import { useState } from 'react';
import { EmptyBasketConfirmationModal } from 'basket/components/EmptyBasketConfirmationModal';
import { ServiceChanger } from 'core/components';
import { ClearBasketButton } from 'basket/components/Basket/SharedComponents';
import { useBasket } from 'contexts/BasketContext';
import { useVouchers } from 'contexts/VoucherContext';
import { useCheckout } from 'contexts/CheckoutContext';

export const StickyBasketBottomRow: React.FC = () => {
  const { clearBasket } = useBasket();
  const { clearCheckout } = useCheckout();

  const [showClearBasketModal, setShowClearBasketModal] =
    useState<boolean>(false);

  const handleClearBasket = () => {
    setShowClearBasketModal(true);
  };

  const { redeemedVouchers, voidAllVouchers } = useVouchers();

  const confirmClearBasket = () => {
    if (redeemedVouchers.length > 0) {
      voidAllVouchers();
    }
    clearBasket();
    clearCheckout();
    setShowClearBasketModal(false);
  };

  const dismissClearBasket = () => {
    setShowClearBasketModal(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-xs-12 col-md-12 sticky-basket-row basket-bottom-row">
          <ServiceChanger />
          <ClearBasketButton handleClearBasket={handleClearBasket} />
        </div>
      </div>
      <EmptyBasketConfirmationModal
        show={showClearBasketModal}
        proceed={confirmClearBasket}
        dismiss={dismissClearBasket}
      />
    </>
  );
};
