import { useDispatch } from 'react-redux';
import { addNotification } from 'core/actions';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { MapMarker, getCenterAndZoom } from 'venue/components/MapComponents';
import { useVenues } from 'contexts/VenueContext';
import { useRef } from 'react';
import {
  GoogleMap,
  MarkerClusterer,
  useJsApiLoader,
} from '@react-google-maps/api';
import { MdCancel } from 'react-icons/md';
interface VenueMapProps {
  handleClose: () => void;
}
export const VenueMap: React.FC<VenueMapProps> = ({ handleClose }) => {
  const { googleMapsApiKey } = useConfig();
  const { isLoadingVenues, venues } = useVenues();
  const { venueTitle } = usePhrases();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsApiKey,
  });
  const dispatch = useDispatch();

  if (!googleMapsApiKey) {
    dispatch(
      addNotification('There is no Google Maps API key configured.', 'danger'),
    );
    handleClose();
  }
  const mapRef = useRef<google.maps.Map | null>(null);

  const onLoad = (map: google.maps.Map) => {
    mapRef.current = map;
  };

  if (venues.length > 0) {
    const { center, zoom } = getCenterAndZoom(venues);
    const containerStyle = {
      width: '100%',
      height: '100%',
    };
    const options = {
      streetViewControl: false,
      mapTypeControl: false,
      clickableIcons: false,
      fullscreenControl: false,
      disableAutoPan: true,
    };
    return isLoaded && !isLoadingVenues && venues.length > 0 ? (
      <div className="map-container">
        <MdCancel
          size={42}
          onClick={handleClose}
          tabIndex={0}
          className="map-close"
          aria-label="Close map"
        />
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={zoom}
          options={options}
          onLoad={onLoad}
        >
          <MarkerClusterer>
            {(clusterer) => (
              <>
                {venues.map((venue) => (
                  <MapMarker
                    venue={venue}
                    clusterer={clusterer}
                    key={venue.id}
                  />
                ))}
              </>
            )}
          </MarkerClusterer>
        </GoogleMap>
      </div>
    ) : (
      <p className="text-center loading-wrapper">No {venueTitle}s</p>
    );
  }
  if (!isLoadingVenues) {
    return <p className="text-center loading-wrapper">No {venueTitle}s</p>;
  }
  return null;
};
