import { useEffect, useState } from 'react';
import braintreeClient from 'braintree-web/client';
import dataCollector from 'braintree-web/data-collector';
import paypalCheckout from 'braintree-web/paypal-checkout';
import { useConfig } from 'contexts/ConfigContext';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from 'core/actions';
import { useAdditionalInformation } from 'contexts/AdditionalInformationContext';
import { FormProvider, useForm } from 'react-hook-form';
import { PayPalAdditionalFieldsModal } from './AdditionalFields';
import { normaliseFormData } from 'checkout/utils';
import { useServices } from 'contexts/VenueContext';
import { isTimeslotService } from 'venue/utils';
import {
  useCheckout,
  useCheckoutDetails,
  usePayment,
} from 'contexts/CheckoutContext';
import { PaymentType } from 'types/models/Payment';
import { selectTipAmount } from 'checkout/selectors';
import { usePayMyBill } from 'contexts/PayMyBillContext/PayMyBillContext';
import { useGlobalUser } from 'contexts/UserContext';

export const PayPal = ({ isPmb = false }) => {
  const { currency, payPalButtonColour } = useConfig();
  const {
    isUserFieldRequired,
    userInformationFields,
    savedLocationInformation,
    locationInformationFields,
  } = useAdditionalInformation();
  const methods = useForm();
  const dispatch = useDispatch();

  const { isLoggedIn } = useGlobalUser();
  const { selectedService } = useServices();
  const { submitPayment } = useCheckout();
  const { braintreeToken, basketTotal } = useCheckoutDetails();
  const { setPaymentReady, setPaymentEnabled } = usePayment();
  const {
    braintreeToken: pmbBraintreeToken,
    paymentAmount,
    submitPayment: submitPmbPayment,
  } = usePayMyBill();

  const tipAmount = useSelector(selectTipAmount);
  const braintreeTokenToUse = isPmb ? pmbBraintreeToken : braintreeToken;
  const totalToUse = isPmb ? paymentAmount : basketTotal;

  const totalPlusTip = totalToUse + tipAmount;

  const shouldShowAdditionalInfo = isTimeslotService(selectedService);

  const [modalShown, setModalShown] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [deviceData, setDeviceData] = useState(null);

  const userFieldFormats = {
    // key = iOrder field name
    // value = paypal response field name
    address1: 'line1',
    address2: 'line2',
    town: 'city',
    first_name: 'recipientName',
    last_name: 'recipientName',
    mobile_phone: 'phone',
    home_phone: 'phone',
    postcode: 'postalCode',
    country: 'countryCode',
  };

  const payPalErrorMessages = {
    detectedPopUpClose: 'Error: Detected popup close',
    nativePopUpClose: 'Error: Native popup closed',
  };

  const isShippingAddressRequired =
    shouldShowAdditionalInfo &&
    Object.keys(userFieldFormats).some((key) => isUserFieldRequired(key));

  // Handles the race condition of the form input prefilling
  // Check and alter the field content if PayPal give information back
  const handleFormFill = (field) => {
    //  Handle user field email separately
    if (field.userFieldType === 'email' && field.showInUI) {
      field.content = paymentResponse?.details?.email || field.content;
    }
    // Handle all other fields
    if (
      userFieldFormats.hasOwnProperty(field.userFieldType) &&
      field.showInUI
    ) {
      field.content =
        paymentResponse?.details?.shippingAddress[
          userFieldFormats[field.userFieldType]
        ] || field.content;
    }

    return field;
  };

  const showError = (error) => {
    setPaymentEnabled(PaymentType.PayPal, false);
    dispatch(addNotification(error, 'danger'));
  };

  const showInfoBanner = (message) => {
    dispatch(addNotification(message, 'info'));
  };

  const handleSubmitPayment = (formData, deviceData, nonce) => {
    if (isPmb) {
      submitPmbPayment(formData, deviceData, nonce, PaymentType.PayPal);
    } else {
      const normalisedFormData = normaliseFormData(
        locationInformationFields,
        userInformationFields,
        { ...savedLocationInformation, ...formData },
      );

      submitPayment(normalisedFormData, deviceData, nonce, PaymentType.PayPal);
    }
  };

  const handleAdditionalInfoSubmitPayment = (event) => {
    setModalShown(false);
    if (!isLoggedIn) {
      event.email = paymentResponse.details.email;
    }
    handleSubmitPayment(event, deviceData, paymentResponse?.nonce);
  };

  const handleCloseModal = () => {
    setModalShown(false);
    showInfoBanner('PayPal Payment cancelled');
  };

  useEffect(() => {
    const handleMarkPayPalReady = () => {
      setPaymentReady(PaymentType.PayPal, true);
    };

    const handleMarkPayPalUnready = () => {
      setPaymentReady(PaymentType.PayPal, false);
    };

    if (!braintreeTokenToUse) {
      showError('Basket is not authorised');
      return;
    }

    braintreeClient.create(
      {
        authorization: braintreeTokenToUse,
      },
      (clientError, client) => {
        if (clientError) {
          showError('Error connecting to payment service');

          return;
        }

        dataCollector.create(
          { client, kount: true, paypal: true },
          (dataCollectorError, dataCollectorInstance) => {
            if (dataCollectorError) {
              showError(
                `Error collecting device data ${JSON.stringify(
                  dataCollectorError,
                )}`,
              );
              return;
            }

            const payPalDeviceData = dataCollectorInstance.deviceData;

            paypalCheckout.create(
              {
                client,
              },
              (paypalCheckoutError, paypalCheckoutInstance) => {
                if (paypalCheckoutError) {
                  showError('Error initialising PayPal');
                  return;
                }
                paypalCheckoutInstance.loadPayPalSDK(
                  {
                    currency,
                    vault: false,
                    'disable-funding': 'card', //stop the "pay with card" option showing when PayPal is selected
                  },
                  function (loadPayPalSDKErr) {
                    // eslint-disable-next-line no-undef
                    if (loadPayPalSDKErr) {
                      showError('Error initialising PayPal');

                      return;
                    }
                    paypal
                      .Buttons({
                        className: undefined,
                        createOrder: () => {
                          return paypalCheckoutInstance.createPayment({
                            flow: 'checkout',
                            amount: totalPlusTip,
                            currency,
                            enableShippingAddress: isShippingAddressRequired,
                          });
                        },
                        onApprove: (data) =>
                          paypalCheckoutInstance
                            .tokenizePayment(data)
                            .then((payload) => {
                              if (
                                shouldShowAdditionalInfo &&
                                userInformationFields.filter(
                                  (field) => field.showInUI,
                                ).length > 0
                              ) {
                                setPaymentResponse(payload);
                                setDeviceData(payPalDeviceData);
                                setModalShown(true);
                              } else {
                                handleSubmitPayment(
                                  { email: payload.details.email },
                                  payPalDeviceData,
                                  payload.nonce,
                                );
                              }
                            }),
                        onCancel: () => {
                          showInfoBanner('PayPal Payment cancelled');
                        },
                        onError: (error) => {
                          switch (String(error)) {
                            case payPalErrorMessages.detectedPopUpClose:
                            case payPalErrorMessages.nativePopUpClose:
                              showInfoBanner('PayPal Payment cancelled');
                              break;
                            default:
                              showError('PayPal error');
                          }
                        },
                        style: {
                          color: payPalButtonColour,
                          height: 48,
                          'border-radius': 4,
                        },
                      })
                      .render('#paypal-button')
                      .then(() => {
                        handleMarkPayPalReady();
                      });
                  },
                );
              },
            );
          },
        );
      },
    );

    return () => {
      handleMarkPayPalUnready();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPlusTip, savedLocationInformation]);

  return (
    <>
      <div className="text-center">
        <div id="paypal-button" data-testid="paypal-checkout" />
      </div>
      <FormProvider {...methods}>
        <PayPalAdditionalFieldsModal
          shown={modalShown}
          handleConfirm={methods.handleSubmit(
            handleAdditionalInfoSubmitPayment,
          )}
          handleClose={handleCloseModal}
          handleFormFill={handleFormFill}
        />
      </FormProvider>
    </>
  );
};
