import { useTables } from 'contexts/TableContext';

export const TableDetails: React.FC = ({}) => {
  const { selectedTable } = useTables();
  return (
    <div>
      <h2 className="no-margin">{selectedTable?.label ?? 'Bill'}</h2>
      <hr />
    </div>
  );
};
