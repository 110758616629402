import { useAPI } from 'api/useAPI';
import { requestBodyFormatter } from 'api/utils';
import { AxiosRequestConfig } from 'axios';
import { Loader } from 'components/Loader';
import { CheckBasketParams } from 'contexts/CheckoutContext';
import { useConfig } from 'contexts/ConfigContext';
import { addNotification } from 'core/actions';
import { useAuthCookie } from 'hooks/useAuthCookie/useAuthCookie';
import { useAxiosInterceptor } from 'hooks/useAxiosInstance';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
  ErrorResponse,
  OtpChallengeDetails,
  OtpChallengeResponse,
  UserResponse,
} from 'types/models';
import {
  defaultUser,
  LoginFormProps,
  UserInformation,
} from 'types/models/User';
import { setOtpEnabled } from 'user/actions';

interface GlobalUserContext {
  initialised: boolean;
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  logIn: (
    formData: LoginFormProps,
    prevRoute: string,
    checkBasketCallback?: CheckBasketParams,
  ) => void;
  logOut: (retainBasket?: boolean) => void;
  otpDetails?: OtpChallengeDetails;
  resetOTP: () => void;
  resetUser: () => void;
  setOTP: (values: OtpChallengeDetails) => void;
  setShouldClearBasket: (value: boolean) => void;
  shouldClearBasket: boolean;
  showOtpModal: boolean;
  updateUser: (updatedUser: UserInformation) => void;
  user: UserInformation;
  verifyOTP: (
    otpCode: string,
    prevRoute: string,
    checkBasketCallback?: CheckBasketParams,
  ) => void;
}

export const GlobalUserContext = createContext<GlobalUserContext>({
  initialised: false,
  isLoggedIn: false,
  isLoggingIn: false,
  isLoggingOut: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logIn: () => {},
  otpDetails: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logOut: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetOTP: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetUser: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setOTP: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShouldClearBasket: () => {},
  shouldClearBasket: false,
  showOtpModal: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateUser: () => {},
  user: defaultUser,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  verifyOTP: () => {},
});

export const useGlobalUser = (): GlobalUserContext => {
  const consumer = useContext(GlobalUserContext);
  if (!consumer.initialised) {
    throw new Error('GlobalUserContext Provider not initialised');
  }
  return consumer;
};

interface GlobalUserContextProviderProps {
  children: React.ReactNode;
}

export const GlobalUserContextProvider: React.FC<
  GlobalUserContextProviderProps
> = ({ children }) => {
  const { disableUserSignIn } = useConfig();
  const dispatch = useDispatch();
  const history = useHistory();

  const { getUser, logInOTP, logInUser, logOutUser, url } = useAPI();

  // To avoid the user being undefined when not logged in and having to do strict checks anywhere we reference it
  const [user, setUser] = useState<UserInformation>(defaultUser);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isFetchingUser, setIsFetchingUser] = useState(true);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otpDetails, setOtpDetails] = useState<OtpChallengeDetails>();

  const [shouldClearBasket, setShouldClearBasket] = useState(false);

  const { authCookie, deleteAuthCookie, isAuthTokenValid, setAuthCookie } =
    useAuthCookie();

  const { pathname } = useLocation();

  const previous = useRef<string | undefined>(authCookie);

  const logIn = (
    formData: LoginFormProps,
    prevRoute: string,
    checkBasketCallback?: CheckBasketParams,
  ) => {
    setIsLoggingIn(true);

    const logInUserConfig = logInUser();
    const logInUserOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      data: requestBodyFormatter({
        method: logInUserConfig.method,
        ...logInUserConfig.body,
        user: {
          email: formData.email,
          password: formData.password,
        },
        stay_signed_in: formData?.staySignedIn ?? 0,
      }),
    };

    axios(logInUserOptions)
      .then((response) => {
        if (response.data.user) {
          const data = response.data as UserResponse;
          handleUserLogIn(data, prevRoute, checkBasketCallback);
        } else if (response.data.challenge) {
          const data = response.data.challenge as OtpChallengeResponse;
          setOtpDetails({
            ...data,
            userEmail: formData.email,
            staySignedIn: formData.staySignedIn,
          });
          setShowOtpModal(true);
        } else {
          const err = response.data as ErrorResponse;
          dispatch(addNotification(err.detail, 'danger'));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addNotification('Something went wrong, please try again.', 'danger'),
        );
      })
      .finally(() => {
        setIsLoggingIn(false);
      });
  };

  const handleUserLogIn = (
    data: UserResponse,
    prevRoute: string,
    checkBasketCallback?: CheckBasketParams,
  ) => {
    setUser(data.user);
    setIsLoggedIn(true);
    setAuthCookie(data['X-Auth-UserToken']);
    // choose where to go
    if (checkBasketCallback && prevRoute === '/checkout') {
      // if we were on the way to checkout, then check basket and go there
      checkBasketCallback(true);
    } else {
      // redirect to venues
      history.push(prevRoute ?? '/');
    }
  };
  const verifyOTP = (
    otpCode: string,
    prevRoute: string,
    checkBasketCallback?: CheckBasketParams,
  ) => {
    const logInOTPConfig = logInOTP();
    const logInOTPOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      data: requestBodyFormatter({
        method: logInOTPConfig.method,
        ...logInOTPConfig.body,
        challengeID: otpDetails?.id,
        user: { email: otpDetails?.userEmail },
        otp: otpCode,
        stay_signed_in: otpDetails?.staySignedIn ?? 0,
      }),
    };

    axios(logInOTPOptions)
      .then((response) => {
        if (response.data.user) {
          const data = response.data as UserResponse;
          handleUserLogIn(data, prevRoute, checkBasketCallback);
          resetOTP();
          // Keep this here just now but might need a new way of doing it
          dispatch(setOtpEnabled(true));
        } else {
          const err = response.data as ErrorResponse;
          dispatch(addNotification(err.detail, 'danger'));
        }
      })
      .catch(() => {
        dispatch(
          addNotification('Something went wrong, please try again.', 'danger'),
        );
      });
  };

  const getUserDetails = () => {
    setIsFetchingUser(true);
    const getUserConfig = getUser();
    const getUserOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      data: requestBodyFormatter({
        method: getUserConfig.method,
        ...getUserConfig.body,
      }),
    };

    axios(getUserOptions)
      .then((response) => {
        if (response.data.user && response.data?.response === 'ok') {
          const data = response.data as UserResponse;
          setUser(data.user);
          setIsLoggedIn(true);
        } else {
          const err = response.data as ErrorResponse;
          dispatch(addNotification(err.detail, 'danger'));
        }
      })
      .finally(() => {
        setIsFetchingUser(false);
      });
  };

  const updateUser = (updatedUser: UserInformation) => {
    setUser(updatedUser);
    setIsLoggedIn(true);
  };

  const logOut = (retainBasket?: boolean) => {
    setIsLoggingOut(true);
    const logOutConfig = logOutUser();
    const logOutOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      data: requestBodyFormatter({
        method: logOutConfig.method,
        ...logOutConfig.body,
      }),
    };

    axios(logOutOptions).finally(() => {
      resetUser();
      if (!retainBasket) {
        setShouldClearBasket(true);
      }
    });
  };

  const resetUser = () => {
    setUser(defaultUser);
    setIsLoggedIn(false);
    deleteAuthCookie();
    setOtpEnabled(false);
    setIsLoggingOut(false);
  };

  const setOTP = (details: OtpChallengeDetails) => {
    setShowOtpModal(true);
    setOtpDetails(details);
  };

  const resetOTP = () => {
    setOtpDetails(undefined);
    setShowOtpModal(false);
  };

  const handleLogout = () => {
    resetUser();
    previous.current = undefined;

    if (pathname === '/checkout') {
      dispatch(addNotification('Please log in to continue.', 'danger'));
      history.push({
        pathname: '/user/login',
        state: { from: { pathname: '/checkout' } },
      });
    }
  };

  useEffect(() => {
    const handleLogoutEvent = (e: StorageEvent) => {
      if (e.key === 'logOutWebOrdering') {
        handleLogout();
      }
    };

    if (!isAuthTokenValid() && !previous.current) {
      handleLogout();
      // Tells other open tabs to log out
      localStorage.setItem('logOutWebOrdering', Date.now().toString());
    }

    window.addEventListener('storage', handleLogoutEvent);

    return () => {
      window.removeEventListener('storage', handleLogoutEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCookie]);

  useEffect(() => {
    if (disableUserSignIn) {
      resetUser()
      setIsFetchingUser(false);
      setIsLoggedIn(false);
    } else if (authCookie && isAuthTokenValid()) {
      getUserDetails();
    } else {
      setIsFetchingUser(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const axios = useAxiosInterceptor();

  return (
    <GlobalUserContext.Provider
      value={{
        initialised: true,
        isLoggedIn,
        isLoggingIn,
        isLoggingOut,
        logIn,
        logOut,
        otpDetails,
        resetOTP,
        resetUser,
        setOTP,
        setShouldClearBasket,
        shouldClearBasket,
        showOtpModal,
        updateUser,
        user,
        verifyOTP,
      }}
    >
      {isFetchingUser ? (
        <div className="data-loader-wrapper">
          <Loader />
        </div>
      ) : (
        children
      )}
    </GlobalUserContext.Provider>
  );
};
