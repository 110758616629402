/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { Loader } from 'components/Loader';
import { ServiceType } from 'types/models';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { getLoyaltyPhrase } from 'utils';
import { Button } from 'react-bootstrap';
import { LoyaltyRewardsPanelPhrase } from './components/LoyaltyRewardsPanelPhrase';
import { LoyaltyRewardsModal } from './components/Modal';
import { addNotification } from 'core/actions';
import { LoyaltyRewardPanelLines } from './components/LoyaltyRewardPanelLines';
import { useServices } from 'contexts/VenueContext';
import { useCheckout } from 'contexts/CheckoutContext';
import { useGiftCard } from 'contexts/GiftCardContext';
import { useVouchers } from 'contexts/VoucherContext';
import { useGlobalUser } from 'contexts/UserContext';
import { useAuthCookie } from 'hooks/useAuthCookie';

export const LoyaltyRewards: React.FC = () => {
  const { supportsRewardsRedemption } = useConfig();
  const dispatch = useDispatch();
  const {
    loyalty: { rewardPhrase, rewardTitleText },
  } = usePhrases();

  const { user } = useGlobalUser();
  const { selectedService } = useServices();
  const { isFetchingBasket } = useCheckout();
  const { redeemedGiftCards } = useGiftCard();
  const { redeemedVouchers } = useVouchers();
  const {
    getLoyaltyRewards,
    isLoyaltyRewardsLoading,
    loyaltyRewardsError,
    resetLoyaltyRewardsError,
  } = useLoyaltyRewards();

  const { isAuthTokenValid } = useAuthCookie();

  const [showModal, setShowModal] = useState(false);
  const [hasLoyaltyError, setHasLoyaltyError] = useState(false);

  const hasLoyaltyCard = Boolean(user.loyalty_card);
  const hasGiftCards = redeemedGiftCards.length > 0;
  const hasVouchersApplied = redeemedVouchers.length > 0;

  const serviceSupportsLoyalty =
    selectedService === ServiceType.ClickAndCollect ||
    selectedService === ServiceType.OrderAndPay ||
    selectedService === ServiceType.DeliveryToLocation;
  const showLoyaltyPanel =
    supportsRewardsRedemption &&
    hasLoyaltyCard &&
    serviceSupportsLoyalty &&
    isAuthTokenValid();
  const loyaltyTitle = getLoyaltyPhrase(rewardTitleText, rewardPhrase);

  useEffect(() => {
    if (showLoyaltyPanel) {
      getLoyaltyRewards();
    }
    return () => {
      resetLoyaltyRewardsError();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      loyaltyRewardsError !== undefined &&
      loyaltyRewardsError?.code !== -992
    ) {
      setHasLoyaltyError(true);
      dispatch(
        addNotification(
          `There was an error with loyalty. ${loyaltyRewardsError.detail} (${loyaltyRewardsError.code})`,
          'danger',
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loyaltyRewardsError]);

  return (
    <>
      {showLoyaltyPanel && !hasLoyaltyError && (
        <div className="card loyalty" data-testid="loyalty-reward-card">
          <h3 className="title">{loyaltyTitle}</h3>
          {isLoyaltyRewardsLoading ? (
            <Loader />
          ) : (
            <div className="body">
              <Button
                className="center-block checkout-fw-button"
                bsStyle="primary"
                data-testid="loyalty-reward-modal-btn"
                disabled={
                  hasGiftCards || hasVouchersApplied || isFetchingBasket
                }
                onClick={() => setShowModal(true)}
              >
                {loyaltyTitle}
              </Button>
              <LoyaltyRewardPanelLines />
              <LoyaltyRewardsPanelPhrase />
              <LoyaltyRewardsModal
                isShown={showModal}
                setIsShown={setShowModal}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
