import { useConfig } from 'contexts/ConfigContext';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { removeNotification } from 'core/actions';

export const Logo: React.FC = () => {
  const dispatch = useDispatch();
  const { logoLinkToHomepage, homepage } = useConfig();

  const clearNotification = () => {
    dispatch(removeNotification());
  };

  const logoImage = (
    <div
      className="logo-img"
      data-testid="header-logo"
      role="img"
      aria-label="Company logo"
    />
  );

  return (
    <div className="header-navbar-logo">
      {logoLinkToHomepage ? (
        <a href={`${homepage}`} onClick={clearNotification} target="blank">
          {logoImage}
        </a>
      ) : (
        <Link onClick={clearNotification} to="/">
          {logoImage}
        </Link>
      )}
    </div>
  );
};
