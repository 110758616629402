import { GenericJSObject } from 'types/Core';
import { setupFetchParams } from 'utils';
interface APICall {
  method: string;
  body: GenericJSObject;
}
interface APIHook {
  changePassword: () => APICall;
  checkBasket: () => APICall;
  checkVoucher: () => APICall;
  creditGiftCard: () => APICall;
  creditReward: () => APICall;
  debitGiftCard: () => APICall;
  debitReward: () => APICall;
  deleteAccount: () => APICall;
  deleteAccountWithOtp: () => APICall;
  editUser: () => APICall;
  getAccount: () => APICall;
  getAdditionalInformation: () => APICall;
  getBraintreeToken: () => APICall;
  getConfig: () => APICall;
  getSignUp: () => APICall;
  getVenueHome: () => APICall;
  getHotels: () => APICall;
  getLoyalty: () => APICall;
  getMenuPages: () => APICall;
  getMenus: () => APICall;
  getRoomFolios: () => APICall;
  getStyles: () => APICall;
  getTables: () => APICall;
  getUser: () => APICall;
  getVenueSummary: () => APICall;
  logInOTP: () => APICall;
  logInUser: () => APICall;
  logOutUser: () => APICall;
  makeBraintreePayment: () => APICall;
  makeAccountPayment: () => APICall;
  placeOrder: () => APICall;
  registerUser: () => APICall;
  resendLoyaltyPin: () => APICall;
  resetPassword: () => APICall;
  resetPasswordByToken: () => APICall;
  searchOpenAccounts: () => APICall;
  submitChargeToRoom: () => APICall;
  getTimeslots: () => APICall;
  url: string;
  userAddLoyaltyNumber: () => APICall;
  userEnrollLoyalty: () => APICall;
  userUnlinkLoyaltyCard: () => APICall;
  orderHistoryRequestOrders: () => APICall;
  venues: () => APICall;
  verifyGiftCard: () => APICall;
  verifyLoyalty: () => APICall;
  voidVoucher: () => APICall;
}

export const useAPI = (): APIHook => {
  const apiUrl = window.env.REACT_APP_API as string;

  const getConfig = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'getConfig',
      body: body,
    };
  };

  const getSignUp = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'getSignUpConfig',
      body: body,
    };
  };

  const getStyles = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'style',
      body: body,
    };
  };

  const checkVoucher = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'checkVoucher',
      body: body,
    };
  };

  const voidVoucher = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'voidVoucher',
      body: body,
    };
  };

  const getRoomFolios = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'getHotelRoomFolios',
      body: body,
    };
  };

  const getHotels = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'getHotels',
      body: body,
    };
  };

  const submitChargeToRoom = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'makeChargeToRoom',
      body: body,
    };
  };

  const getAdditionalInformation = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'getAdditionalOrderInformation',
      body: body,
    };
  };

  const verifyGiftCard = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'verifyGiftCard',
      body: body,
    };
  };

  const debitGiftCard = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'debitGiftCardBalance',
      body: body,
    };
  };

  const creditGiftCard = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'creditGiftCard',
      body: body,
    };
  };

  const getMenus = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'getMenus',
      body: body,
    };
  };

  const getMenuPages = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'getMenuPages',
      body: body,
    };
  };

  const venues = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'venues',
      body: body,
    };
  };

  const getVenueSummary = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'getVenueSummary',
      body: body,
    };
  };

  const getVenueHome = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'home',
      body: body,
    };
  };

  const getTables = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'getTables',
      body: body,
    };
  };

  const makeBraintreePayment = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'makeBraintreePayment',
      body: body,
    };
  };

  const getTimeslots = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'timeslots',
      body: body,
    };
  };

  const userAddLoyaltyNumber = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'userAddLoyaltyNumber',
      body: body,
    };
  };

  const getLoyalty = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'userGetRewards',
      body: body,
    };
  };

  // TODO - remove this - API team are making this redundant
  const verifyLoyalty = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'userVerifyLoyaltyNumber',
      body: body,
    };
  };

  const resendLoyaltyPin = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'loyaltyResendPin',
      body: body,
    };
  };

  const debitReward = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'debitLoyaltySiteReward',
      body: body,
    };
  };

  const creditReward = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'creditLoyaltySiteReward',
      body: body,
    };
  };

  const deleteAccount = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'requestAccountDeletion',
      body: body,
    };
  };

  const deleteAccountWithOtp = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'requestAccountDeletionWithOTP',
      body: body,
    };
  };

  const changePassword = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'changePassword',
      body: body,
    };
  };

  const checkBasket = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'checkBasket',
      body: body,
    };
  };

  const getBraintreeToken = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'getBraintreeToken',
      body: body,
    };
  };

  const placeOrder = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'placeOrder',
      body: body,
    };
  };

  const searchOpenAccounts = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'searchOpenAccounts',
      body: body,
    };
  };

  const getAccount = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'getAccount',
      body: body,
    };
  };

  const makeAccountPayment = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'makeAccountPayment',
      body: body,
    };
  };

  const userEnrollLoyalty = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'userEnrollLoyalty',
      body: body,
    };
  };

  const userUnlinkLoyaltyCard = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'unlinkLoyaltyCard',
      body: body,
    };
  };

  const orderHistoryRequestOrders = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'getMyOrders',
      body: body,
    };
  };

  const registerUser = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'postRegisterUser',
      body: body,
    };
  };

  const editUser = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'putMe',
      body: body,
    };
  };

  const resetPasswordByToken = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'resetPasswordByToken',
      body: body,
    };
  };

  const resetPassword = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'resetPassword',
      body: body,
    };
  };

  const logInUser = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'postLoginEmail',
      body: body,
    };
  };

  const logInOTP = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'loginWithOTP',
      body: body,
    };
  };

  const getUser = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'getUser',
      body: body,
    };
  };

  const logOutUser = (): APICall => {
    const { body } = setupFetchParams();
    return {
      method: 'logout',
      body: body,
    };
  };

  return {
    changePassword,
    checkBasket,
    checkVoucher,
    creditGiftCard,
    creditReward,
    debitGiftCard,
    debitReward,
    deleteAccount,
    deleteAccountWithOtp,
    editUser,
    getAccount,
    getAdditionalInformation,
    getBraintreeToken,
    getConfig,
    getSignUp,
    getHotels,
    getLoyalty,
    getMenuPages,
    getMenus,
    getRoomFolios,
    getStyles,
    getTables,
    getUser,
    getVenueHome,
    getVenueSummary,
    logInOTP,
    logInUser,
    logOutUser,
    makeAccountPayment,
    makeBraintreePayment,
    placeOrder,
    registerUser,
    resendLoyaltyPin,
    resetPassword,
    resetPasswordByToken,
    searchOpenAccounts,
    submitChargeToRoom,
    getTimeslots,
    url: apiUrl,
    userAddLoyaltyNumber,
    userEnrollLoyalty,
    userUnlinkLoyaltyCard,
    orderHistoryRequestOrders,
    venues,
    verifyGiftCard,
    verifyLoyalty,
    voidVoucher,
  };
};
