import { Dialog } from 'components/Dialog';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { useHistory, useLocation } from 'react-router';
import { WLWOLocationState } from 'types/models/WLWOLocationState';

export const AddLoyaltyErrorDialog: React.FC = () => {
  const { addLoyaltyError, setAddLoyaltyError } = useLoyaltyRewards();

  const history = useHistory();
  const location = useLocation<WLWOLocationState>();
  const { from } = location.state || { from: { pathname: '/venues' } };

  const handleAccept = () => {
    setAddLoyaltyError('');
  };

  const handleContinue = () => {
    setAddLoyaltyError('');
    history.push(from.pathname);
  };

  return (
    <Dialog
      isOpen={!!addLoyaltyError}
      description={addLoyaltyError}
      datatestid="loyalty-action-modal"
      accept={{
        action: () => handleAccept(),
        text: 'Review your details',
        btnType: 'default',
        datatestid: 'button-review-details-action',
      }}
      reject={{
        action: () => handleContinue(),
        text: 'Continue',
        datatestid: 'button-loyalty-continue-details',
      }}
    />
  );
};
