/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CSSProperties, ReactNode, useEffect } from 'react';
import { useParams } from 'react-router';
import VirtualisedSelect from 'react-select-virtualized';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { ReactSelectOption } from 'types/react-select-virtualized';
import { findTableByNumber, getServiceLabel } from 'venue/utils';
import { ServiceType } from 'types/models';
import { useSalesAreas, useServices, useVenues } from 'contexts/VenueContext';
import { useTables } from 'contexts/TableContext/TableContext';
import { Controller, useFormContext } from 'react-hook-form';
import cs from 'classnames';
import { getTableText } from 'PayMyBill/utils';

interface ParamsProps {
  tableNumber: string;
}

interface GroupFormatProps {
  label: string;
  options: ReactSelectOption[];
}

export const tableSelectorGroupFormat = ({
  label,
  options,
}: GroupFormatProps): ReactNode => {
  return (
    <div className="table-selector-label">
      <div>{label}</div>
      <div className="table-selector-indicator">{options.length}</div>
    </div>
  );
};

export const TableSelector: React.FC = () => {
  const { tableSelectorSearchEnabled } = useConfig();
  const { tableNumber: tableNumberFromUrl } = useParams<ParamsProps>();
  const { table: tablePhrase, payMyBillEnterTablePlaceholder } = usePhrases();

  const { selectedVenue } = useVenues();
  const { services, selectedService } = useServices();
  const { selectedSalesArea } = useSalesAreas();
  const { fetchTables, isFetchingTables, tables, hasGroups, selectedTable } =
    useTables();

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const formName = 'tableSelector';
  const hasError = errors[formName] !== undefined;
  const fieldError = errors[formName];

  const phraseToDisplay = `You're ordering using ${getServiceLabel(
    services,
    ServiceType.OrderAndPay,
  )} for ${tablePhrase.toLowerCase()}`;

  const inputPlaceholder =
    selectedService === ServiceType.PayMyBill
      ? getTableText(payMyBillEnterTablePlaceholder, tablePhrase)
      : `Select your ${tablePhrase || 'table'}`;

  const formGroupClasses = cs('form-group', hasError && 'has-error');

  // Make API call to get tables
  useEffect(() => {
    if (selectedVenue && selectedSalesArea) {
      fetchTables(selectedVenue.id, selectedSalesArea.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVenue, selectedSalesArea]);

  // This will prepopulate the table field if an option has already been selected - so only used in the TableChanger
  useEffect(() => {
    if (selectedTable && tables.find((tbl) => tbl === selectedTable)) {
      setValue(formName, {
        ...selectedTable,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Find table from url and prepopulate the table field
  useEffect(() => {
    const foundTable = findTableByNumber(
      tables,
      Number(tableNumberFromUrl),
      hasGroups,
    );

    if (foundTable) {
      setValue(formName, foundTable);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableNumberFromUrl, tables]);

  const customFilter = (option: ReactSelectOption, rawInput: string) =>
    option &&
    option.label.toLocaleLowerCase().indexOf(rawInput.toLocaleLowerCase()) >= 0;

  return (
    <div data-testid={formName} className={formGroupClasses}>
      {tableNumberFromUrl ? <h5>{`${phraseToDisplay}:`}</h5> : null}
      <Controller
        control={control}
        name={formName}
        rules={{
          required: true,
        }}
        render={({ field }) => (
          <VirtualisedSelect
            {...field}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            inputProps={{
              autoComplete: 'off',
              autoCorrect: 'off',
              spellCheck: 'off',
            }}
            styles={{
              menuPortal: (base: CSSProperties) => ({ ...base, zIndex: 9999 }),
            }}
            formatGroupHeaderLabel={tableSelectorGroupFormat}
            options={tables}
            placeholder={inputPlaceholder}
            noOptionsMessage={() => `No ${tablePhrase || 'table'} available`}
            isSearchable={tableSelectorSearchEnabled}
            filterOption={customFilter}
            isClearable={false}
            menuShouldScrollIntoView={true}
            grouped={hasGroups}
            classNamePrefix={
              hasError ? 'virtual-select-has-error' : 'virtual-select'
            }
            isLoading={isFetchingTables}
            aria-invalid={hasError ? 'true' : 'false'}
            aria-required={true}
          />
        )}
      />
      {fieldError && (
        <span className="help-block" role="alert">
          Please select a valid {tablePhrase}
        </span>
      )}
    </div>
  );
};
