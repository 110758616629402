import { createContext, useContext, useEffect, useState } from 'react';

import { useAPI } from 'api/useAPI';
import { requestBodyFormatter } from 'api/utils';
import { AxiosRequestConfig } from 'axios';
import { useSalesAreas, useServices, useVenues } from 'contexts/VenueContext';
import { addNotification } from 'core/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectTipAmount } from 'checkout/selectors';
import { isHotels } from 'types/guards';
import {
  ErrorResponse,
  GetHotelRoomFoliosResponse,
  GetHotelsResponse,
  MakeChargeToRoomResponse,
  ServiceType,
} from 'types/models';
import { Folio } from 'types/models/Hotels';
import { setAdditionalInfoError999 } from 'checkout/actions';
import { useAdditionalInformation } from 'contexts/AdditionalInformationContext';
import { AdditionalInfoField } from 'types/models/AdditionalInformation';
import { getAdditionalInformation, normaliseFormData } from 'checkout/utils';
import { AdditionalInformationRequest } from 'types/models/Payment';
import { useBasket } from 'contexts/BasketContext';
import { useHistory } from 'react-router';
import { isTimeslotService } from 'venue/utils';
import { useTables } from 'contexts/TableContext/TableContext';
import { useCheckout, useCheckoutDetails } from 'contexts/CheckoutContext';
import { useTimeslots } from 'contexts/TimeslotContext/TimeslotContext';
import { useAxiosInterceptor } from 'hooks/useAxiosInstance';

interface FolioProps {
  foliosLoading: boolean;
  foliosResponse: GetHotelRoomFoliosResponse | ErrorResponse | undefined;
  roomNumber: string;
}

interface HotelProps {
  hotelsLoading: boolean;
  hotelsResponse: GetHotelsResponse | ErrorResponse | undefined;
  hotelName: string | undefined;
}

interface ChargeToRoomProps {
  isSubmittingChargeToRoom: boolean;
  chargeToRoomResponse: MakeChargeToRoomResponse | ErrorResponse | undefined;
}

interface HotelContext {
  initialised: boolean;
  folios: FolioProps;
  hotels: HotelProps;
  chargeToRoom: ChargeToRoomProps;
  checkFolios: (name: string, roomNumber: string) => void;
  resetState: () => void;
  checkHotels: () => void;
  makeChargeToRoom: (folio: Folio | null) => void;
}

// set up context
export const HotelContext = createContext<HotelContext>({
  initialised: false,
  folios: {
    foliosLoading: false,
    foliosResponse: undefined,
    roomNumber: '',
  },
  hotels: {
    hotelsLoading: false,
    hotelsResponse: undefined,
    hotelName: undefined,
  },
  chargeToRoom: {
    isSubmittingChargeToRoom: false,
    chargeToRoomResponse: undefined,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  checkFolios: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  checkHotels: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  makeChargeToRoom: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetState: () => {},
});

// set up hook
export const useHotels = (): HotelContext => {
  const consumer = useContext(HotelContext);

  // Condition used to determine if Context Provider has been declared
  if (!consumer.initialised) {
    throw new Error('HotelContextProvider not initialised');
  }

  return consumer;
};

interface HotelContextProviderProps {
  children: React.ReactNode;
}

// set up provider
export const HotelContextProvider: React.FC<HotelContextProviderProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { url, getRoomFolios, getHotels, submitChargeToRoom } = useAPI();
  const axios = useAxiosInterceptor();

  const { clearBasket } = useBasket();
  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();
  const { selectedSalesArea } = useSalesAreas();
  const { selectedTable } = useTables();
  const { setOrderId } = useCheckout();
  const { basketId } = useCheckoutDetails();
  const { selectedTimeslot } = useTimeslots();

  const tipAmount = useSelector(selectTipAmount);
  const shouldShowAdditionalInfo = isTimeslotService(selectedService);

  const {
    savedLocationInformation,
    locationInformationFields,
    userInformationFields,
  } = useAdditionalInformation();

  // Room Folio states
  // **************************************************************************//
  const [folioGuestName, setFolioGuestName] = useState<string>('');
  const [folioRoomNumber, setFolioRoomNumber] = useState<string>('');
  const [isFetchingFolios, setIsFetchingFolios] = useState<boolean>(false);
  const [foliosLoading, setFoliosLoading] = useState<boolean>(false);
  const [foliosResponse, setFoliosResponse] = useState<
    GetHotelRoomFoliosResponse | ErrorResponse | undefined
  >();
  // **************************************************************************//

  // Hotel states
  // **************************************************************************//
  const [isFetchingHotels, setIsFetchingHotels] = useState<boolean>(false);
  const [hotelsLoading, setHotelsLoading] = useState<boolean>(false);
  const [hotelsResponse, setHotelsResponse] = useState<
    GetHotelsResponse | ErrorResponse | undefined
  >();
  const [hotelName, setHotelName] = useState<string | undefined>(undefined);
  // **************************************************************************//

  // Make charge to room states
  // **************************************************************************//
  const [isSubmittingChargeToRoom, setIsSubmittingChargeToRoom] =
    useState<boolean>(false);
  const [chargeToRoomResponse, setChargeToRoomResponse] = useState<
    MakeChargeToRoomResponse | ErrorResponse | undefined
  >();
  const [selectedFolio, setSelectedFolio] = useState<Folio | null>();
  const [isCharging, setIsCharging] = useState<boolean>(false);
  // **************************************************************************//

  const checkFolios = (name: string, roomNumber: string) => {
    setFolioGuestName(name);
    setFolioRoomNumber(roomNumber);
    setIsFetchingFolios(true);
  };

  const checkHotels = () => {
    setIsFetchingHotels(true);
  };

  const makeChargeToRoom = (folio: Folio | null) => {
    setSelectedFolio(folio);
    setIsCharging(true);
  };

  const getAdditionalInfo = (): AdditionalInformationRequest[] => {
    const additionalInfo: AdditionalInfoField = {};

    userInformationFields
      .filter((x) => x.content)
      .forEach((uif) => {
        if (uif.name) {
          const key = uif.name as keyof AdditionalInfoField;
          additionalInfo[key] = uif.content;
        }
      });

    const normalisedFormData = normaliseFormData(
      locationInformationFields,
      userInformationFields,
      { ...savedLocationInformation, ...additionalInfo },
    );

    return getAdditionalInformation(normalisedFormData);
  };

  useEffect(() => {
    if (isFetchingFolios && typeof hotelName === 'string') {
      fetchFolios(folioGuestName, folioRoomNumber, hotelName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingFolios]);

  useEffect(() => {
    if (isFetchingHotels) {
      fetchHotels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingHotels]);

  useEffect(() => {
    if (isHotels(hotelsResponse) && hotelsResponse.count > 0) {
      setHotelName(hotelsResponse.hotels[0].name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelsResponse]);

  useEffect(() => {
    if (isCharging) {
      chargeToRoom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCharging]);

  const fetchFolios = (name: string, roomNumber: string, hotel: string) => {
    setFoliosResponse(undefined);
    if (!foliosLoading) {
      setFoliosLoading(true);

      const getRoomFoliosConfig = getRoomFolios();

      const fetchFoliosOptions: AxiosRequestConfig = {
        url,
        method: 'POST',
        data: requestBodyFormatter({
          version: '20',
          method: getRoomFoliosConfig.method,
          ...getRoomFoliosConfig.body,
          salesAreaId: selectedSalesArea?.id,
          name,
          roomNumber,
          hotel,
        }),
      };

      axios(fetchFoliosOptions)
        .then((response) => {
          const data = response.data as GetHotelRoomFoliosResponse;
          if (data.response === 'OK') {
            setFoliosResponse(data);
          } else {
            const err = response.data as ErrorResponse;
            setFoliosResponse(err);
          }
        })
        .catch(() => {
          dispatch(
            addNotification(
              'There was an unexpected error fetching folios.',
              'danger',
            ),
          );
        })
        .finally(() => {
          setFoliosLoading(false);
          setIsFetchingFolios(false);
        });
    }
  };

  const fetchHotels = () => {
    setHotelsResponse(undefined);
    if (!hotelsLoading) {
      setHotelsLoading(true);

      const getHotelsConfig = getHotels();

      const fetchHotelOptions: AxiosRequestConfig = {
        url,
        method: 'POST',
        data: requestBodyFormatter({
          version: '20',
          method: getHotelsConfig.method,
          ...getHotelsConfig.body,
          salesAreaId: selectedSalesArea?.id,
        }),
      };

      axios(fetchHotelOptions)
        .then((response) => {
          const data = response.data as GetHotelsResponse;
          if (data.response === 'OK') {
            setHotelsResponse(data);
            setHotelName(data.hotels[0].name);
          } else {
            const err = response.data as ErrorResponse;
            setHotelsResponse(err);
          }
        })
        .catch(() => {
          dispatch(
            addNotification(
              'There was an unexpected error fetching Hotels.',
              'danger',
            ),
          );
        })
        .finally(() => {
          setHotelsLoading(false);
          setIsFetchingHotels(false);
        });
    }
  };

  const chargeToRoom = () => {
    setChargeToRoomResponse(undefined);
    if (!isSubmittingChargeToRoom) {
      setIsSubmittingChargeToRoom(true);
      const serviceBasedParams = getServiceParams();

      const additionalInfoParams = shouldShowAdditionalInfo
        ? { additionalInformation: getAdditionalInfo() }
        : undefined;

      const submitChargeToRoomConfig = submitChargeToRoom();

      const chargeToRoomOptions: AxiosRequestConfig = {
        url,
        method: 'POST',
        data: requestBodyFormatter({
          ...submitChargeToRoomConfig.body,
          method: submitChargeToRoomConfig.method,
          salesAreaId: selectedSalesArea?.id,
          siteId: selectedVenue?.id,
          tip: tipAmount,
          basketId,
          hotel: hotelName,
          ...serviceBasedParams,
          ...additionalInfoParams,
          folio: {
            guestName: folioGuestName,
            folioId: selectedFolio?.folioId,
            folioIndex: selectedFolio?.folioIndex,
            roomNumber: folioRoomNumber,
          },
        }),
      };

      axios(chargeToRoomOptions)
        .then((response) => {
          const data = response.data as MakeChargeToRoomResponse;
          if (data.response === 'OK') {
            setChargeToRoomResponse(data);
            setOrderId(data?.accountNumber);
            clearBasket();

            history.push({
              pathname: '/complete',
              state: { from: { pathname: '/checkout' } },
            });
          } else {
            const err = response.data as ErrorResponse;

            if (response.data.code === -999) {
              dispatch(setAdditionalInfoError999(response.data.detail));
            } else {
              setChargeToRoomResponse(err);
              setIsSubmittingChargeToRoom(false);
              setIsCharging(false);
            }
          }
        })
        .catch(() => {
          dispatch(
            addNotification(
              'There was an unexpected error making a charge to room',
              'danger',
            ),
          );
          setIsSubmittingChargeToRoom(false);
          setIsCharging(false);
        });
    }
  };

  const getServiceParams = () => {
    if (shouldShowAdditionalInfo) {
      return { timeslot: selectedTimeslot?.time };
    } else if (selectedService === ServiceType.OrderAndPay) {
      return { table: selectedTable?.number };
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  const resetState = () => {
    setFolioGuestName('');
    setFolioRoomNumber('');
    setIsFetchingFolios(false);
    setFoliosLoading(false);
    setFoliosResponse(undefined);
    setIsSubmittingChargeToRoom(false);
    setSelectedFolio(undefined);
    setIsCharging(false);
    setChargeToRoomResponse(undefined);
  };

  return (
    <HotelContext.Provider
      value={{
        initialised: true,
        folios: {
          foliosLoading,
          foliosResponse,
          roomNumber: folioRoomNumber,
        },
        hotels: {
          hotelsLoading,
          hotelsResponse,
          hotelName,
        },
        chargeToRoom: {
          isSubmittingChargeToRoom,
          chargeToRoomResponse,
        },
        checkFolios,
        checkHotels,
        makeChargeToRoom,
        resetState,
      }}
    >
      {children}
    </HotelContext.Provider>
  );
};
