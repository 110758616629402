import { useServices, useVenues } from 'contexts/VenueContext';
import { Button } from 'react-bootstrap';
import { FaChevronCircleRight } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { CombinedVenue, ServiceType, VenueSalesArea } from 'types/models';
import { ReactSelectOption } from 'types/react-select-virtualized';
import { doesSalesAreaSupportService, getServiceLabel } from 'venue/utils';

interface AtTableButtonProps {
  text: string;
  serviceId: number;
}

interface AtTableControlsProps {
  selectedVenue: CombinedVenue;
  selectedTable: ReactSelectOption;
  selectedSalesArea: VenueSalesArea;
}

export const AtTableControls: React.FC<AtTableControlsProps> = ({
  selectedVenue,
  selectedSalesArea,
  selectedTable,
}) => {
  const { setSelectedService } = useVenues();
  const { services } = useServices();
  const history = useHistory();
  const promptButtons: AtTableButtonProps[] = [];
  const venueAndSalesAreaCanOrder =
    selectedVenue.canOrder && selectedSalesArea.canOrder;
  const venueAndSalesAreaCanPlaceOrder =
    selectedVenue.canPlaceOrder && selectedSalesArea.canPlaceOrder;
  const orderAndPayId = ServiceType.OrderAndPay;
  const payMyBillId = ServiceType.PayMyBill;

  if (
    doesSalesAreaSupportService(selectedSalesArea, orderAndPayId) &&
    venueAndSalesAreaCanOrder
  ) {
    promptButtons.push({
      text: venueAndSalesAreaCanPlaceOrder
        ? getServiceLabel(services, orderAndPayId)
        : 'Browse Menus',
      serviceId: orderAndPayId,
    });
  }

  if (doesSalesAreaSupportService(selectedSalesArea, payMyBillId)) {
    promptButtons.push({
      text: getServiceLabel(services, payMyBillId),
      serviceId: payMyBillId,
    });
  }

  const handleButtonClick = (button: AtTableButtonProps) => {
    setSelectedService(button.serviceId);
    if (button.serviceId === payMyBillId) {
      history.push(
        `/venue/${selectedVenue.id}/sales-area/${selectedSalesArea.id}/service/${payMyBillId}/table/${selectedTable.number}`,
      );
    } else if (button.serviceId === orderAndPayId) {
      history.push(`/venue/${selectedVenue?.id}/order-and-pay/menus`);
    }
  };

  return (
    <>
      {promptButtons.map((button: AtTableButtonProps) => {
        return (
          <Button
            bsStyle="primary"
            className="btn-block service-button"
            data-testid={`venue-service-${button.serviceId}-button`}
            key={button.serviceId}
            onClick={() => handleButtonClick(button)}
          >
            {button.text}
            <FaChevronCircleRight
              className="service-button-proceed-icon"
              data-testid={`hyperlink-${button.serviceId}-button`}
            />
          </Button>
        );
      })}
    </>
  );
};
