import { Button } from 'react-bootstrap';
import { FaRegMap } from 'react-icons/fa';

interface MapViewButtonProps {
  onClick: () => void;
}

export const MapViewButton: React.FC<MapViewButtonProps> = (props) => {
  return (
    <Button className="venue-search-button" onClick={() => props.onClick()}>
      <FaRegMap size={16} />
      <span className="venue-search-button-label">{'Go to map view'}</span>
    </Button>
  );
};
