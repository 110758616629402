import { CustomFormattedNumber } from 'common/components/CustomFormattedNumber';
import { Keywords } from 'menu/components/Keywords';
import { MenuGroupProduct } from 'types/models';
import { useConfig } from 'contexts/ConfigContext';
import { useMenuGroups, useMenus } from 'contexts/MenuContext';
import { useSalesAreas, useServices, useVenues } from 'contexts/VenueContext';
import { MinimumAgeBadge } from 'menu/components/MinimumAge';
import { useMenuModal } from 'contexts/MenuModalContext';
import cs from 'classnames';
import { useBasket } from 'contexts/BasketContext';
import { ProductQuantityIndicator } from 'menu/components/MenuDisplayItems/ProductQuantityIndicator';
import {
  doesPortionHaveValidPrice,
  getAztecProduct,
  getProductDisplayRecord,
  getProductPortions,
  isControlledPortion,
  isProductAvailable,
  isProductOutOfStock,
  sortPortionsByPriceThenId,
} from 'menu/utils';
import { MenuItemDescription } from 'menu/components/MenuItemDescription';
import { ItemUnavailable } from 'menu/components/MenuDisplayItems/ItemUnavailable';
import { CalorieAmountDisplay } from 'menu/components/Calories';
import { isTimeslotService } from 'venue/utils';
import { useCheckout } from 'contexts/CheckoutContext';

interface ProductListItemProps {
  product: MenuGroupProduct;
  upsellGroupId: number | undefined;
}

export const ProductListItem: React.FC<ProductListItemProps> = ({
  product,
  upsellGroupId,
}) => {
  const { currency, currencySymbolsDisabled } = useConfig();
  const { menus, hyperlink } = useMenus();
  const { menuId, products, choiceGroups, upsellGroups } = useMenuGroups();
  const { handleSelectedMenuItem } = useMenuModal();
  const { getBasketQuantity } = useBasket();
  const selectedMenu = menus.find((m) => m.id === menuId);
  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();
  const { selectedSalesArea } = useSalesAreas();

  const venueCanPlaceOrder = selectedVenue?.canPlaceOrder;
  const salesAreaCanPlaceOrder = selectedSalesArea?.canPlaceOrder;

  // can we order from menu?
  const menuCanOrder = isTimeslotService(selectedService)
    ? true
    : selectedMenu?.canOrder ?? false;

  const { isFetchingBasket } = useCheckout();

  const aztecProduct = getAztecProduct(product.productId, products);
  // Check if product is available
  const isAvailable = isProductAvailable(aztecProduct, choiceGroups, products);
  // Check if product is in stock
  const isOutOfStock = isProductOutOfStock(aztecProduct);
  // Get product display record.
  const displayRecord = getProductDisplayRecord(
    aztecProduct,
    product.displayRecordId,
  );

  // Get product portions.
  const portions = getProductPortions(aztecProduct);

  const availablePortions = portions.filter(
    (portion) =>
      doesPortionHaveValidPrice(portion) &&
      isControlledPortion(product, portion),
  );

  // Sort by price (Lowest first).
  const sortedPortionsArray = isAvailable
    ? sortPortionsByPriceThenId(availablePortions)
    : [];

  const minimumAge = aztecProduct.minimumCustomerAge;

  const totalSelected = getBasketQuantity(
    product.productId,
    product.displayRecordId,
  );
  // Check if the product has choices.

  const classes = cs('list-group-item', 'list-group-item-border', {
    'list-group-item-border-selected': totalSelected > 0,
    'product-item-highlight':
      hyperlink.highlightProduct &&
      hyperlink.hyperlinkUiSpecificData.hyperlinkItemValue ===
        product.productId,
  });

  const outOfStockClass = isOutOfStock ? 'out-of-stock' : '';

  const handleAdd = () => {
    if (!isFetchingBasket) {
      handleSelectedMenuItem(
        product,
        products,
        choiceGroups,
        upsellGroupId,
        upsellGroups,
        menuId,
      );
    }
  };

  const canOrder = menuCanOrder && venueCanPlaceOrder && salesAreaCanPlaceOrder;
  const canSelectProduct = isAvailable && sortedPortionsArray.length > 0;

  return (
    <div
      id={`product-${product.productId}-${product.displayRecordId}`}
      className={classes}
      data-testid={`menu-group-item${menuCanOrder ? '-orderable' : ''}`}
      role="button"
      tabIndex={product.productId}
      onKeyDown={() => {
        if (canSelectProduct && canOrder && !isOutOfStock) {
          handleAdd();
        }
      }}
      onClick={() => {
        if (canSelectProduct && canOrder && !isOutOfStock) {
          handleAdd();
        }
      }}
      title="Add"
      aria-label="Add"
    >
      <div className="product-header-line" data-testid="list-view-item">
        <ProductQuantityIndicator product={product} />
        <div className="product-details">
          <div
            className={`item-name ${outOfStockClass}`}
            data-testid="menu-group-item-name"
          >
            {displayRecord.name}
          </div>
          <div className="product-keywords">
            <CalorieAmountDisplay
              displayRecord={displayRecord}
              portions={portions}
              isOutOfStock={isOutOfStock}
            />
            {minimumAge ? <MinimumAgeBadge minimumAge={minimumAge} /> : null}
            {displayRecord?.keywords?.length > 0 ? (
              <Keywords list={displayRecord.keywords} />
            ) : null}
          </div>
          <div className="item-information">
            {displayRecord.description ? (
              <MenuItemDescription
                description={displayRecord.description}
                isOutOfStock={isOutOfStock}
              />
            ) : null}
          </div>
          {canSelectProduct ? (
            <div
              className={`item-price ${outOfStockClass}`}
              data-testid="menu-group-item-price"
            >
              <CustomFormattedNumber
                style={currencySymbolsDisabled ? 'decimal' : 'currency'}
                value={sortedPortionsArray[0].price}
                currency={currency}
                minimumFractionDigits={2}
                precedingText={sortedPortionsArray.length > 1 ? 'From ' : ''}
              />
            </div>
          ) : (
            <ItemUnavailable />
          )}
        </div>
        {displayRecord.image !== '' ? (
          <div className="item-image">
            <img src={displayRecord.image} alt={displayRecord.name} />
          </div>
        ) : null}
      </div>
    </div>
  );
};
