import { useConfig } from 'contexts/ConfigContext';
import { IoSettingsSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';

export const FeatureFlagLink: React.FC = () => {
  const { env } = useConfig();
  return (
    (env === 'qa1' || env === 'qa2' || env === 'development') && (
      <Link to={'/ff'} data-testid="ff-link" className="header-link ff-page">
        <IoSettingsSharp className="link-icon" size={20} />
      </Link>
    )
  );
};