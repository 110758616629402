import { useAPI } from 'api/useAPI';
import { requestBodyFormatter } from 'api/utils';
import { AxiosRequestConfig } from 'axios';
import { useCheckout } from 'contexts/CheckoutContext';
import { useConfig } from 'contexts/ConfigContext';
import { useGlobalUser } from 'contexts/UserContext/GlobalUserContext';
import { addNotification } from 'core/actions';
import { Location } from 'history';
import { useAuthCookie } from 'hooks/useAuthCookie';
import { useAxiosInterceptor } from 'hooks/useAxiosInstance';
import { createContext, useContext, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ErrorResponse, UserResponse } from 'types/models';

interface UserSettingsContext {
  initialised: boolean;
  changePassword: (
    oldPassword: string,
    newPassword: string,
    newConfirmPassword: string,
  ) => void;
  editUser: (formData: FieldValues) => void;
  isFetchingPassword: boolean;
  isLoadingUserDetails: boolean;
  isRegisteringFromCheckout: boolean;
  registerUser: (formData: FieldValues, from: Location) => void;
  resetPassword: (email: string) => void;
  resetPasswordByToken: (newPassword: string, token: string) => void;
  setIsRegisteringFromCheckout: (status: boolean) => void;
}

export const UserSettingsContext = createContext<UserSettingsContext>({
  initialised: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changePassword: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  editUser: () => {},
  isFetchingPassword: false,
  isLoadingUserDetails: false,
  isRegisteringFromCheckout: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerUser: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetPassword: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetPasswordByToken: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsRegisteringFromCheckout: () => {},
});

export const useUserSettings = (): UserSettingsContext => {
  const consumer = useContext(UserSettingsContext);
  if (!consumer.initialised) {
    throw new Error('User Settings Context Provider not initialised');
  }
  return consumer;
};

interface UserSettingsContextProviderProps {
  children: React.ReactNode;
}

export const UserSettingsContextProvider: React.FC<
  UserSettingsContextProviderProps
> = ({ children }) => {
  const {
    loyalty: { shouldPromptForAssociationPostSignUp },
  } = useConfig();

  const {
    changePassword: changePasswordAPI,
    editUser: editUserAPI,
    registerUser: registerUserAPI,
    resetPassword: resetPasswordAPI,
    resetPasswordByToken: resetPasswordByTokenAPI,
    url,
  } = useAPI();
  const dispatch = useDispatch();
  const history = useHistory();
  const axios = useAxiosInterceptor();

  const { updateUser } = useGlobalUser();
  const { checkBasket } = useCheckout();
  const { setAuthCookie } = useAuthCookie();

  const [isRegistering, setIsRegistering] = useState(false);
  const [isRegisteringFromCheckout, setIsRegisteringFromCheckout] =
    useState(false);
  const [isEditingUser, setIsEditingUser] = useState(false);
  const [isFetchingPassword, setIsFetchingPassword] = useState(false);

  const registerUser = (formData: FieldValues, from: Location) => {
    setIsRegistering(true);
    const registerUserConfig = registerUserAPI();
    const registerUserOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      data: requestBodyFormatter({
        method: registerUserConfig.method,
        ...registerUserConfig.body,
        user: formData,
      }),
    };

    axios(registerUserOptions)
      .then((response) => {
        if (response.data.user) {
          const data = response.data as UserResponse;
          updateUser(data.user);
          setAuthCookie(data['X-Auth-UserToken']);

          if (
            isRegisteringFromCheckout &&
            !shouldPromptForAssociationPostSignUp
          ) {
            // Could be a race condition here for the register page
            // Redirect if logged in user or is not fetching basket
            dispatch(addNotification('Thank you for signing up', 'success'));
            checkBasket(true);
            setIsRegisteringFromCheckout(false);
          } else if (shouldPromptForAssociationPostSignUp) {
            history.push({
              pathname: '/user/loyalty/register',
              state: { from },
            });
          } else {
            dispatch(addNotification('Thank you for signing up', 'success'));
            history.push({
              pathname: from.pathname,
              state: { from: '/user/register' },
            });
          }
        } else {
          const data = response.data as ErrorResponse;
          dispatch(addNotification(data.detail, 'danger', data.code));
        }
      })
      .catch(() => {
        dispatch(
          addNotification('Something went wrong, please try again', 'danger'),
        );
      })
      .finally(() => {
        setIsRegistering(false);
      });
  };

  const editUser = (formData: FieldValues) => {
    setIsEditingUser(true);
    const editUserConfig = editUserAPI();
    const editUserOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      data: requestBodyFormatter({
        method: editUserConfig.method,
        ...editUserConfig.body,
        user: formData,
      }),
    };

    axios(editUserOptions)
      .then((response) => {
        if (response.data.user) {
          const data = response.data as UserResponse;
          updateUser(data.user);
          dispatch(
            addNotification(
              'Your details have been updated successfully.',
              'success',
            ),
          );
        } else {
          const data = response.data as ErrorResponse;
          const errorMessage =
            data?.detail ??
            'Something went wrong updating your details. Please try again.';
          dispatch(addNotification(errorMessage, 'danger'));
        }
      })
      .catch(() => {
        dispatch(
          addNotification(
            'Something went wrong updating your details. Please try again.',
            'danger',
          ),
        );
      })
      .finally(() => {
        setIsEditingUser(false);
      });
  };

  const changePassword = (
    oldPassword: string,
    newPassword: string,
    newPasswordConfirm: string,
  ) => {
    setIsFetchingPassword(true);
    const changePasswordConfig = changePasswordAPI();
    const changePasswordOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      data: requestBodyFormatter({
        method: changePasswordConfig.method,
        ...changePasswordConfig.body,
        oldPassword,
        newPassword,
        newPasswordConfirm,
      }),
    };

    axios(changePasswordOptions)
      .then((response) => {
        if (response.data.response === 'OK') {
          dispatch(
            addNotification('Your password has been updated.', 'success'),
          );
          history.push('/venues');
        } else {
          const data = response.data as ErrorResponse;
          dispatch(addNotification(data.detail, 'danger'));
        }
      })
      .catch(() => {
        dispatch(
          addNotification('There was an error. Please try again.', 'danger'),
        );
      })
      .finally(() => {
        setIsFetchingPassword(false);
      });
  };

  // This is due to be refined later in 2025 and is not currently used
  const resetPasswordByToken = (newPassword: string, token: string) => {
    setIsFetchingPassword(true);
    const resetPasswordConfig = resetPasswordByTokenAPI();
    const resetPasswordOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      data: requestBodyFormatter({
        method: resetPasswordConfig.method,
        ...resetPasswordConfig.body,
        newPassword,
        token,
      }),
    };

    axios(resetPasswordOptions)
      .then((response) => {
        if (response.data.response === 'OK') {
          history.push({ pathname: '/user/login', state: { from: '/venues' } });
          dispatch(
            addNotification(
              'Password reset successfully! Please click here to login.',
              'success',
            ),
          );
        } else {
          const data = response.data as ErrorResponse;
          dispatch(addNotification(data.detail, 'danger'));
        }
      })
      .catch(() => {
        dispatch(
          addNotification(
            'Something went wrong resetting your password. Please try again.',
            'danger',
          ),
        );
      })
      .finally(() => {
        setIsFetchingPassword(false);
      });
  };

  const resetPassword = (email: string) => {
    setIsFetchingPassword(true);
    const resetPasswordConfig = resetPasswordAPI();
    const resetPasswordOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      data: requestBodyFormatter({
        method: resetPasswordConfig.method,
        ...resetPasswordConfig.body,
        email,
      }),
    };

    axios(resetPasswordOptions)
      .then((response) => {
        if (response.data.response === 'OK') {
          dispatch(
            addNotification(
              `An email containing a new password has been sent to ${email}.`,
              'success',
            ),
          );
        } else {
          const data = response.data as ErrorResponse;
          dispatch(addNotification(data.detail, 'danger'));
        }
      })
      .catch(() => {
        dispatch(
          addNotification(
            'Something went wrong resetting your password. Please try again.',
            'danger',
          ),
        );
      })
      .finally(() => {
        setIsFetchingPassword(false);
      });
  };

  return (
    <UserSettingsContext.Provider
      value={{
        initialised: true,
        changePassword,
        editUser,
        isFetchingPassword,
        isLoadingUserDetails: isRegistering || isEditingUser,
        isRegisteringFromCheckout,
        registerUser,
        resetPassword,
        resetPasswordByToken,
        setIsRegisteringFromCheckout,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};
