import { FormatDateOptions, useIntl } from 'react-intl';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import { useConfig } from 'contexts/ConfigContext';
import { CombinedVenue } from 'types/models';

interface ItemOpeningTimesProps {
  venue: CombinedVenue;
}
export const ItemOpeningTimes: React.FC<ItemOpeningTimesProps> = ({
  venue,
}) => {
  const { openingTimes24hFormat } = useConfig();
  const intl = useIntl();

  let { openingTimes } = venue;
  if (openingTimes.length > 7) {
    openingTimes = uniqBy(openingTimes, 'label');
  }

  const formatOptions: FormatDateOptions = {
    hourCycle: openingTimes24hFormat === true ? 'h23' : 'h12',
  };

  return (
    <div className="opening-times">
      {uniq(openingTimes).map((day) => {
        const [openingHours, openingMinutes] = day.openingTime.split(':');
        const openingDate = new Date();
        openingDate.setHours(Number(openingHours), Number(openingMinutes));

        const [closingHours, closingMinutes] = day.closingTime.split(':');
        const closingDate = new Date();
        closingDate.setHours(Number(closingHours), Number(closingMinutes));

        return (
          <div className="opening-times-line" key={day.label}>
            <strong>{day.label}</strong>
            <span className="text-muted text-right">
              {day.closed
                ? 'CLOSED'
                : `${intl.formatTime(
                    openingDate,
                    formatOptions,
                  )} - ${intl.formatTime(closingDate, formatOptions)}`}
            </span>
          </div>
        );
      })}
    </div>
  );
};
