import { usePhrases } from 'contexts/ConfigContext';
import { useSalesAreas, useServices, useVenues } from 'contexts/VenueContext';
import { createRef, useEffect, useState } from 'react';
import { Menu } from 'types/models';
import { isTimeslotService } from 'venue/utils';

interface CanOrderBannerProps {
  menu?: Menu;
  shouldCalculateWidth?: boolean;
}
export const CanOrderBanner: React.FC<CanOrderBannerProps> = ({
  menu,
  shouldCalculateWidth = false,
}) => {
  const { salesAreaPhrase } = usePhrases();
  const { selectedVenue } = useVenues();
  const { selectedSalesArea } = useSalesAreas();
  const { selectedService } = useServices();
  const containerRef = createRef<HTMLDivElement>();
  const [containerWidth, setContainerWidth] = useState('100vw');

  const menuCanOrder = isTimeslotService(selectedService)
    ? true
    : menu?.canOrder;

  const getMessage = (): string | null => {
    if (!selectedVenue?.canPlaceOrder) {
      return `Online ordering is currently unavailable at ${selectedVenue?.name}.`;
    }

    if (!selectedSalesArea?.canPlaceOrder) {
      return `Online ordering is currently unavailable at ${salesAreaPhrase}: ${
        selectedSalesArea?.friendly || selectedSalesArea?.name
      }.`;
    }

    if (!menu) {
      return null;
    }

    if (!menuCanOrder) {
      return menu?.name
        ? `Online ordering is currently unavailable for ${menu.name}.`
        : 'Menu is currently unavailable.';
    }

    return null;
  };

  const bannerMessage = getMessage();

  useEffect(() => {
    /*Is there a scrollbar? What's the width? reduce the width of the container*/
    const scrollBarWidth = (window.innerWidth - document.body.clientWidth) / 2;
    setContainerWidth(`calc(100vw - ${scrollBarWidth}px`);
  }, [containerRef]);

  return bannerMessage ? (
    <div
      className="can-order-banner"
      data-testid="banner-order-unavailable"
      style={shouldCalculateWidth ? { width: containerWidth } : {}}
    >
      {bannerMessage}
    </div>
  ) : null;
};
