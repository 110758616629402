import { Button } from 'react-bootstrap';
import { AcceptsPayment } from './components/AcceptsPayment';
import { ApplePayButtonColourSelect } from './components/ApplePayButtonColour';
import { BasketTitleInput } from './components/BasketTitle';
import { BranchTitleTextInput } from './components/BranchTitle';
import { CVVNumberTextInput } from './components/CVVNumber';
import { CardNumberTextInput } from './components/CardNumber';
import { ClientPaymentNameTextInput } from './components/ClientPaymentName';
import { Currency } from './components/Currency';
import { CurrencySymbolToggle } from './components/RemoveCurrencySymbol';
import { GalleryViewToggle } from './components/GalleryView';
import { GoogleMapsApiKey } from './components/GoogleMapsApiKey';
import { GooglePayMerchantId } from './components/GooglePayMerchantId';
import { GuestCheckoutPhrase } from './components/GuestCheckoutPhrase';
import { GuestCheckoutTitle } from './components/GuestCheckoutTitlePhrase';
import { GuestCheckoutToggle } from './components/GuestCheckoutEnabled';
import { HomePageInput } from './components/Homepage';
import { HomeTextInput } from './components/HeaderHomeText';
import { LoginButtonText } from './components/LoginButtonText';
import { LoginContinueAsGuest } from './components/LoginContinueAsGuest';
import { LoginCreateAccountText } from './components/LoginCreateAccountText';
import { LoginForgottenPasswordText } from './components/LoginForgottenPasswordText';
import { LogoLinkToHomepageToggle } from './components/LinkToHomepage';
import { MaxNumberOfVouchersText } from './components/MaxNumberOfVouchers';
import { MenuAspectRatioToggle } from './components/MenuAspectRatio';
import { MenuTextInput } from './components/MenuText';
import { MinAgePhraseInput } from './components/MinAgePhrase';
import { MultiMenuToggle } from './components/MultipleMenusEnabled';
import { OpeningTimesToggle } from './components/OpeningTimes';
import { PageTitleInput } from './components/PageTitle';
import { PayPalButtonColourSelect } from './components/PayPalButtonColour';
import { PostcodeTextInput } from './components/Postcode';
import { RemoveBackLinkToggle } from './components/RemoveBackLink';
import { ReorderTextInput } from './components/ReorderText';
import { ReorderToggle } from './components/Reorder';
import { SalesAreaAtTableHeadlineTextInput } from './components/SalesAreaAtTableHeadline';
import { SalesAreaAtTableSubtitleTextInput } from './components/SalesAreaAtTableSubtitle';
import { SalesAreaClickAndCollectHeadlineTextInput } from './components/SalesAreaClickAndCollectHeadline';
import { SalesAreaClickAndCollectSubtitleTextInput } from './components/SalesAreaClickAndCollectSubtitle';
import { SalesAreaPhraseTextInput } from './components/SalesAreaPhrase';
import { SpecialRequestToggle } from './components/SpecialRequest';
import { StickyBasketToggle } from './components/StickyBasket';
import { SupportsTipToggle } from './components/SupportsTip';
import { TablePhraseInput } from './components/TablePhrase';
import { TimeslotTimesToggle } from './components/TimeslotTimes';
import { VenuePhraseInput } from './components/VenuePhrase';
import { VenueSalesAreaFormatInput } from './components/VenueSalesAreaFormat';
import { VenueSearchToggle } from './components/VenueSearch';
import { VenueSelectInput } from './components/VenueSelect';
import { VoucherButtonText } from './components/VoucherButtonText/VoucherButtonText';
import { VoucherCallToActionText } from './components/VoucherCallToAction';
import { VoucherPlaceholderText } from './components/VoucherPlaceholder';
import { VoucherSupportingText } from './components/VoucherSupportingText';
import { VouchersEnabledToggle } from './components/VouchersEnabled';
import { SupportsGiftCardRedemptionToggle } from './components/SupportsGiftCardRedemption/SupportsGiftCardRedemptionToggle';
import { GiftCardCallToActionTitleTextInput } from './components/GiftCardCallToActionTitle';
import { GiftCardPhraseTextInput } from './components/GiftCardPhrase';
import { GiftCardRedeemButtonTitleTextInput } from './components/GiftCardRedeemButtonTitle';
import { GiftCardRedeemSupportingTextInput } from './components/GiftCardRedeemSupportingText';
import { PrivacyPolicyUrlInput } from './components/PrivacyPolicyUrl';
import { CaptchaDisabledToggle } from './components/CaptchaDisabled';
import { useHistory } from 'react-router';
import { CalorieAllowancePhrase } from 'featureflags/components/CalorieAllowancePhrase';
import { ShowCalorieAllowancePhrase } from 'featureflags/components/ShowCalorieAllowancePhrase';
import { VenueHomeBanners } from 'featureflags/components/VenueHomeBanners';
import { AccountDeletionOnly } from 'featureflags/components/AccountDeletionOnly';
import { VenueHomeHeaderBanner } from 'featureflags/components/VenueHomeHeaderBanner';
import { AllergenPromptToggle } from 'featureflags/components/AllergegnPrompt';
import { LoyaltyCardPhraseTextInput } from 'featureflags/components/LoyaltyCardPhrase';
import { LoyaltyArticlePhraseTextInput } from 'featureflags/components/LoyaltyArticlePhrase';
import { ShowLoyaltyCardToggle } from 'featureflags/components/LoyaltyShowCard';
import { LoyaltyCanAssociateToggle } from 'featureflags/components/LoyaltyCanAssociate';
import { LoyaltyPromptAssociationToggle } from 'featureflags/components/LoyaltyPromptAssociation';

export const FeatureFlagPage: React.FC = () => {
  const history = useHistory();

  const returnToWLWO = () => {
    history.push('/');
  };

  const resetToAPI = () => {
    alert('not implemented yet');
  };

  return (
    <div className="container">
      <div className="ff-button-bar panel panel-default">
        <Button bsStyle="danger" onClick={resetToAPI}>
          Reset to API
        </Button>
        <Button
          bsStyle="info"
          onClick={returnToWLWO}
          data-testid="ff-return-home"
        >
          Return to WLWO
        </Button>
      </div>
      <div className="ff-panel panel panel-default">
        <h2>Config settings</h2>
        <small>
          Toggle settings or update text fields. Toggles will instantly update
          and textboxes will update upon blur.
        </small>
      </div>
      <div className="ff-panel panel panel-default">
        <h2>Global config</h2>
        <small>Global settings that impact the entire application</small>
        <Currency />
        <CurrencySymbolToggle />
        <h3>Minimum Age config</h3>
        <small>Config for the minimum age prompt</small>
        <MinAgePhraseInput />
        <h3>Cookie Banner</h3>
        <PrivacyPolicyUrlInput />
        <h3>Calorie Allowance</h3>
        <CalorieAllowancePhrase />
        <ShowCalorieAllowancePhrase />
        <AccountDeletionOnly />
        <AllergenPromptToggle />
      </div>
      <div className="ff-panel panel panel-default">
        <h2>Site config</h2>
        <small>Settings related to browser</small>
        <PageTitleInput />
        <HomeTextInput />
        <HomePageInput />
        <RemoveBackLinkToggle />
        <LogoLinkToHomepageToggle />
        <BranchTitleTextInput />
        <VenueSelectInput />
        <CaptchaDisabledToggle />
      </div>
      <div className="ff-panel panel panel-default">
        <h2>Basket config</h2>
        <small>Settings related to basket</small>
        <BasketTitleInput />
        <StickyBasketToggle />
      </div>
      <div className="ff-panel panel panel-default">
        <h2>Checkout config</h2>
        <small>Settings related to checkout</small>
        <SupportsTipToggle />
        <h3>Vouchering</h3>
        <small>Settings related to vouchers</small>
        <VouchersEnabledToggle />
        <MaxNumberOfVouchersText />
        <VoucherCallToActionText />
        <VoucherButtonText />
        <VoucherPlaceholderText />
        <VoucherSupportingText />
        <h3>Gift Cards</h3>
        <small>Settings related to Gift Cards</small>
        <SupportsGiftCardRedemptionToggle />
        <GiftCardCallToActionTitleTextInput />
        <GiftCardPhraseTextInput />
        <GiftCardRedeemButtonTitleTextInput />
        <GiftCardRedeemSupportingTextInput />
        <h3>Payment</h3>
        <small>Settings related to Payment</small>
        <AcceptsPayment />
        <CardNumberTextInput />
        <CVVNumberTextInput />
        <PostcodeTextInput />
        <h4>Apple Pay</h4>
        <ClientPaymentNameTextInput />
        <ApplePayButtonColourSelect />
        <h4>PayPal</h4>
        <PayPalButtonColourSelect />
        <h4>Google Pay</h4>
        <GooglePayMerchantId />
      </div>
      <div className="ff-panel panel panel-default">
        <h2>Guest Checkout config</h2>
        <small>Settings related to guest checkout</small>
        <GuestCheckoutToggle />
        <GuestCheckoutTitle />
        <GuestCheckoutPhrase />
      </div>
      <div className="ff-panel panel panel-default">
        <h2>Login Page Config</h2>
        <small>Settings related to the login page</small>
        <LoginContinueAsGuest />
        <LoginCreateAccountText />
        <LoginForgottenPasswordText />
        <LoginButtonText />
      </div>
      <div className="ff-panel panel panel-default">
        <h2>Loyalty config</h2>
        <small>Settings related to loyalty</small>
        <ShowLoyaltyCardToggle />
        <LoyaltyCardPhraseTextInput />
        <LoyaltyArticlePhraseTextInput />
        <LoyaltyCanAssociateToggle />
        <LoyaltyPromptAssociationToggle />
      </div>
      <div className="ff-panel panel panel-default">
        <h2>Menu config</h2>
        <small>Settings related to menus</small>
        <MenuTextInput />
        <MultiMenuToggle />
        <GalleryViewToggle />
        <ReorderToggle />
        <ReorderTextInput />
        <SpecialRequestToggle />
        <MenuAspectRatioToggle />
      </div>
      <div className="ff-panel panel panel-default">
        <h2>Sales Area config</h2>
        <small>Settings related to sales areas</small>
        <SalesAreaPhraseTextInput />
        <SalesAreaAtTableHeadlineTextInput />
        <SalesAreaAtTableSubtitleTextInput />
        <SalesAreaClickAndCollectHeadlineTextInput />
        <SalesAreaClickAndCollectSubtitleTextInput />
        <VenueSalesAreaFormatInput />
      </div>
      <div className="ff-panel panel panel-default">
        <h2>Venue config</h2>
        <small>Settings related to venues or venue display</small>
        <VenuePhraseInput />
        <TablePhraseInput />
        <OpeningTimesToggle />
        <TimeslotTimesToggle />
        <VenueSearchToggle />
        <VenueHomeBanners />
        <VenueHomeHeaderBanner />
        <br />
        <h4>Venue Map Config</h4>
        <small>Settings related to the venue map</small>
        <GoogleMapsApiKey />
      </div>
    </div>
  );
};
