import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useGlobalUser } from 'contexts/UserContext';

export const AuthenticatedRoute: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { isLoggedIn } = useGlobalUser();

  const ComponentElement = Component as React.ElementType;

  return (
    <Route
      {...rest}
      render={(props): React.ReactNode => {
        if (isLoggedIn) {
          return <ComponentElement {...props} />;
        }

        return (
          <Redirect
            to={{
              pathname: '/user/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
