import { Button } from 'react-bootstrap';
import { CombinedVenue } from 'types/models';
import { Link } from 'react-router-dom';
import { useSalesAreas } from 'contexts/VenueContext';
import { IoEllipsisHorizontalCircle } from 'react-icons/io5';

interface VenueInfoHeaderProps {
  venue: CombinedVenue;
  handleOpen?: () => void;
  isTitleLink?: boolean;
}

export const VenueInfoHeader: React.FC<VenueInfoHeaderProps> = ({
  venue,
  handleOpen,
  isTitleLink = false,
}) => {
  const { selectedSalesArea } = useSalesAreas();
  const hasMultipleSalesAreas = venue.salesArea.length > 1;
  const salesAreaName =
    hasMultipleSalesAreas && selectedSalesArea && !handleOpen
      ? ` (${selectedSalesArea.name})`
      : '';

  return (
    <div className="venue-info-header" data-testid="link-venue-header">
      <div
        className="venue-info-header-title"
        data-testid="link-venue-header-title"
      >
        <h4>
          {isTitleLink ? (
            <Link to={`/venue/${venue.id}`}>{venue.name}</Link>
          ) : (
            venue.name
          )}
          {salesAreaName}
        </h4>
        {handleOpen ? (
          <Button
            className="icon-button"
            onClick={handleOpen}
            aria-label="Open venue details"
          >
            <IoEllipsisHorizontalCircle size={24} />
          </Button>
        ) : null}
      </div>
    </div>
  );
};
