import { useEffect, useState } from 'react';
import braintreeClient from 'braintree-web/client';
import threeDSecure from 'braintree-web/three-d-secure';
import dataCollector from 'braintree-web/data-collector';
import hostedFields from 'braintree-web/hosted-fields';
import { usePhrases } from 'contexts/ConfigContext';
import { useDispatch, useSelector } from 'react-redux';
import { selectTipAmount } from 'checkout/selectors';
import { addNotification, removeNotification } from 'core/actions';
import { PaymentIcons } from 'checkout/components/Payment';
import { GuestCheckoutForm } from 'checkout/components/Payment/GuestCheckout';
import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { FormProvider, useForm } from 'react-hook-form';
import { useAdditionalInformation } from 'contexts/AdditionalInformationContext';
import { AdditionalFieldsForm } from 'checkout/components/Payment/AdditionalFields';
import { normaliseFormData } from 'checkout/utils';
import { useServices } from 'contexts/VenueContext';
import {
  useCheckout,
  useCheckoutDetails,
  usePayment,
} from 'contexts/CheckoutContext';
import { PaymentType } from 'types/models/Payment';
import { usePayMyBill } from 'contexts/PayMyBillContext/PayMyBillContext';
import { useGlobalUser } from 'contexts/UserContext';

export const HostedFields = ({ isPmb = false }) => {
  const { cardNumber, cvvNumber, postalCode } = usePhrases();
  const [supportedCards, setSupportedCards] = useState([]);

  const dispatch = useDispatch();
  const { isLoggedIn, user } = useGlobalUser();

  const tipAmount = useSelector(selectTipAmount);
  const { basketTotal, braintreeToken } = useCheckoutDetails();
  const {
    braintreeToken: pmbBraintreeToken,
    paymentAmount,
    submitPayment: submitPmbPayment,
  } = usePayMyBill();

  const braintreeTokenToUse = isPmb ? pmbBraintreeToken : braintreeToken;
  const totalToUse = isPmb ? paymentAmount : basketTotal;

  const {
    savedLocationInformation,
    locationInformationFields,
    userInformationFields,
  } = useAdditionalInformation();

  const { selectedService } = useServices();
  const { submitPayment } = useCheckout();
  const {
    setPaymentEnabled,
    isSubmittingPayment,
    setIsSubmittingPayment,
    setPaymentInitError,
    setPaymentReady,
    setPaymentDisplayName,
  } = usePayment();

  const methods = useForm({ mode: 'all' });

  const normaliseTokenizingError = (error) => {
    let normalisedError =
      'Card validation failed, please check the details and try again.';

    if (error.name && error.message && error.details) {
      const errors = error.details.invalidFieldKeys.map((key) => {
        switch (key) {
          case 'number':
            return 'card number';

          case 'cvv':
            return 'CVV';

          case 'expirationMonth':
            return 'expiration month';

          case 'expirationYear':
            return 'expiration year';

          case 'postalCode':
            return 'postal code';

          default:
            return 'all fields';
        }
      });

      normalisedError = `Please check ${errors.join(', ')}`;
    }

    return normalisedError;
  };

  const normaliseThreeDSecureError = (error) => {
    // if (error.name && error.details && error.details.originalError) {
    // User friendly error messages are deeply buried, as evidenced below.
    return (
      error?.details?.originalError?.message ||
      error?.details?.originalError?.details?.originalError?.error?.message ||
      'There was a problem validating your card.'
    );
  };

  const showError = (error) => {
    dispatch(addNotification(error, 'danger'));
  };

  const handleMarkCardReady = () => {
    setPaymentInitError(false);
    setPaymentReady(PaymentType.CreditCard, true);
  };

  const handleMarkCardUnready = () => {
    setPaymentInitError(false);
    setPaymentReady(PaymentType.CreditCard, false);
  };

  const handleSubmitPayment = (formData, deviceData, nonce) => {
    if (isPmb) {
      submitPmbPayment(formData, deviceData, nonce, PaymentType.CreditCard);
    } else {
      const normalisedFormData = normaliseFormData(
        locationInformationFields,
        userInformationFields,
        { ...savedLocationInformation, ...formData },
      );

      submitPayment(
        normalisedFormData,
        deviceData,
        nonce,
        PaymentType.CreditCard,
      );
    }
  };

  const removeError = () => {
    dispatch(removeNotification());
  };

  const [hfInstance, setHFInstance] = useState();
  const [threeDSInstance, setThreeDSInstance] = useState();
  const [deviceData, setDeviceData] = useState();
  const [threeDSEnabled, setThreeDSEnabled] = useState(true);

  const totalAmount = (totalToUse + tipAmount).toFixed(2);

  const handlePaymentSubmit = (event) => {
    setIsSubmittingPayment(true);

    removeError();

    hfInstance.tokenize((tokenizeErr, payload) => {
      if (tokenizeErr) {
        showError(normaliseTokenizingError(tokenizeErr));
        setIsSubmittingPayment(false);
        return;
      }

      if (payload.nonce === undefined) {
        Sentry.captureEvent({
          message: `threeDSecureInstance.verifyCard no nonce`,
          extra: {
            tokenizeErr,
            payload,
          },
        });
      }

      let threeDSecureBody = {};

      const emailFor3ds = isLoggedIn ? user.email : event.email;

      if (isLoggedIn) {
        threeDSecureBody = {
          amount: totalAmount,
          nonce: payload.nonce,
          bin: payload.details.bin,
          email: user.email,
          billingAddress: {
            givenName: user.first_name,
            surname: user.last_name,
            phoneNumber: user.mobile_phone,
            streetAddress: user.address1,
            locality: user.county,
            postalCode: String(user.postcode),
          },
        };
      } else {
        threeDSecureBody = {
          amount: totalAmount,
          nonce: payload.nonce,
          bin: payload.details.bin,
          email: emailFor3ds,
        };
      }

      if (threeDSEnabled === true) {
        threeDSInstance.verifyCard(
          {
            ...threeDSecureBody,
            onLookupComplete(data, next) {
              // use `data` here, then call `next()`
              next();
            },
          },
          function (err, verifyCardPayload) {
            if (err) {
              showError(normaliseThreeDSecureError(err));
              setIsSubmittingPayment(false);
              console.error(
                'threeDSecureInstance.verifyCard error',
                threeDSecureBody,
                err,
              );

              Sentry.configureScope((scope) => {
                scope.setExtra('threeDSecureBody', threeDSecureBody);
                scope.setExtra();
                // scope.clear();
              });

              const extraData = {
                threeDSecureBody,
                tokenizePayload: payload,
                tokenizeErr,
                innerError: err,
                verifyCardErr: err,
                verifyCardPayload,
                originalError: err?.originalError,
                selectedService,
                loginMethod: isLoggedIn ? 'logged in' : 'guest',
                liabilityShifted: verifyCardPayload?.liabilityShifted,
                liabilityShiftPossible:
                  verifyCardPayload?.liabilityShiftPossible,
              };

              Sentry.setUser({ email: threeDSecureBody.email });

              Sentry.captureEvent({
                message: `threeDSecureInstance.verifyCard ${
                  !isLoggedIn ? 'guest' : 'logged in'
                } ${
                  verifyCardPayload?.liabilityShifted === true
                    ? `warning`
                    : `fail`
                }`,
                extra: extraData,
              });

              return;
            }

            if (verifyCardPayload.liabilityShifted) {
              // Liability has shifted
              handleSubmitPayment(event, deviceData, verifyCardPayload.nonce);
            } else if (verifyCardPayload.liabilityShiftPossible) {
              // Liability may still be shifted
              // Decide if you want to submit the nonce
              handleSubmitPayment(event, deviceData, verifyCardPayload.nonce);
            } else {
              // Liability has not shifted and will not shift
              // Decide if you want to submit the nonce
              handleSubmitPayment(event, deviceData, verifyCardPayload.nonce);
            }
          },
        );
      } else {
        handleSubmitPayment(event, deviceData, payload.nonce);
      }
    }); // end tockenize
  };

  useEffect(() => {
    const initHostedFields = () => {
      if (!braintreeTokenToUse) {
        showError('Basket is not authorised');
        setPaymentInitError(true);

        return;
      }

      braintreeClient.create(
        {
          authorization: braintreeTokenToUse,
        },
        (clientError, client) => {
          if (clientError) {
            showError('Error connecting to payment service');
            setPaymentInitError(true);
            return;
          }

          if (
            client.getConfiguration().gatewayConfiguration
              .threeDSecureEnabled === true
          ) {
            threeDSecure.create(
              {
                version: 2,
                client,
              },
              (threeDSecureErr, threeDSecureInstance) => {
                if (threeDSecureErr) {
                  showError('Error initialising 3DSecure');

                  return;
                }
                setThreeDSInstance(threeDSecureInstance);
              },
            );
          }

          setThreeDSEnabled(
            client.getConfiguration().gatewayConfiguration.threeDSecureEnabled,
          );

          if (client.getConfiguration().gatewayConfiguration.paypalEnabled) {
            setPaymentEnabled(
              PaymentType.PayPal,
              client.getConfiguration().gatewayConfiguration.paypalEnabled,
            );

            setPaymentDisplayName(
              client.getConfiguration().gatewayConfiguration.paypal
                ?.displayName ?? '',
            );
          }
          setPaymentEnabled(
            PaymentType.ApplePay,
            client.getConfiguration().gatewayConfiguration.applePayWeb
              ? true
              : false,
          );
          setPaymentEnabled(
            PaymentType.GooglePay,
            client.getConfiguration().gatewayConfiguration.androidPay, //androidPay.enabled maybe should be used instead
          );

          dataCollector.create(
            { client, kount: true, paypal: true },
            (dataCollectorError, dataCollectorInstance) => {
              if (dataCollectorError) {
                showError(
                  `Error collecting device data ${JSON.stringify(
                    dataCollectorError,
                  )}`,
                );

                return;
              }

              const { deviceData } = dataCollectorInstance;
              setDeviceData(deviceData);

              hostedFields.create(
                {
                  client,
                  styles: {
                    input: {
                      color: '#333',
                    },
                    ':focus': {
                      color: 'black',
                    },
                  },
                  fields: {
                    number: {
                      selector: '#card-number',
                      placeholder: cardNumber || 'XXXX XXXX XXXX XXXX',
                      inputmode: 'numeric',
                    },
                    cvv: {
                      selector: '#cvv',
                      placeholder: cvvNumber || 'XXX',
                      inputmode: 'numeric',
                    },
                    expirationMonth: {
                      selector: '#expiration-month',
                      placeholder: 'MM',
                      inputmode: 'numeric',
                    },
                    expirationYear: {
                      selector: '#expiration-year',
                      placeholder: 'YYYY',
                      inputmode: 'numeric',
                    },
                    postalCode: {
                      selector: '#postal-code',
                      placeholder: postalCode || 'XXXXX',
                      type: 'text',
                    },
                  },
                },
                (hostedFieldsError, hostedFieldsInstance) => {
                  if (hostedFieldsError) {
                    showError('Error initialising card payment');
                    setPaymentInitError(true);
                    return;
                  }

                  const cardsWithimages = hostedFieldsInstance.getState().cards;
                  const configurationCards =
                    client.getConfiguration().gatewayConfiguration.creditCards
                      .supportedCardTypes;

                  // Need the comparison to be case insensitive
                  // Braintree returns MasterCard with inconsistent casing, which causes the image to not be rendered
                  const lowercaseCards = configurationCards.map((x) =>
                    x.toLowerCase(),
                  );

                  const supportedCards = cardsWithimages
                    .filter(
                      (card) =>
                        lowercaseCards.indexOf(card.niceType.toLowerCase()) >
                        -1,
                    )
                    .map((card) => card.type);

                  setSupportedCards(supportedCards);
                  handleMarkCardReady();

                  // save it for later
                  setHFInstance(hostedFieldsInstance);
                },
              );
            },
          );
        },
      );
    };

    setPaymentInitError(false);
    initHostedFields();

    // the old componentWillUnmount
    return () => {
      handleMarkCardUnready();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="payment-icons">
        <div className="card-payment-title-container">
          <h3 className="title">Pay with Card</h3>
          <PaymentIcons supportedCards={supportedCards} />
        </div>
      </div>
      <FormProvider {...methods}>
        <form
          className="panel-body"
          data-testid="hosted-fields"
          id="drop-in-form"
        >
          <GuestCheckoutForm />
          <AdditionalFieldsForm />
          <div className="row">
            <div className="form-group col-xs-12 col-md-8">
              <label htmlFor="card-number" className="control-label">
                Card Number
                <span className="required">*</span>
              </label>
              <div className="form-control" id="card-number" />
              <span className="helper-text" />
            </div>
            <div className="form-group col-xs-6 col-md-4">
              <label htmlFor="expiration-month" className="control-label">
                Expiry Date
                <span className="required">*</span>
              </label>
              <div className="flex">
                <div className="form-control" id="expiration-month" />
                <div className="form-control" id="expiration-year" />
              </div>
            </div>
            <div className="form-group col-xs-6">
              <label htmlFor="cvv" className="control-label">
                CVV
                <span className="required">*</span>
              </label>
              <div className="form-control" id="cvv" />
            </div>
            <div className="form-group col-xs-6">
              <label htmlFor="postal-code" className="control-label">
                Post code
                <span className="required">*</span>
              </label>
              <div className="form-control" id="postal-code" />
            </div>
          </div>
          <div>
            <button
              value="submit"
              id="submit"
              type="submit"
              onClick={methods.handleSubmit(handlePaymentSubmit)}
              className="btn btn-primary center-block checkout-fw-button"
              data-testid="card-payment-submit-button"
              disabled={isSubmittingPayment}
            >
              Pay with Card
              <span>
                {' '}
                (<FormattedCurrency value={totalAmount} />)
              </span>
            </button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
