import { usePhrases } from 'contexts/ConfigContext';
import { CombinedVenue, VenueSalesArea } from 'types/models';
import { Order } from 'types/models/Orders';
import { getOrderLocationPhrase, simpleDateToIsoString } from 'utils';
import { OrderHistoryItem } from './OrderHistoryItem';
import { Loader } from 'components/Loader';
import { useHistory } from 'react-router';
import { useVenues } from 'contexts/VenueContext';
import { useOrderHistory } from 'contexts/OrderHistoryContext';

interface OrderHistoryTableProps {
  orders: Order[];
  venues: CombinedVenue[];
}
export const OrderHistoryTable: React.FC<OrderHistoryTableProps> = ({
  orders,
  venues,
}) => {
  const { venueSalesAreaFormat } = usePhrases();
  const history = useHistory();
  const { isLoadingVenues } = useVenues();
  const { isLoadingOrders } = useOrderHistory();

  const goToOrder = (orderId: number) => {
    history.push(`/user/order/${orderId}`);
  };

  const orderTable =
    orders &&
    orders.length > 0 &&
    orders
      .sort((a, b) => {
        return (
          new Date(simpleDateToIsoString(b.orderTimestamp)).getTime() -
          new Date(simpleDateToIsoString(a.orderTimestamp)).getTime()
        );
      })
      .filter((x) => x.tableNumber !== undefined || x.timeSlot !== undefined)
      .length > 0 ? (
      orders.map((order) => {
        const reorderVenue = venues
          .filter((it: CombinedVenue) => it.id === order.venueId)
          .shift();

        let orderLocationName = 'unknown';

        if (reorderVenue) {
          orderLocationName = reorderVenue.name;

          if (reorderVenue.salesArea.length > 1) {
            const salesAreaOrderedFrom = reorderVenue.salesArea.find(
              (sa: VenueSalesArea) => sa.id === order.salesAreaId,
            );

            if (salesAreaOrderedFrom) {
              orderLocationName = getOrderLocationPhrase(
                venueSalesAreaFormat,
                reorderVenue,
                salesAreaOrderedFrom,
              );
            }
          }
        }
        return (
          <OrderHistoryItem
            key={order.orderId}
            order={order}
            orderLocationName={orderLocationName}
            goToOrder={goToOrder}
          />
        );
      })
    ) : (
      <tr>
        <td colSpan={5} className="text-center">
          No orders
        </td>
      </tr>
    );

  return (
    <table className="order-history-table table table-hover">
      <thead>
        <tr>
          <th>#</th>
          {/* Hide this one on desktop */}
          <th className="mobile-table-item">Order details</th>
          <th className="desktop-table-item">When</th>
          <th className="desktop-table-item">Where</th>
          <th className="desktop-table-item">Amount</th>
          <th className="desktop-table-item">Order Type</th>
        </tr>
      </thead>
      <tbody>
        {isLoadingOrders || isLoadingVenues ? (
          <tr>
            <td colSpan={5} className="text-center">
              <Loader />
            </td>
          </tr>
        ) : (
          orderTable
        )}
      </tbody>
    </table>
  );
};
