import { AtTableError } from 'at-table/AtTableError';
import { Loader } from 'components/Loader';
import { useTables } from 'contexts/TableContext';
import { useSalesAreas } from 'contexts/VenueContext';
import { useEffectSkipFirst } from 'hooks/useEffectSkipFirst';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { CombinedVenue, ServiceType, VenueSalesArea } from 'types/models';
import {
  doesSalesAreaSupportService,
  doesSalesAreaSupportServices,
  doesVenueSupportService,
  doesVenueSupportServices,
  findSalesAreaById,
  findTableByNumber,
} from 'venue/utils';
import { AtTableSuccess } from './AtTableSuccess';

interface ParamsProps {
  salesAreaId: string;
  tableNumber: string;
}

interface AtTableHomePageBodyProps {
  selectedVenue: CombinedVenue;
}

export const AtTableHomePageBody: React.FC<AtTableHomePageBodyProps> = ({
  selectedVenue,
}) => {
  const params = useParams<ParamsProps>();
  const { selectedSalesArea, setSelectedSalesArea } = useSalesAreas();
  const {
    fetchTables,
    hasGroups,
    isFetchingTables,
    tables,
    selectedTable,
    setSelectedTable,
  } = useTables();

  // true=success, false=error, undefined=loading
  const [isSuccess, setIsSuccess] = useState<boolean>();

  const salesAreaId = parseInt(params.salesAreaId, 10);
  const tableNumber = parseInt(params.tableNumber, 10);
  const isLoading = isSuccess === undefined;

  const foundSalesArea = findSalesAreaById(selectedVenue, salesAreaId);

  const sitInSupportedServices = [
    ServiceType.OrderAndPay,
    ServiceType.PayMyBill,
  ];

  const checkSuccess = (salesArea: VenueSalesArea) => {
    const venueSupportsServices = doesVenueSupportServices(
      selectedVenue,
      sitInSupportedServices,
    );

    const salesAreaSupportsServices = doesSalesAreaSupportServices(
      salesArea,
      sitInSupportedServices,
    );

    const venueCanOrder = selectedVenue.canOrder;
    const salesAreaCanOrder = salesArea.canOrder;
    const venueAndSalesSupportsPmb =
      doesVenueSupportService(selectedVenue, ServiceType.PayMyBill) &&
      doesSalesAreaSupportService(salesArea, ServiceType.PayMyBill);

    const venueCanOrderNow =
      venueAndSalesSupportsPmb || (venueCanOrder && salesAreaCanOrder);

    if (
      venueSupportsServices &&
      salesAreaSupportsServices &&
      venueCanOrderNow
    ) {
      setIsSuccess(true);
    } else {
      setIsSuccess(false);
    }
  };

  useEffect(() => {
    if (foundSalesArea && tableNumber) {
      fetchTables(selectedVenue.id, salesAreaId);
    } else {
      setIsSuccess(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffectSkipFirst(() => {
    // Find sales area and table after tables call has resolved
    if (!isFetchingTables) {
      const foundTable = findTableByNumber(tables, tableNumber, hasGroups);

      if (foundSalesArea && foundTable) {
        checkSuccess(foundSalesArea);
        setSelectedSalesArea(foundSalesArea);
        setSelectedTable(foundTable);
      } else {
        setIsSuccess(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tables]);

  if (isLoading) {
    return (
      <div className="panel panel-default">
        <Loader />
      </div>
    );
  }

  return isSuccess && selectedSalesArea && selectedTable ? (
    <AtTableSuccess
      selectedVenue={selectedVenue}
      selectedSalesArea={selectedSalesArea}
      selectedTable={selectedTable}
    />
  ) : (
    <AtTableError selectedVenue={selectedVenue} />
  );
};
