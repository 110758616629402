import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { PaymentPanel } from 'checkout/components/Payment';
import { Voucher } from 'checkout/components/Voucher';
import { CheckoutBasket } from 'checkout/components/CheckoutBasket';
import { Address } from 'checkout/components/Address';
import { Tip } from 'checkout/components/Tip';
import { LoyaltyRewards } from 'checkout/components/LoyaltyRewards';
import { SelectedServiceInformation } from 'checkout/components/SelectedServiceInformation';
import { TotalSummary } from 'checkout/components/TotalSummary';
import { useConfig } from 'contexts/ConfigContext';
import { useServices, useVenues } from 'contexts/VenueContext';
import { setTipAmount } from 'checkout/actions';
import { AdditionalInfo } from './DeliveryToLocation';
import { ServiceType } from 'types/models/Service';
import { GiftCard } from 'checkout/components/GiftCard';
import { GiftCardOverchargeModal } from './Modals/GiftCardOverchargeModal';
import { useDeviceIdentifier } from 'contexts/DeviceIdentifierContext';
import { HotelContextProvider } from 'contexts/HotelContext';
import { useTables } from 'contexts/TableContext/TableContext';
import { useCheckout, useCheckoutDetails } from 'contexts/CheckoutContext';
import { useTimeslots } from 'contexts/TimeslotContext/TimeslotContext';
import { getUrlPart } from 'utils';
import { useBasket } from 'contexts/BasketContext';
import { VenueInfoBar } from 'venue/components/SingleVenue/VenueInfo';

export const Checkout: React.FC = () => {
  const {
    acceptsPayment,
    supportsGiftCardRedemption,
    supportsTip,
    vouchersEnabled,
  } = useConfig();

  const dispatch = useDispatch();
  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();
  const { selectedTable } = useTables();

  const { isBasketChecked } = useCheckout();
  const { basketId, basketTotal } = useCheckoutDetails();
  const { basket } = useBasket();
  const { selectedTimeslot } = useTimeslots();

  const { deviceIdentifier } = useDeviceIdentifier();

  const voucherComp = useRef<HTMLFormElement | null>(null);

  const shouldRedirect =
    !selectedVenue ||
    !basketId ||
    !isBasketChecked ||
    basket.length <= 0 ||
    (!selectedTimeslot && !selectedTable);

  const getRedirectPath = () => {
    if (selectedService !== -1 && selectedVenue?.id) {
      const serviceUrlPart = getUrlPart(selectedService);
      return `/venue/${selectedVenue.id}/${serviceUrlPart}/menus/`;
    }
    return '/venues';
  };

  useEffect(() => {
    if (basketTotal === 0) {
      dispatch(setTipAmount(0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketTotal]);

  if (shouldRedirect) {
    return <Redirect to={getRedirectPath()} />;
  }

  return (
    <HotelContextProvider>
      <VenueInfoBar />
      <div className="container checkout-wrapper">
        <div className="details">
          <Address />
          <CheckoutBasket />
          <TotalSummary />
          <SelectedServiceInformation />
          {selectedService === ServiceType.DeliveryToLocation && (
            <AdditionalInfo />
          )}
        </div>
        <div className="actions">
          {vouchersEnabled && <Voucher ref={voucherComp} />}
          <LoyaltyRewards />
          {supportsGiftCardRedemption && deviceIdentifier && acceptsPayment ? (
            <GiftCard />
          ) : null}
          {acceptsPayment && supportsTip && <Tip />}
          {/* Until we do masonry or subgrid or something, this stops me having to try and rewrite the page and lose the sticky RHS column */}
          <TotalSummary />
          <PaymentPanel />
          <GiftCardOverchargeModal />
        </div>
      </div>
    </HotelContextProvider>
  );
};
