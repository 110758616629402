import { useEffect } from 'react';

import cs from 'classnames';
import { useFormContext, RegisterOptions } from 'react-hook-form';
import { Field } from 'types/models/Forms';
import {
  isNotOnlyWhitespace,
  isPhoneNumber,
  isRequired,
  maxLength,
  minLength,
  minValue,
} from 'core/components/RHF/validation';

interface EmailProps {
  field: Field;
}

export const PhoneNumber: React.FC<EmailProps> = ({ field }) => {
  const {
    formState: { errors },
    register,
    setValue,
  } = useFormContext();
  const { required, label, placeholder, name, disabled, min, max } = field;

  const hasError = errors[name] !== undefined;
  const fieldError = errors[name];

  const labelToUse = label ? label : name;

  const formGroupClasses = cs('form-group', hasError && 'has-error');

  const registerOptions: RegisterOptions = {
    ...isRequired(labelToUse, required),
    ...minValue(labelToUse, 1),
    ...maxLength(labelToUse, max ?? 15),
    ...minLength(labelToUse, min ?? 10),
    validate: {
      isPhoneNumber,
      ...(required && {
        whiteSpace: (value) => isNotOnlyWhitespace(value, labelToUse),
      }),
    },
  };

  const requiredLabel = required ? <span className="required">*</span> : null;

  useEffect(() => {
    if (field.content) {
      setValue(field.name, field.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={formGroupClasses}>
      <label className="control-label" htmlFor={name}>
        {label}
        {requiredLabel}
      </label>
      <input
        className="form-control"
        type="tel"
        placeholder={placeholder ? placeholder : labelToUse}
        aria-required={required ? 'true' : 'false'}
        aria-invalid={hasError ? 'true' : 'false'}
        {...register(name, registerOptions)}
        autoComplete="tel"
        disabled={disabled}
      />
      {fieldError && (
        <span className="help-block" role="alert">
          {fieldError.message}
        </span>
      )}
    </div>
  );
};
