import { GenericJSObject } from 'types/Core';
import { FetchParams } from 'types/FetchParams';

const setHeaders = (brandToken: string): HeadersInit => ({
  'X-Auth-BrandToken': brandToken, // c.get('brandToken'),
  'Content-Type': 'application/x-www-form-urlencoded',
});

const setHeadersAndUser = (brandToken: string, t: string): HeadersInit => {
  if (t) {
    return {
      'X-Auth-BrandToken': brandToken, //c.get('brandToken'),
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Auth-UserToken': t,
    };
  }

  return setHeaders(brandToken);
};

const setBody = (): GenericJSObject => ({
  platform: 'web',
  userDeviceIdentifier: 'web',
});

export const setupFetchParams = (token?: string): FetchParams => {
  const brandToken = window.env.REACT_APP_BRAND_TOKEN;
  return {
    url: window.env.REACT_APP_API,
    headers: token
      ? setHeadersAndUser(brandToken, token ?? '')
      : setHeaders(brandToken),
    body: setBody(),
  };
};
