import { Route, Switch } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { App } from 'App';
import { LegacyRouter } from 'routers/Legacy';
import { UserRouter } from 'routers/User';
import { FeatureFlagPage } from 'featureflags';
import { useConfig } from 'contexts/ConfigContext';
import { NotFound } from 'routers';

export const Routes: React.FC = () => {
  const { env, iosAppId, pageTitle } = useConfig();

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        {iosAppId ? (
          <meta name="apple-itunes-app" content={`app-id=${iosAppId}`} />
        ) : null}
      </Helmet>
      <Switch>
        <App>
          {env === 'qa1' || env === 'qa2' || env === 'development' ? (
            <Route path="/ff" component={FeatureFlagPage} />
          ) : null}
          <Route path="/user" component={UserRouter} />
          <Route component={LegacyRouter} />
        </App>
        <Route component={NotFound} />
      </Switch>
    </>
  );
};
