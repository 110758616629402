import { createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosRequestConfig } from 'axios';
import { useSalesAreas, useServices, useVenues } from 'contexts/VenueContext';
import { fetchBodyFormatter, sortWithSortOrder } from 'utils';
import { addNotification } from 'core/actions';
import { ErrorResponse, GetMenuPagesResponse } from 'types/models/Responses';
import {
  AztecPortion,
  AztecPortions,
  AztecProducts,
  ChoiceGroupDisplayRecord,
  ChoiceGroupDisplayRecords,
  ChoiceGroups,
  DisplayKeyword,
  DisplayRecord,
  DisplayRecords,
  MenuGroup,
  MenuGroupItem,
  MenuGroupItemHyperlink,
  MenuGroupItemImage,
  MenuGroupItemProduct,
  MenuGroupItemSubHeader,
  MenuGroupItemTextField,
  UpsellGroups,
  ServiceType,
} from 'types/models';
import { useAPI } from 'api/useAPI';
import { useTimeslots } from 'contexts/TimeslotContext/TimeslotContext';
import { useAxiosInterceptor } from 'hooks/useAxiosInstance';

interface MenuGroupContext {
  choiceGroups: ChoiceGroups;
  initialised: boolean;
  keywords: DisplayKeyword[];
  loadingMenuGroups: boolean;
  menuId: number;
  menuGroups: MenuGroup[];
  products: AztecProducts;
  upsellGroups: UpsellGroups;
  getMenuPages: (menuId: number) => void;
}

interface MenuGroupsObject {
  menuGroups: MenuGroup[];
  isProcessingData: boolean;
}

interface AztecProductsObject {
  aztecProducts: AztecProducts;
  isProcessingData: boolean;
}

interface KeywordsObject {
  keywords: DisplayKeyword[];
  isProcessingData: boolean;
}

interface ChoiceGroupsObject {
  choiceGroups: ChoiceGroups;
  isProcessingData: boolean;
}

interface UpsellGroupsObject {
  upsellGroups: UpsellGroups;
  isProcessingData: boolean;
}

// set up context
export const MenuGroupContext = createContext<MenuGroupContext>(
  {} as MenuGroupContext,
);

// set up hook
export const useMenuGroups = (): MenuGroupContext => {
  const consumer = useContext(MenuGroupContext);

  // Condition used to determine if Context Provider has been declared
  if (!consumer.initialised) {
    throw new Error('MenuGroupContextProvider not initialised');
  }

  return consumer;
};

// set up hook
export const useProducts = (): {
  products: AztecProducts;
  choiceGroups: ChoiceGroups;
  keywords: DisplayKeyword[];
  upsellGroups: UpsellGroups;
} => {
  const consumer = useContext(MenuGroupContext);

  // Condition used to determine if Context Provider has been declared
  if (!consumer.initialised) {
    throw new Error('MenuGroupContextProvider not initialised');
  }

  return {
    choiceGroups: consumer.choiceGroups,
    keywords: consumer.keywords,
    products: consumer.products,
    upsellGroups: consumer.upsellGroups,
  };
};

const sortMenuGroups = (menuGroups: MenuGroup[]) => {
  return menuGroups.sort((mga: MenuGroup, mgb: MenuGroup) =>
    sortWithSortOrder(mga, mgb),
  );
};

const formatMenuGroups = (menuGroups: MenuGroup[]): MenuGroup[] => {
  return sortMenuGroups(menuGroups).map((mg: MenuGroup) => {
    const { items, ...restOfMG } = mg;

    // rebuild items
    const newItems = items.map((item: MenuGroupItem) => {
      // we need an any here as nothing it typed!

      //determine item type
      // not sure this is the best way to determine type!
      switch (item.itemType) {
        case 'subHeader':
          return item as MenuGroupItemSubHeader;
        case 'image':
          return item as MenuGroupItemImage;
        case 'product':
          return item as MenuGroupItemProduct;
        case 'textField':
          return item as MenuGroupItemTextField;
        case 'hyperlink':
          return item as MenuGroupItemHyperlink;
        default:
          console.log(`We don't have a type for ${item.itemType}`);
      }
    });

    // create a new MenuGroup without items
    const newMg = {
      ...restOfMG,
      items: newItems,
    };

    return newMg as MenuGroup;
  });
};

// const formatProducts = (products: )

// product normalisation function
const normaliseMenuGroupsAndProducts = (
  response: GetMenuPagesResponse,
): {
  choiceGroups: ChoiceGroups;
  keywords: DisplayKeyword[];
  menuGroups: MenuGroup[];
  products: AztecProducts;
  upsellGroups: UpsellGroups;
} => {
  const menuGroups = formatMenuGroups(response.display.displayGroups);
  // TODO - fix types on GetMenuPageResponse
  // There's going to be a big knock on with this and the AztecProduct usage, will probably have to extend the interface
  const products: AztecProducts = {};
  response.aztec.products.forEach((product) => {
    products[product.id] = product;
    // Format display records
    if (product?.displayRecords) {
      const formattedDisplayRecords: DisplayRecords = {};
      product.displayRecords.forEach(
        (dr: DisplayRecord) => (formattedDisplayRecords[dr.id] = dr),
      );
      products[product.id].displayRecords = formattedDisplayRecords;
    }
    // Format portions
    if (product?.portions) {
      const formattedPortions: AztecPortions = {};
      product.portions.forEach(
        (portion: AztecPortion) => (formattedPortions[portion.id] = portion),
      );
      products[product.id].portions = formattedPortions;
    }
  });

  const choiceGroups: ChoiceGroups = {};
  response.aztec.choiceGroups.forEach((cg) => {
    // This was in the old normaliser - some choices don't have these keys so we need to set them
    // I think it happens in the case of cooking instructions
    const hasMax = cg.hasOwnProperty('maximum');
    const hasMin = cg.hasOwnProperty('minimum');

    if (!hasMin && !hasMax) {
      cg.minimum = 1;
      cg.maximum = 1;
      cg.inclusive = 1;
    } else {
      cg.minimum = cg.minimum ?? 0;
      cg.maximum = cg.maximum ?? 1;
      cg.inclusive = cg.inclusive ?? 0;
    }

    choiceGroups[cg.id] = cg;
    if (cg?.displayRecords) {
      const formattedDisplayRecords: ChoiceGroupDisplayRecords = {};
      cg.displayRecords.forEach(
        (dr: ChoiceGroupDisplayRecord) => (formattedDisplayRecords[dr.id] = dr),
      );
      choiceGroups[cg.id].displayRecords = formattedDisplayRecords;
    }
  });

  const keywords = response.display.keywords.map(
    (k: DisplayKeyword) => k as DisplayKeyword,
  );

  const upsellGroups: UpsellGroups = {};
  if (response.upsellGroups) {
    response.upsellGroups.forEach((upsellGroup) => {
      upsellGroups[upsellGroup.id] = upsellGroup;
    });
  }

  return { choiceGroups, keywords, menuGroups, products, upsellGroups };
};

interface MenuGroupContextProviderProps {
  children: React.ReactNode;
}

// set up provider
export const MenuGroupContextProvider: React.FC<
  MenuGroupContextProviderProps
> = ({ children }) => {
  const dispatch = useDispatch();
  const { selectedSalesArea } = useSalesAreas();
  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();
  const { selectedTimeslot } = useTimeslots();

  const { url, getMenuPages: getMenuPagesAPI } = useAPI();
  const axios = useAxiosInterceptor();

  const [menuGroupsState, setMenuGroupsState] = useState<MenuGroupsObject>({
    menuGroups: [],
    isProcessingData: true,
  });

  const [aztecProductsState, setAztecProductsState] =
    useState<AztecProductsObject>({
      aztecProducts: [],
      isProcessingData: true,
    });

  const [menuId, setMenuId] = useState<number>(-1);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [choiceGroupsState, setChoiceGroupsState] =
    useState<ChoiceGroupsObject>({
      choiceGroups: [],
      isProcessingData: true,
    });

  const [keywordsState, setKeywordsState] = useState<KeywordsObject>({
    keywords: [],
    isProcessingData: true,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [upsellGroupsState, setUpsellGroupsState] =
    useState<UpsellGroupsObject>({
      upsellGroups: [],
      isProcessingData: true,
    });

  // set optional params (could do this better)
  let optionalParams = {};

  if (selectedService == ServiceType.OrderAndPay) {
    optionalParams = { serviceId: selectedService };
  } else if (selectedService == ServiceType.ClickAndCollect) {
    optionalParams = {
      serviceId: selectedService,
      timeslot: selectedTimeslot?.time,
    };
  }

  const getMenuPages = (menuId: number) => {
    const getMenuPagesAPIConfig = getMenuPagesAPI();

    // set axios hook options
    const menuGroupOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      data: fetchBodyFormatter({
        method: getMenuPagesAPIConfig.method,
        ...getMenuPagesAPIConfig.body,
        menuId,
        salesAreaId: selectedSalesArea?.id,
        siteId: selectedVenue?.id,
        version: '1.0.0',
        ...optionalParams,
      }),
    };

    setMenuGroupsState({ menuGroups: [], isProcessingData: true });
    setAztecProductsState({
      aztecProducts: [],
      isProcessingData: true,
    });
    setKeywordsState({
      keywords: [],
      isProcessingData: true,
    });
    setMenuId(menuId);

    axios(menuGroupOptions)
      .then((response) => {
        const data = response.data as GetMenuPagesResponse;
        if (data.response === 'OK') {
          // This has to be after the other normalise as the second one alters the data
          // Was hurting my head in finding a fix
          const normalizedData = normaliseMenuGroupsAndProducts(data);

          setMenuGroupsState({
            menuGroups: normalizedData.menuGroups,
            isProcessingData: false,
          });
          setAztecProductsState({
            aztecProducts: normalizedData.products,
            isProcessingData: false,
          });
          setKeywordsState({
            keywords: normalizedData.keywords,
            isProcessingData: false,
          });
          setChoiceGroupsState({
            choiceGroups: normalizedData.choiceGroups,
            isProcessingData: false,
          });
          setUpsellGroupsState({
            upsellGroups: normalizedData.upsellGroups,
            isProcessingData: false,
          });
        } else {
          const errResponse = response.data as ErrorResponse;

          //Empty the menu groups on error. Shouldn't need to maintain these after an error happens
          setMenuGroupsState({
            menuGroups: [],
            isProcessingData: false,
          });
          setAztecProductsState({
            aztecProducts: [],
            isProcessingData: false,
          });
          setKeywordsState({
            keywords: [],
            isProcessingData: false,
          });
          setChoiceGroupsState({
            choiceGroups: [],
            isProcessingData: false,
          });
          setUpsellGroupsState({
            upsellGroups: [],
            isProcessingData: false,
          });
          dispatch(
            addNotification(
              `Error retrieving menus: ${errResponse.detail} (${errResponse.code})`,
              'danger',
            ),
          );
        }
      })
      .catch((error) => {
        setMenuGroupsState({
          menuGroups: [],
          isProcessingData: false,
        });
        dispatch(
          addNotification(
            `Error retrieving menus: ${error.toString()}`,
            'danger',
          ),
        );
      });
  };

  return (
    <MenuGroupContext.Provider
      value={{
        choiceGroups: choiceGroupsState.choiceGroups,
        keywords: keywordsState.keywords,
        initialised: true,
        loadingMenuGroups:
          menuGroupsState.isProcessingData ||
          aztecProductsState.isProcessingData ||
          keywordsState.isProcessingData ||
          choiceGroupsState.isProcessingData ||
          upsellGroupsState.isProcessingData,
        menuGroups: menuGroupsState.menuGroups,
        menuId,
        products: aztecProductsState.aztecProducts,
        upsellGroups: upsellGroupsState.upsellGroups,
        getMenuPages,
      }}
    >
      {children}
    </MenuGroupContext.Provider>
  );
};
