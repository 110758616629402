import dompurify from 'dompurify';
import { Field } from 'types/models/Forms';

interface HTMLFieldProps {
  field: Field;
}

export const HTMLField: React.FC<HTMLFieldProps> = ({ field }) => {
  const sanitizer = dompurify.sanitize;

  dompurify.addHook('afterSanitizeAttributes', (node) => {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener');
    }
  });

  if (field.content === undefined) {
    return null;
  }

  return <div className='form-group' dangerouslySetInnerHTML={{ __html: sanitizer(field.content) }} />;
};
