import { AtTableControls } from './AtTableControls';
import { usePhrases } from 'contexts/ConfigContext';
import { useServices } from 'contexts/VenueContext';
import {
  doesSalesAreaSupportService,
  doesVenueSupportService,
  getServiceLabel,
} from 'venue/utils';
import { CombinedVenue, ServiceType, VenueSalesArea } from 'types/models';
import { ReactSelectOption } from 'types/react-select-virtualized';

interface AtTableSuccessProps {
  selectedVenue: CombinedVenue;
  selectedTable: ReactSelectOption;
  selectedSalesArea: VenueSalesArea;
}

export const AtTableSuccess: React.FC<AtTableSuccessProps> = ({
  selectedVenue,
  selectedSalesArea,
  selectedTable,
}) => {
  const { table: tablePhrase } = usePhrases();
  const { services } = useServices();
  const venueAndSalesAreaCanOrder =
    selectedVenue.canOrder && selectedSalesArea.canOrder;

  const isOrderAndPaySupported =
    venueAndSalesAreaCanOrder &&
    doesVenueSupportService(selectedVenue, ServiceType.OrderAndPay) &&
    doesSalesAreaSupportService(selectedSalesArea, ServiceType.OrderAndPay);

  const isPayMyBillSupported =
    doesVenueSupportService(selectedVenue, ServiceType.PayMyBill) &&
    doesSalesAreaSupportService(selectedSalesArea, ServiceType.PayMyBill);

  const venueAndSalesAreaCanPlaceOrder =
    selectedVenue.canPlaceOrder && selectedSalesArea.canPlaceOrder;

  const orderAndPayLabel =
    getServiceLabel(services, ServiceType.OrderAndPay) || 'Order & Pay';

  const payMyBillLabel =
    getServiceLabel(services, ServiceType.PayMyBill) || 'Pay My Bill';

  const tableFriendlyName =
    selectedTable?.label ?? tablePhrase + selectedTable?.number;
  const venueName = selectedVenue.name;
  const venueCity = selectedVenue.address.town;
  const salesAreaName =
    selectedVenue.salesArea?.length > 1 ? ` (${selectedSalesArea?.name})` : '';

  const getVenueOptions = (): string => {
    const serviceLabels: string[] = [];
    if (isOrderAndPaySupported) {
      if (venueAndSalesAreaCanPlaceOrder) {
        serviceLabels.push(orderAndPayLabel);
      } else {
        serviceLabels.push('browse the menus');
      }
    }
    if (isPayMyBillSupported) {
      serviceLabels.push(payMyBillLabel);
    }

    return serviceLabels.join(' or ');
  };

  const getSubPhrase = () => {
    const unavailableServiceLabels: string[] = [];

    if (!isOrderAndPaySupported || !venueAndSalesAreaCanPlaceOrder) {
      unavailableServiceLabels.push(orderAndPayLabel);
    }

    if (!isPayMyBillSupported) {
      unavailableServiceLabels.push(payMyBillLabel);
    }

    const getSubPhraseIsAre =
      unavailableServiceLabels.length > 1 ? 'are' : 'is';

    if (unavailableServiceLabels.length > 0) {
      return `${unavailableServiceLabels.join(
        ' and ',
      )} ${getSubPhraseIsAre} currently unavailable.`;
    }
    return '';
  };

  const basePhrase = `You are at ${tableFriendlyName}, at ${venueName}${salesAreaName}, in ${venueCity}. Would you like to ${getVenueOptions()}?`;

  return (
    <div
      className="panel panel-default"
      data-testid="text-panel-venue-information"
    >
      <h4>{basePhrase}</h4>
      <div>{getSubPhrase()}</div>
      <hr />
      <div className="sit-in-buttons">
        <AtTableControls
          selectedVenue={selectedVenue}
          selectedSalesArea={selectedSalesArea}
          selectedTable={selectedTable}
        />
      </div>
    </div>
  );
};
