import { createContext, useContext, useEffect, useState } from 'react';

import { useConfig } from 'contexts/ConfigContext';
import { getDeviceIdKey, isCookieValid } from 'utils';
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';

interface DeviceIdentifierContextProps {
  initialised: boolean;
  deviceIdentifier: string | undefined;
  // generateDeviceId: () => void;
}

export const DeviceIdentifierContext =
  createContext<DeviceIdentifierContextProps>({
    initialised: false,
    deviceIdentifier: undefined,
  });

export const useDeviceIdentifier = (): DeviceIdentifierContextProps => {
  const consumer = useContext(DeviceIdentifierContext);
  if (!consumer.initialised) {
    throw new Error('DeviceIdentifierContext not initialised');
  }
  return consumer;
};

interface DeviceIdentifierContextProviderProps {
  children: React.ReactNode;
}

export const DeviceIdentifierContextProvider: React.FC<
  DeviceIdentifierContextProviderProps
> = ({ children }) => {
  const [deviceId, setDeviceId] = useState<string>();

  const { client } = useConfig();

  const [cookies] = useCookies([`${client.replace(/\s+/g, '')}--wo`]);
  const clientKey = getDeviceIdKey(client);

  const cookie = cookies[`${client.replace(/\s+/g, '')}--wo`];

  useEffect(() => {
    if (!navigator.cookieEnabled || localStorage === undefined) {
      return;
    }

    const lsValue = localStorage.getItem(clientKey);

    if (cookie && isCookieValid(cookie)) {
      if (lsValue) {
        setDeviceId(lsValue);
      }

      if (!lsValue) {
        const generatedUuid = uuidv4();

        setDeviceId(() => {
          localStorage.setItem(clientKey, generatedUuid);
          return generatedUuid;
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookie]);

  return (
    <DeviceIdentifierContext.Provider
      value={{
        initialised: true,
        deviceIdentifier: deviceId,
      }}
    >
      {children}
    </DeviceIdentifierContext.Provider>
  );
};
