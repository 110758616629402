import { useState, useEffect, createContext, useContext } from 'react';

import {
  Breakpoints,
  MediaQueryDefinition,
  MediaQueryLists,
  MediaQueryMatches,
} from 'types/Breakpoints';

interface BreakpointContext {
  breakpoints: Breakpoints;
  initialised: boolean;
}

const BreakpointContext = createContext<BreakpointContext>({
  initialised: false,
  breakpoints: {
    xs: false,
    tiny: false,
    sm: false,
    md: false,
    lg: false,
    landscape: false,
  },
});

export const useBreakpoint = (): BreakpointContext => {
  const consumer = useContext(BreakpointContext);
  if (!consumer.initialised) {
    throw new Error('BreakpointContextProvider not initialised');
  }
  return consumer;
};

interface BreakpointContextProviderProps {
  children: React.ReactNode;
}

export const BreakpointContextProvider: React.FC<
  BreakpointContextProviderProps
> = ({ children }) => {
  const [matchedBreakpoints, setMatchedBreakpoints] = useState<Breakpoints>({
    xs: false,
    tiny: false,
    sm: false,
    md: false,
    lg: false,
    landscape: false,
  });

  useEffect(() => {
    // Define these here and we no longer have to find random screensizes in components!
    const queries: MediaQueryDefinition = {
      xs: '(max-width: 480px)',
      tiny: '(min-width: 480.02px) and (max-width: 766.98px)', // what is this?
      sm: '(min-width: 767px) and (max-width: 991.98px)',
      md: '(min-width: 992px) and (max-width: 1199.98px)',
      lg: '(max-width: 1200px)',
      landscape: '(max-height: 580px)',
    };

    //For future ref default bootstrap breakpoints
    // Why don't ours match this?!
    // X-Small	None	<576px
    // Small	sm	≥576px
    // Medium	md	≥768px
    // Large	lg	≥992px
    // Extra large	xl	≥1200px
    // Extra extra large	xxl	≥1400px

    const mediaQueryLists: MediaQueryLists = {};
    const keys = Object.keys(queries);
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce(
        (acc: MediaQueryMatches, media: string) => {
          acc[media] = mediaQueryLists[media] && mediaQueryLists[media].matches;
          return acc;
        },
        {},
      );

      const updatedBreakpoints: Breakpoints = {
        xs: updatedMatches['xs'],
        tiny: updatedMatches['tiny'],
        sm: updatedMatches['sm'],
        md: updatedMatches['md'],
        lg: updatedMatches['lg'],
        landscape: updatedMatches['landscape'],
      };

      setMatchedBreakpoints(updatedBreakpoints);
    };

    if (window && window.matchMedia) {
      const matches: MediaQueryMatches = {};

      keys.forEach((media) => {
        mediaQueryLists[media] = window.matchMedia(queries[media]);
        matches[media] = mediaQueryLists[media].matches;
      });

      const initBreakpoints: Breakpoints = {
        xs: matches['xs'],
        tiny: matches['tiny'],
        sm: matches['sm'],
        md: matches['md'],
        lg: matches['lg'],
        landscape: matches['landscape'],
      };

      setMatchedBreakpoints(initBreakpoints);

      isAttached = true;
      keys.forEach((media) => {
        mediaQueryLists[media].addListener(handleQueryListener);
      });
    }

    return () => {
      if (isAttached) {
        keys.forEach((media) => {
          mediaQueryLists[media].removeListener(handleQueryListener);
        });
      }
    };
  }, []);

  return (
    <BreakpointContext.Provider
      value={{ initialised: true, breakpoints: matchedBreakpoints }}
    >
      {children}
    </BreakpointContext.Provider>
  );
};
