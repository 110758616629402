import { useConfig, useSetConfig } from 'contexts/ConfigContext';

import { ControlLabel } from 'react-bootstrap';
import { ToggleSwitch } from 'core/components/ToggleSwitch';

export const LoyaltyCanAssociateToggle: React.FC = () => {
  const {
    loyalty: { canAssociateLoyaltyCard },
  } = useConfig();
  const setConfig = useSetConfig();

  const handleClick = (toggledValue: boolean) => {
    setConfig('loyalty.canAssociateLoyaltyCard', toggledValue);
  };

  return (
    <div>
      <ControlLabel className="ff-label">
        Can associate loyalty card
      </ControlLabel>
      <ToggleSwitch
        id="loyalty-can-associate"
        onChange={handleClick}
        checked={canAssociateLoyaltyCard}
        disabled={false}
        name="loyalty-can-associate"
        onLabel="on"
        offLabel="off"
        small={false}
      />
    </div>
  );
};
