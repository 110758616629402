import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { usePhrases } from 'contexts/ConfigContext';
import { usePayMyBill } from 'contexts/PayMyBillContext/PayMyBillContext';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BillMode, FormattedBill } from 'types/models/PayMyBill';

interface BillPaymentButtonsProps {
  bill: FormattedBill;
  handleChangeDisplay: (mode: BillMode) => void;
  handlePaymentAmount: (amount: number) => void;
}

export const BillPaymentButtons: React.FC<BillPaymentButtonsProps> = ({
  bill,
  handleChangeDisplay,
  handlePaymentAmount,
}) => {
  const { payMyBillBalanceText } = usePhrases();
  const { braintreeLoading } = usePayMyBill();

  const handlePartPayment = (mode: BillMode) => {
    window.scrollTo(0, 0);
    handleChangeDisplay(mode);
  };

  return bill.outstandingBalanceToPay > 0 ? (
    <div className="bill-payment-btns gap">
      <button
        className="btn btn-primary checkout-fw-button"
        data-testid="button-outstanding-balance-payment"
        onClick={() => handlePaymentAmount(bill.outstandingBalanceToPay)}
        disabled={braintreeLoading}
      >
        {`Pay ${payMyBillBalanceText} (`}
        <FormattedCurrency value={bill.outstandingBalanceToPay} />
        {`)`}
      </button>
      <button
        className="btn btn-primary checkout-fw-button"
        data-testid="button-even-split-payment"
        onClick={() => handlePartPayment(BillMode.showSplit)}
        disabled={braintreeLoading}
      >
        Pay an even split
      </button>
      <button
        className="btn btn-primary checkout-fw-button"
        data-testid="button-even-custom-payment"
        onClick={() => handlePartPayment(BillMode.showCustom)}
        disabled={braintreeLoading}
      >
        Pay a custom amount
      </button>
    </div>
  ) : (
    <div className="bill-payment-btns">
      <Alert
        bsStyle="success"
        className="text-center"
        data-testid="alert-success-notification"
      >
        This account has been settled
      </Alert>
      <Link className="btn btn-primary" to="/venues">
        Back to homepage
      </Link>
    </div>
  );
};
