import { ServiceType } from 'types/models';
import { TableChanger, TimeslotChanger } from 'core/components';
import { useServices } from 'contexts/VenueContext';

export const ServiceChanger: React.FC = () => {
  const { selectedService } = useServices();

  return (
    <div
      className="service-changer"
      data-testid={
        ServiceType.ClickAndCollect || ServiceType.DeliveryToLocation
          ? 'click-collect-service-changer'
          : 'order-pay-service-changer'
      }
    >
      {selectedService === ServiceType.ClickAndCollect ||
      selectedService === ServiceType.DeliveryToLocation ? (
        <TimeslotChanger />
      ) : null}
      {selectedService === ServiceType.OrderAndPay ? <TableChanger /> : null}
    </div>
  );
};
