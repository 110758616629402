import { useServices, useVenues } from 'contexts/VenueContext';
import { MenuBackButton, MenuNavButton } from 'menu/components/MenuNav';
import { useHistory } from 'react-router';
import { Menu, MenuDisplayState, MenuGroup } from 'types/models';
import { getUrlPart } from 'utils';
import cs from 'classnames';
import { useRef } from 'react';
import { CanOrderBanner } from 'menu/components/Alerts';

interface MenuGroupNavProps {
  activeMG: MenuDisplayState;
  filteredMenuGroups: MenuGroup[];
  jumpToElement: (id: string) => void;
  selectedMenu: Menu;
}

export const MenuGroupNav: React.FC<MenuGroupNavProps> = ({
  activeMG,
  filteredMenuGroups,
  jumpToElement,
  selectedMenu,
}) => {
  const history = useHistory();
  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();

  const menuNavRef = useRef<HTMLDivElement>(null);

  const backButtonClick = () => {
    history.push(
      `/venue/${selectedVenue?.id}/${getUrlPart(selectedService)}/menus/`,
    );
    window.scrollTo(0, 0); // Navigate to top of page after customer hits button
  };

  return (
    <div className="menu-nav" ref={menuNavRef}>
      <div className="menu-nav-header">
        <MenuBackButton onClick={() => backButtonClick()} />
        <h4>{selectedMenu.name}</h4>
      </div>
      <div
        id="horizontal-menu"
        className="display-group-list menu-groups list-group"
        data-testid="menu-group-list"
      >
        {filteredMenuGroups.map((mg) => {
          const navItemClasses = cs(
            activeMG && mg.groupId === activeMG.menuId && 'active',
            'list-group-item',
          );
          return (
            <MenuNavButton
              key={`mg-item-${mg.groupId}`}
              buttonId={`mg-item-${mg.groupId}`}
              buttonClass={navItemClasses}
              handleClick={() => {
                jumpToElement(`WP-${mg.groupId}`);
              }}
              testId={`menu-group-item-${mg.groupId}`}
              buttonText={mg.groupName}
            />
          );
        })}
      </div>
      <CanOrderBanner menu={selectedMenu} shouldCalculateWidth/>
    </div>
  );
};
