import { usePhrases } from 'contexts/ConfigContext';
import { useTables } from 'contexts/TableContext';
import { useServices } from 'contexts/VenueContext';
import { useParams } from 'react-router';
import { CombinedVenue, ServiceType } from 'types/models';
import {
  findSalesAreaById,
  findTableByNumber,
  getServiceLabel,
} from 'venue/utils';

interface ParamsProps {
  salesAreaId: string;
  tableNumber: string;
}

interface AtTableErrorProps {
  selectedVenue: CombinedVenue;
}

export const AtTableError: React.FC<AtTableErrorProps> = ({
  selectedVenue,
}) => {
  const params = useParams<ParamsProps>();
  const { salesAreaPhrase, table: tablePhrase } = usePhrases();
  const { services } = useServices();
  const { hasGroups, tables } = useTables();

  const salesAreaId = parseInt(params.salesAreaId, 10);
  const tableNumber = parseInt(params.tableNumber, 10);

  const hasMultipleSalesAreas = selectedVenue.salesArea.length > 1;
  const orderAndPayLabel =
    getServiceLabel(services, ServiceType.OrderAndPay) || 'Order & Pay';
  const payMyBillLabel =
    getServiceLabel(services, ServiceType.PayMyBill) || 'Pay My Bill';

  const foundSalesArea = findSalesAreaById(selectedVenue, salesAreaId);
  const foundTable = findTableByNumber(tables, tableNumber, hasGroups);

  const getErrorMessage = (): string => {
    // Invalid Sales Area
    if (!foundSalesArea) {
      return `The ${salesAreaPhrase} selected has not been recognised for ${selectedVenue.name}, in ${selectedVenue.address.town}.`;
    }

    // Invalid Table
    if (!foundTable) {
      return `The ${tablePhrase} has not been recognised for ${
        selectedVenue.name
      }${hasMultipleSalesAreas ? ` (${foundSalesArea.name})` : ''}, in ${
        selectedVenue.address.town
      }.`;
    }

    // Generic error message
    return `${orderAndPayLabel} and ${payMyBillLabel} are currently unavailable at ${
      selectedVenue.name
    }${hasMultipleSalesAreas ? ` (${foundSalesArea.name})` : ''}, in ${
      selectedVenue.address.town
    }.`;
  };

  return (
    <div
      className="panel panel-default"
      data-testid="error-panel-unavailble-services"
    >
      {getErrorMessage()}
    </div>
  );
};
