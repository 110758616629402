import { useAPI } from 'api/useAPI';
import { requestBodyFormatter } from 'api/utils';
import { AxiosRequestConfig } from 'axios';
import { useGlobalUser } from 'contexts/UserContext/GlobalUserContext';
import { addNotification } from 'core/actions';
import { useAxiosInterceptor } from 'hooks/useAxiosInstance';
import { createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ErrorResponse } from 'types/models';
import { AccountDeletionResponse } from 'types/models/Responses/AccountDeletionResponse';

interface AccountDeletionContext {
  initialised: boolean;
  accountDeleted: boolean;
  confirmAccountDeletion: (otp: string) => void;
  deleteAccountRequest: () => void;
  resetDeletionStatus: () => void;
}

export const AccountDeletionContext = createContext<AccountDeletionContext>({
  initialised: false,
  accountDeleted: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  confirmAccountDeletion: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  deleteAccountRequest: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetDeletionStatus: () => {},
});

export const useAccountDeletion = (): AccountDeletionContext => {
  const consumer = useContext(AccountDeletionContext);
  if (!consumer.initialised) {
    throw new Error('AccountDeletionContext Provider not initialised');
  }
  return consumer;
};

interface AccountDeletionContextProviderProps {
  children: React.ReactNode;
}

export const AccountDeletionProvider: React.FC<
  AccountDeletionContextProviderProps
> = ({ children }) => {
  const dispatch = useDispatch();
  const { url, deleteAccount, deleteAccountWithOtp } = useAPI();
  const { otpDetails, resetOTP, setOTP, user } = useGlobalUser();
  const axios = useAxiosInterceptor();

  const [accountDeleted, setAccountDeleted] = useState<boolean>(false);

  const resetDeletionStatus = () => {
    setAccountDeleted(false);
  };

  const deleteAccountRequest = () => {
    const deleteAccountConfig = deleteAccount();
    const requestOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      data: requestBodyFormatter({
        method: deleteAccountConfig.method,
        ...deleteAccountConfig.body,
      }),
    };

    axios(requestOptions)
      .then((response) => {
        const data = response.data as AccountDeletionResponse;
        if (data.challenge) {
          setOTP({ ...data.challenge, userEmail: user?.email });
        } else {
          const data = response.data as ErrorResponse;
          dispatch(addNotification(data.detail, 'danger', data.code));
        }
      })
      .catch(() => {
        dispatch(
          addNotification('There was an error. Please try again.', 'danger'),
        );
      });
  };

  const confirmAccountDeletion = (otp: string) => {
    const deleteAccountWithOtpConfig = deleteAccountWithOtp();
    const deleteAccountConfirm: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      data: requestBodyFormatter({
        method: deleteAccountWithOtpConfig.method,
        ...deleteAccountWithOtpConfig.body,
        user: {
          email: otpDetails?.userEmail,
        },
        challengeID: otpDetails?.id,
        OTP: otp,
      }),
    };

    axios(deleteAccountConfirm)
      .then((response) => {
        if (response.data.response === 'OK') {
          setAccountDeleted(true);
          resetOTP();
        } else {
          const data = response.data as ErrorResponse;
          dispatch(addNotification(data.detail, 'danger', data.code));
        }
      })
      .catch(() => {
        dispatch(
          addNotification('There was an error. Please try again.', 'danger'),
        );
      });
  };

  return (
    <AccountDeletionContext.Provider
      value={{
        initialised: true,
        accountDeleted,
        confirmAccountDeletion,
        deleteAccountRequest,
        resetDeletionStatus,
      }}
    >
      {children}
    </AccountDeletionContext.Provider>
  );
};
