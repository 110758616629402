import { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { NotFound } from 'routers';
import {
  AuthenticatedOrderingRoute,
  AuthenticatedRoute,
} from 'routers/PrivateRoute';
import { useDispatch } from 'react-redux';
import { removeNotification } from 'core/actions';
import { AccountDeletion, Edit } from 'user/components/LoggedInUserComponents';
import { PasswordChange } from 'user/components/PasswordChange';
import { LoyaltyRegisterPage } from 'user/components/Loyalty/AddLoyalty';
import { UserLoyaltyPage } from 'user/components/Loyalty/UserLoyalty';
import { useConfig } from 'contexts/ConfigContext';
import { UnlinkLoyaltyPage } from 'user/components/Loyalty';
import { AccountDeletionProvider } from 'contexts/UserContext';
import { OrderHistoryRouter } from 'routers/OrderHistory/OrderHistoryRouter';

export const LoggedInUserRouter: React.FC = () => {
  const dispatch = useDispatch();
  const { env } = useConfig();

  useEffect(() => {
    dispatch(removeNotification());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AccountDeletionProvider>
      <Switch>
        <Route path="/user/order" component={OrderHistoryRouter} />
        <AuthenticatedRoute
          path="/user/delete-account"
          component={AccountDeletion}
        />
        <AuthenticatedOrderingRoute path="/user/edit" component={Edit} />
        <AuthenticatedOrderingRoute
          path="/user/password-change"
          component={PasswordChange}
        />
        <AuthenticatedOrderingRoute
          path="/user/loyalty/register"
          component={LoyaltyRegisterPage}
        />

        {env === 'qa1' || env === 'qa2' || env === 'development' ? (
          <AuthenticatedOrderingRoute
            path="/user/loyalty/unlink"
            component={UnlinkLoyaltyPage}
          />
        ) : null}
        <AuthenticatedOrderingRoute
          path="/user/loyalty"
          component={UserLoyaltyPage}
        />
        <Redirect exact from="/user" to="/user/order" />
        <Route component={NotFound} />
      </Switch>
    </AccountDeletionProvider>
  );
};
