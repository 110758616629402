import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { useGlobalUser } from 'contexts/UserContext';
import { Loyalty } from 'user/components/Loyalty/AddLoyalty';
import {
  UserHasLoyalty,
  UserHasNoLoyalty,
} from 'user/components/Loyalty/UserLoyalty';
import { getLoyaltyLinkTitle } from 'utils';

export const UserLoyaltyBody: React.FC = () => {
  const {
    loyalty: { canAssociateLoyaltyCard },
  } = useConfig();

  const {
    loyalty: { loyaltyProgramPhrase, cardPhrase },
  } = usePhrases();

  const { user } = useGlobalUser();

  const loyaltyCard = user.loyalty_card;

  if (loyaltyCard) {
    return <UserHasLoyalty />;
  } else if (!canAssociateLoyaltyCard) {
    return <UserHasNoLoyalty />;
  }
  return (
    <>
      <h2>{getLoyaltyLinkTitle(loyaltyProgramPhrase, cardPhrase)}</h2>
      <Loyalty isOnUserScreen={true} />
    </>
  );
};
