import { useEffect, useState } from 'react';
import { SelectedServiceChoiceModal } from 'venue/components/SelectedServiceChoiceModal';
import { useConfig } from 'contexts/ConfigContext';
import { LocationSearchButton } from 'venue/components/Search';
import { useVenues } from 'contexts/VenueContext';
import { useVenueSearch } from 'hooks/useVenueSearch';
import { VenueList, VenueMapModal } from './components';
import { SearchBar } from 'menu/components/SearchBar';
import { MapViewButton } from './components/MapComponents';
import { useDispatch } from 'react-redux';
import { addNotification } from 'core/actions';
import axios from 'axios';
import { GoogleMapsApiResponse } from 'types/models';

export const VenuePage: React.FC = () => {
  const { disableSearch, homepage, searchText, googleMapsApiKey } = useConfig();
  const { fetchVenues, fetchVenueSummary } = useVenues();
  const { searchTerm, setSearchTerm, filteredVenueList } = useVenueSearch();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchVenues();
    fetchVenueSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showMap, setShowMap] = useState(false);
  const [googleMapsApiError, setGoogleMapsApiError] = useState(false);

  const openMapView = async () => {
    const showMapsApiError = () => {
      dispatch(
        addNotification(
          'Something went wrong when opening map view.',
          'danger',
        ),
      );
    };

    if (!googleMapsApiError) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?&key=${googleMapsApiKey}`,
          {
            params: {
              address: ' ',
            },
          },
        )
        .then((response) => {
          const data = response.data as GoogleMapsApiResponse;
          if (data.status === 'OK' || data.status === 'ZERO_RESULTS') {
            setShowMap(true);
          } else if (data.status === 'REQUEST_DENIED') {
            dispatch(addNotification(data.error_message, 'danger'));
            setGoogleMapsApiError(true);
          }
        })
        .catch(() => {
          showMapsApiError();
        });
    } else {
      showMapsApiError();
    }
  };

  return (
    <>
      {!disableSearch ? (
        <>
          <div className="venue-search-container">
            <div className="container">
              <div className="venue-search-bar">
                <SearchBar
                  searchTerm={searchTerm}
                  changeSearchTerm={setSearchTerm}
                  clearSearchTerm={() => setSearchTerm('')}
                  placeHolder={searchText}
                />
                <LocationSearchButton />
                <MapViewButton onClick={() => openMapView()} />
              </div>
            </div>
          </div>
          <div className="container">
            {showMap ? (
              <VenueMapModal
                isOpen={true}
                handleClose={() => setShowMap(false)}
              />
            ) : null}
            <VenueList venues={filteredVenueList} />
            <SelectedServiceChoiceModal resetVenueOnClose={true} />
          </div>
        </>
      ) : (
        <div className="container container-sm text-center">
          <div
            className="panel panel-default"
            data-testid="header-unexpected-error-text"
          >
            <h3>Something unexpected happened.</h3>
            <a
              href={homepage}
              className="btn btn-primary"
              data-testid="btn-back-to-homepage"
            >
              Back to homepage
            </a>
          </div>
          <SelectedServiceChoiceModal resetVenueOnClose={true} />
        </div>
      )}
    </>
  );
};
