import { Button } from 'react-bootstrap';
import { MdDelete } from 'react-icons/md';
import { useConfig } from 'contexts/ConfigContext';
import { useBasket } from 'contexts/BasketContext';
import { useVouchers } from 'contexts/VoucherContext';

interface ClearBasketButtonProps {
  handleClearBasket: () => void;
}

export const ClearBasketButton: React.FC<ClearBasketButtonProps> = ({
  handleClearBasket,
}) => {
  const { stickyBasketEnabled } = useConfig();
  const { basket } = useBasket();
  const { redeemedVouchers, voidAllVouchersLoading } = useVouchers();


  return (
    <Button
      title="Clear Basket"
      className="clear-basket-button btn-with-icon"
      bsStyle="danger"
      onClick={() => handleClearBasket()}
      disabled={
        basket.length + redeemedVouchers.length === 0 || voidAllVouchersLoading
      }
      data-testid="sticky-basket-clear-basket-button"
    >
      <MdDelete /> {stickyBasketEnabled ? 'Clear Basket' : null}
    </Button>
  );
};
