import { useCheckout } from 'contexts/CheckoutContext';
import { useConfig } from 'contexts/ConfigContext';
import { useGlobalUser } from 'contexts/UserContext';
import { Redirect } from 'react-router';
import { Loyalty } from 'user/components/Loyalty/AddLoyalty/Loyalty';

export const LoyaltyRegisterPage: React.FC = () => {
  const {
    loyalty: { shouldPromptForAssociationPostSignUp },
  } = useConfig();
  const { user } = useGlobalUser();
  const { isFetchingBasket } = useCheckout();
  const hasLoyaltyCard = Boolean(user.loyalty_card);

  if (
    !shouldPromptForAssociationPostSignUp ||
    (hasLoyaltyCard && !isFetchingBasket)
  ) {
    return <Redirect to="/venues" />;
  }

  return (
    <div className="container container-md">
      <div
        className="panel panel-default add-loyalty"
        data-testid="panel-loyalty"
      >
        <Loyalty />
      </div>
    </div>
  );
};
