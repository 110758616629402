import { useVenues } from 'contexts/VenueContext';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { VenueInfoLayout } from 'layouts';
import { useEffectSkipFirst } from 'hooks/useEffectSkipFirst';
import { Loader } from 'components/Loader';
import { AtTableHomePageBody } from 'at-table/AtTableHomePageBody';
import { usePhrases } from 'contexts/ConfigContext';

interface ParamsProps {
  venueId: string;
}

export const AtTableHomePage: React.FC = () => {
  const { venueTitle } = usePhrases();
  const params = useParams<ParamsProps>();
  const {
    venues,
    isLoadingVenues,
    selectedVenue,
    setSelectedVenue,
    fetchVenues,
    fetchVenueSummary,
    fetchVenueHome,
  } = useVenues();

  const venueId = parseInt(params.venueId, 10);
  const [isCheckingVenue, setIsCheckingVenue] = useState(true);
  const isLoading = isCheckingVenue || isLoadingVenues;

  const findVenue = (linkedVenueId: number) => {
    return venues.find((x: { id: number }) => x.id === linkedVenueId);
  };

  const checkVenue = (venueId: number) => {
    const foundVenue = findVenue(venueId);

    if (foundVenue) {
      setSelectedVenue(foundVenue);
    }
    setIsCheckingVenue(false);
  };

  useEffect(() => {
    setSelectedVenue(undefined);
    fetchVenues(venueId);
    fetchVenueSummary(undefined, venueId);
    fetchVenueHome(venueId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffectSkipFirst(() => {
    if (!isLoadingVenues) {
      checkVenue(venueId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingVenues]);


  if (isLoading) {
    return (
      <div className="container">
        <div className="panel panel-default">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <>
      {selectedVenue ? (
        <VenueInfoLayout selectedVenue={selectedVenue} classNamePrefix="sit-in">
          <AtTableHomePageBody selectedVenue={selectedVenue} />
        </VenueInfoLayout>
      ) : (
        <div className="container container-md">
          <div className="panel panel-default">
            The {venueTitle} selected has not been recognised.
          </div>
        </div>
      )}
    </>
  );
};
