import { Dialog } from 'components/Dialog';
import { usePhrases } from 'contexts/ConfigContext';
import { useGlobalUser } from 'contexts/UserContext';

interface ConfirmAccountDeletionDialogProps {
  isOpen: boolean;
  confirmAction: () => void;
  rejectAction: () => void;
}

export const ConfirmAccountDeletionDialog: React.FC<
  ConfirmAccountDeletionDialogProps
> = ({ isOpen, confirmAction, rejectAction }) => {
  const {
    deleteAccount: {
      prompt: { supportingText },
    },
  } = usePhrases();

  const { user } = useGlobalUser();

  const title = user?.firstName
    ? `${user.firstName}, are you sure you want to delete your account?`
    : `Are you sure you want to delete your account?`;

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      description={supportingText}
      accept={{
        action: confirmAction,
        text: 'Yes, I want to delete my account',
        btnType: 'destructive',
        datatestid: 'button-delete-confirmation',
      }}
      reject={{
        action: rejectAction,
        text: 'No, I want to keep my account',
        datatestid: 'button-delete-rejection',
      }}
    />
  );
};
