import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useConfig } from 'contexts/ConfigContext';
import { useGlobalUser } from 'contexts/UserContext';

export const AuthenticatedOrderingRoute: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { isLoggedIn } = useGlobalUser();

  const { accountDeletionOnly } = useConfig();

  const ComponentElement = Component as React.ElementType;

  return (
    <Route
      {...rest}
      render={(props): React.ReactNode => {
        if (isLoggedIn && !accountDeletionOnly) {
          return <ComponentElement {...props} />;
        }

        if (isLoggedIn && accountDeletionOnly) {
          return (
            <Redirect
              to={{
                pathname: '/user/delete-account',
                state: { from: props.location },
              }}
            />
          );
        }

        return (
          <Redirect
            to={{
              pathname: '/user/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
