import { OrderHistoryContextProvider } from 'contexts/OrderHistoryContext';
import { Switch } from 'react-router-dom';
import { Order } from 'order-history/components/Order';
import { OrderHistoryPage } from 'order-history/OrderHistoryPage';
import { AuthenticatedOrderingRoute } from 'routers/PrivateRoute';

export const OrderHistoryRouter: React.FC = () => {
  return (
    <Switch>
      <OrderHistoryContextProvider>
        <AuthenticatedOrderingRoute
          exact
          path="/user/order"
          component={OrderHistoryPage}
        />
        <AuthenticatedOrderingRoute path="*/:orderId" component={Order} />
      </OrderHistoryContextProvider>
    </Switch>
  );
};
