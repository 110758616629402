import { Link, useLocation } from 'react-router-dom';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { useSelector } from 'react-redux';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { selectOtpEnabled } from 'user/selectors';
import { useGlobalUser } from 'contexts/UserContext';
import { getLoyaltyLinkTitle } from 'utils';
import cs from 'classnames';
import { LoggedInUserButton } from './LoggedInUserButton';
import { Button } from 'react-bootstrap';
import { FeatureFlagLink } from 'core/components/Header/FeatureFlagLink';
interface MenuItemProps {
  to?: string;
  children?: ReactNode;
  onClick?: () => void;
  divider?: boolean;
}

export const LoggedInHeader: React.FC = () => {
  const loggedInHeaderRef = useRef<HTMLDivElement | null>(null);
  const { pathname } = useLocation();

  const {
    accountDeletionOnly,
    disableUserSignIn,
    loyalty: { userShowLoyaltyCard },
    supportsRewardsRedemption,
  } = useConfig();

  const {
    loyalty: { loyaltyProgramPhrase, cardPhrase },
  } = usePhrases();

  const { logOut } = useGlobalUser();
  const otpEnabled = useSelector(selectOtpEnabled);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        loggedInHeaderRef.current &&
        !loggedInHeaderRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (accountDeletionOnly || disableUserSignIn) {
    return null;
  }

  const MenuItem: React.FC<MenuItemProps> = ({ to, children, onClick }) => {
    return (
      <Link
        onClick={(e) => {
          if (onClick) {
            e.preventDefault();
            onClick();
          }
          setIsOpen(false);
        }}
        to={to ?? '/#'}
        className={cs(
          'logged-in-option',
          pathname.startsWith(to ?? '') ? 'active' : '',
        )}
      >
        <div className={'logged-in-option-label'}>{children}</div>
      </Link>
    );
  };

  return (
    <>
      <div className="user-header-menu user-account">
        <div id="user-header-boundary" ref={loggedInHeaderRef}>
          <LoggedInUserButton
            isMenuOpen={isOpen}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
          <div id="user-logged-in-nav" className={cs('', !isOpen && 'closed')}>
            {userShowLoyaltyCard && supportsRewardsRedemption ? (
              <MenuItem to="/user/loyalty" data-testid="header-loyalty-link">
                {getLoyaltyLinkTitle(loyaltyProgramPhrase, cardPhrase)}
              </MenuItem>
            ) : null}
            <MenuItem to="/user/order" data-testid="header-order-history-link">
              Order history
            </MenuItem>
            <MenuItem to="/user/edit" data-testid="header-my-details-link">
              My details
            </MenuItem>
            <MenuItem
              to={'/user/password-change'}
              data-testid="header-change-password-link"
            >
              Change password
            </MenuItem>
            {otpEnabled ? (
              <MenuItem
                to="/user/delete-account"
                data-testid="header-delete-account-link"
              >
                Delete My Account
              </MenuItem>
            ) : null}
            <MenuItem data-testid="header-log-out-link">
              <Button
                onClick={() => logOut()}
                className="btn btn-danger btn-sm"
              >
                Log out
              </Button>
            </MenuItem>
          </div>
        </div>
      </div>
      <FeatureFlagLink />
    </>
  );
};
