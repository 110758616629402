import { useEffect } from 'react';

import cs from 'classnames';
import { useFormContext, RegisterOptions } from 'react-hook-form';
import { AdditionalRules, Field } from 'types/models/Forms';
import {
  isNotOnlyWhitespace,
  isRequired,
} from 'core/components/RHF/validation';

interface TextProps {
  field: Field;
  autocomplete?: string;
  additionalRules?: AdditionalRules;
}

export const Checkbox: React.FC<TextProps> = ({
  field,
  autocomplete,
  additionalRules,
}) => {
  const {
    formState: { errors },
    register,
    setValue,
  } = useFormContext();
  const { required, label, placeholder, name, disabled } = field;

  const labelToUse = label ? label : name;

  const hasError = errors[name] !== undefined;
  const fieldError = errors[name];

  const requiredLabel = required ? <span className="required">*</span> : null;

  const formGroupClasses = cs('form-group', hasError && 'has-error');

  const registerOptions: RegisterOptions = {
    ...isRequired('This field', required),
    validate: {
      ...(required && {
        whiteSpace: (value) => isNotOnlyWhitespace(value, labelToUse),
      }),
      ...additionalRules,
    },
  };

  useEffect(() => {
    if (field.content) {
      setValue(field.name, field.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={formGroupClasses}>
      <div className="checkbox">
        <label className="" htmlFor={name}>
          <input
            type="checkbox"
            placeholder={placeholder ? placeholder : labelToUse}
            aria-required={required ? 'true' : 'false'}
            aria-invalid={hasError ? 'true' : 'false'}
            {...register(name, registerOptions)}
            autoComplete={autocomplete}
            disabled={disabled}
            id={name}
          />
          {label}
          {requiredLabel}
        </label>
      </div>
      {fieldError && (
        <span className="help-block no-margin-bottom" role="alert">
          {fieldError.message}
        </span>
      )}
    </div>
  );
};
