import { UserLoyaltyBody } from 'user/components/Loyalty/UserLoyalty/UserLoyaltyBody';

export const UserLoyaltyPage: React.FC = () => {
  return (
    <div className="container">
      <div
        className="panel panel-default add-loyalty"
        data-testid="panel-loyalty"
      >
        <UserLoyaltyBody />
      </div>
    </div>
  );
};
