import { useState, useEffect } from 'react';

import { AxiosRequestConfig } from 'axios';
import { useAPI } from 'api/useAPI';
import { fetchBodyFormatter } from 'utils';
import { addNotification } from 'core/actions';
import { useDispatch } from 'react-redux';
import { GetSignUpConfigResponse } from 'types/models/Responses/GetSignUpConfigResponse';
import { Field, FormsConfig } from 'types/models/Forms';
import { isWebOrderingConfig } from 'types/guards';
import { useAxiosInterceptor } from 'hooks/useAxiosInstance';

interface SignUpProps {
  formData: FormsConfig | undefined;
  isLoadingForms: boolean;
}

export const useSignUp = (): SignUpProps => {
  const dispatch = useDispatch();
  const axios = useAxiosInterceptor();
  const { url, getSignUp: getSignUpAPI } = useAPI();
  const getSignUpConfig = getSignUpAPI();

  const [formData, setFormData] = useState<FormsConfig>();
  const [isLoadingForms, setIsLoadingForms] = useState(false);

  const formatFormResponse = (
    response: GetSignUpConfigResponse,
  ): FormsConfig => {
    const expectedForms: (keyof GetSignUpConfigResponse)[] = [
      'register',
      'edit',
      'editUser',
      'linkLoyaltyAccount',
    ];

    expectedForms.forEach((form) => {
      const currentForm = response?.[form];
      if (currentForm && isWebOrderingConfig(currentForm[0])) {
        const flattenedFields: Field[] = [];
        currentForm.forEach((row) => {
          if (isWebOrderingConfig(row)) flattenedFields.push(...row.fields);
        });
        response[form] = flattenedFields;
      }
    });

    return response as FormsConfig;
  };

  const signUpOptions: AxiosRequestConfig = {
    url,
    method: 'POST',
    data: fetchBodyFormatter({
      method: getSignUpConfig.method,
      ...getSignUpConfig.body,
    }),
  };

  useEffect(() => {
    if (!isLoadingForms) {
      setIsLoadingForms(true);
      axios(signUpOptions)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data as GetSignUpConfigResponse;
            setFormData(formatFormResponse(data));
          } else {
            dispatch(
              addNotification(
                'Unfortunately something went wrong, please try again.',
                'danger',
              ),
            );
          }
        })
        .catch(() => {
          dispatch(
            addNotification(
              'Unfortunately something went wrong, please try again.',
              'danger',
            ),
          );
        })
        .finally(() => {
          setIsLoadingForms(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { formData, isLoadingForms };
};
