import { Button, Col, FormGroup, Grid, Panel, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Email } from 'core/components/RHF/FieldTemplates';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { Field } from 'types/models/Forms';
import { useUserSettings } from 'contexts/UserContext';

interface FormValues {
  email: string;
}

export const PasswordRequest: React.FC = () => {
  const { resetPassword, isFetchingPassword } = useUserSettings();
  const methods = useForm<FormValues>();

  const handleResetPassword: SubmitHandler<FormValues> = (formData) => {
    resetPassword(formData.email);
  };

  const emailField: Field = {
    label: 'Email Address',
    max: 64,
    min: 5,
    name: 'email',
    placeholder: 'Email Address',
    required: true,
    type: 'email',
  };

  return (
    <Grid>
      <p className="text-right">
        <Link to="/user/login">Already have an account? Log in here</Link>
      </p>
      <Panel>
        <h2>Request a new password</h2>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleResetPassword)}>
            <Row>
              <Col sm={12}>
                <Email field={emailField} />
              </Col>
            </Row>
            <FormGroup>
              <Button
                bsStyle="primary"
                type="submit"
                onClick={methods.handleSubmit(handleResetPassword)}
                disabled={isFetchingPassword}
              >
                Send
              </Button>
            </FormGroup>
          </form>
        </FormProvider>
      </Panel>
    </Grid>
  );
};
