import { useState, useContext, useEffect } from 'react';
import { ModalHeader, ModalBody } from 'components/Modal';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
  DeliveryToLocationModalFooter,
  DeliveryToLocationModalHeader,
  ServiceModalHeader,
  VenueModalFooter,
} from 'venue/components/SharedModalComponents';
import { hideServiceChoiceModal } from 'venue/actions';
import core from 'core';
import { ServiceModalContext } from 'contexts/ServiceModalContext';
import { getUrlPart } from 'utils';
import { DeliveryToLocationInformation } from 'venue/components/DeliveryToLocationModal';
import { useAdditionalInformation } from 'contexts/AdditionalInformationContext/AdditionalInformationContext';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { LocationInfoFormProps } from 'types/models/AdditionalInformation';
import { useServices, useVenues } from 'contexts/VenueContext';
import { useTables } from 'contexts/TableContext/TableContext';
import { useTimeslots } from 'contexts/TimeslotContext/TimeslotContext';
import { TimeslotSelector } from 'features/timeslot/components/TimeslotSelector';

interface TimeslotSelectorFormProps {
  timeslot: string;
}

export const SelectedServiceDeliveryToLocation: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    additionalInformationError,
    getAdditionalInfo,
    handleSaveLocationInformation,
    isFetchingAdditionalInfo,
    locationInformationFields,
    resetState,
  } = useAdditionalInformation();
  const { onClose } = useContext(ServiceModalContext);

  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();
  const { setSelectedTable } = useTables();
  const { setSelectedTimeslot, isFetchingTimeslots, timeslots } =
    useTimeslots();

  const timeslotMethods = useForm<TimeslotSelectorFormProps>();
  const locationMethods = useForm<LocationInfoFormProps>();

  const [hasLocationInfo, setHasLocationInfo] = useState<boolean>(false);
  const [showLocationInfo, setShowLocationInfo] = useState<boolean>(false);

  const handleTimeslotConfirm: SubmitHandler<TimeslotSelectorFormProps> = (
    formValues,
  ) => {
    dispatch(core.actions.removeNotification());
    const formTimeslot = formValues.timeslot;
    // TODO - Look to maybe add this in as form validation?
    if (formTimeslot && !isNaN(new Date(formTimeslot).getTime())) {
      setSelectedTimeslot({ time: formTimeslot, available: true });
      if (hasLocationInfo && !showLocationInfo) {
        setShowLocationInfo(true);
      } else {
        confirmToMenuPage();
      }
    }
  };

  const handleClose = () => {
    if (showLocationInfo) {
      setShowLocationInfo(false);
      locationMethods.reset();
    } else {
      resetState();
      onClose(true);
    }
  };

  const confirmToMenuPage = () => {
    dispatch(hideServiceChoiceModal());
    setSelectedTable(undefined);
    getAdditionalInfo();
    const urlPart = getUrlPart(selectedService);
    history.push(`/venue/${selectedVenue?.id}/${urlPart}/menus`);
  };

  const saveLocationInfo: SubmitHandler<LocationInfoFormProps> = (
    formValues,
  ) => {
    handleSaveLocationInformation(formValues);
    confirmToMenuPage();
  };

  const timeslotConfirmButtonText = () => {
    if (isFetchingTimeslots || isFetchingAdditionalInfo) {
      return 'Loading';
    } else {
      return hasLocationInfo ? 'Continue' : 'View Menu';
    }
  };

  useEffect(() => {
    resetState();
    getAdditionalInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (locationInformationFields.length > 0 || additionalInformationError) {
      setHasLocationInfo(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationInformationFields, additionalInformationError]);

  return !showLocationInfo ? (
    <FormProvider {...timeslotMethods}>
      <ModalHeader closeButton onRequestClose={handleClose}>
        <ServiceModalHeader useDistanceIfAvailable />
      </ModalHeader>
      <ModalBody>
        <TimeslotSelector />
      </ModalBody>
      <VenueModalFooter
        handleConfirm={timeslotMethods.handleSubmit(handleTimeslotConfirm)}
        disabled={
          isFetchingTimeslots ||
          timeslots.length === 0 ||
          isFetchingAdditionalInfo
        }
        confirmButtonText={timeslotConfirmButtonText()}
      />
    </FormProvider>
  ) : (
    <FormProvider {...locationMethods}>
      <ModalHeader closeButton onRequestClose={handleClose}>
        <DeliveryToLocationModalHeader />
      </ModalHeader>
      <ModalBody>
        <DeliveryToLocationInformation />
      </ModalBody>
      <DeliveryToLocationModalFooter
        handleConfirm={locationMethods.handleSubmit(saveLocationInfo)}
        handleClose={handleClose}
        disabled={!!additionalInformationError}
      />
    </FormProvider>
  );
};
