import { usePhrases } from 'contexts/ConfigContext';
import { Link } from 'react-router-dom';

interface OTPModalProps {
  fromPath?: string;
}

export const RegistrationButton: React.FC<OTPModalProps> = ({ fromPath }) => {
  const {
    login: { createAccountText },
  } = usePhrases();
  const postRegisterPath = fromPath ?? '/checkout';

  return (
    <Link
      to={{
        pathname: '/user/register',
        state: { from: { pathname: postRegisterPath } },
      }}
      className="btn btn-primary btn-block"
      data-testid="register-link-button"
    >
      {createAccountText}
    </Link>
  );
};
