import { createContext, useContext, useState } from 'react';

import { useAPI } from 'api/useAPI';
import { requestBodyFormatter } from 'api/utils';
import { AxiosRequestConfig } from 'axios';
import { useVenues } from 'contexts/VenueContext';
import { addNotification } from 'core/actions';
import { useDispatch } from 'react-redux';
import { getGiftCardPhrase, getMaskedCardNumber } from 'utils';
import {
  ErrorResponse,
  GetCreditGiftCardResponse,
  GetRedeemGiftCardResponse,
  GetVerifyGiftCardResponse,
} from 'types/models';
import { RedeemedGiftCard, VerifiedGiftCard } from 'types/models/GiftCard';
import { usePhrases } from 'contexts/ConfigContext';
import { useDeviceIdentifier } from 'contexts/DeviceIdentifierContext';
import { GiftCardLine } from 'types/models/Responses/CheckBasketResponse';
import { CheckBasketParams } from 'contexts/CheckoutContext';
import { useGlobalUser } from 'contexts/UserContext';
import { useAxiosInterceptor } from 'hooks/useAxiosInstance';

interface GiftCardContextProps {
  initialised: boolean;
  creditGiftCard: (
    giftCardId: string,
    transactionId: number,
    amount: number,
    checkBasketCallback?: CheckBasketParams,
  ) => void;
  debitGiftCard: (
    giftCardId: string,
    amount: number,
    closeModalCallback?: () => void,
    checkBasketCallback?: CheckBasketParams,
  ) => void;
  isCrediting: boolean;
  isDebiting: boolean;
  isVerifying: boolean;
  redeemedGiftCards: GiftCardLine[];
  removeAllGiftCards: () => void;
  resetVerifiedCard: () => void;
  showGiftCardOverchargeModal: boolean;
  setShowGiftCardOverchargeModal: (status: boolean) => void;
  setRedeemedGiftCards: (giftCards: GiftCardLine[]) => void;
  verifiedGiftCard: VerifiedGiftCard | undefined;
  verifyGiftCard: (cardNumber: string, pin: string) => void;
}

export const GiftCardContext = createContext<GiftCardContextProps>({
  initialised: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  creditGiftCard: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  debitGiftCard: () => {},
  isCrediting: false,
  isDebiting: false,
  isVerifying: false,
  redeemedGiftCards: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeAllGiftCards: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetVerifiedCard: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setRedeemedGiftCards: () => {},
  showGiftCardOverchargeModal: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowGiftCardOverchargeModal: () => {},
  verifiedGiftCard: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  verifyGiftCard: () => {},
});

export const useGiftCard = (): GiftCardContextProps => {
  const consumer = useContext(GiftCardContext);
  if (!consumer.initialised) {
    throw new Error('GiftCardContextProvider not initialised');
  }
  return consumer;
};

interface GiftCardContextProviderProps {
  children: React.ReactNode;
}

export const GiftCardContextProvider: React.FC<
  GiftCardContextProviderProps
> = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useGlobalUser();
  const { selectedVenue } = useVenues();
  const {
    url,
    verifyGiftCard: verifyGiftCardAPI,
    creditGiftCard: creditGiftCardAPI,
    debitGiftCard: debitGiftCardAPI,
  } = useAPI();
  const [isVerifying, setIsVerifying] = useState(false);
  const [isDebiting, setIsDebiting] = useState(false);
  const [isCrediting, setIsCrediting] = useState(false);
  const [verifiedGiftCard, setVerifiedGiftCard] = useState<VerifiedGiftCard>();
  const [redeemedGiftCards, setRedeemedGiftCards] = useState<GiftCardLine[]>(
    [],
  );
  const [showGiftCardOverchargeModal, setShowGiftCardOverchargeModal] =
    useState(false);

  const { deviceIdentifier } = useDeviceIdentifier();

  const {
    giftCard: { giftCardPhrase },
  } = usePhrases();

  const axios = useAxiosInterceptor();

  const verifyGiftCard = (cardNumber: string, pin: string) => {
    setIsVerifying(true);

    const verifyGiftCardAPIConfig = verifyGiftCardAPI();
    const verifyGiftCardOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      data: requestBodyFormatter({
        giftCardNumber: cardNumber,
        giftCardPin: pin,
        method: verifyGiftCardAPIConfig.method,
        siteid: selectedVenue?.id,
        userEmailAddress: user.email,
        version: '20',
        ...{
          ...verifyGiftCardAPIConfig.body,
          userDeviceIdentifier: deviceIdentifier,
        },
      }),
    };

    axios(verifyGiftCardOptions)
      .then((response) => {
        const data = response.data as GetVerifyGiftCardResponse;
        if (data.response === 'OK') {
          if (data.balance <= 0) {
            dispatch(
              addNotification(
                getGiftCardPhrase(
                  `$giftCardPhrase$ ${getMaskedCardNumber(
                    cardNumber,
                  )} does not have any remaining balance`,
                  giftCardPhrase,
                ),
                'danger',
              ),
            );
          } else {
            setVerifiedGiftCard({ ...data, giftCardNumber: cardNumber });
          }

          setIsVerifying(false);
        } else {
          setIsVerifying(false);

          const errResponse = response.data as ErrorResponse;
          dispatch(
            addNotification(
              `${getGiftCardPhrase(errResponse.detail, giftCardPhrase)}`,
              'danger',
            ),
          );
        }
      })
      .catch(() => {
        setIsVerifying(false);
        dispatch(
          addNotification(
            getGiftCardPhrase(
              'There was an unexpected error whilst verifying $giftCardPhrase$',
              giftCardPhrase,
            ),
            'danger',
          ),
        );
      });
  };

  const debitGiftCard = (
    giftCardId: string,
    amount: number,
    closeModalCallback?: () => void,
    checkBasketCallback?: CheckBasketParams,
  ) => {
    setIsDebiting(true);

    const debitGiftCardAPIConfig = debitGiftCardAPI();
    const debitGiftCardOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      data: requestBodyFormatter({
        giftCardId,
        amount,
        method: debitGiftCardAPIConfig.method,
        siteId: selectedVenue?.id,
        version: '20',
        ...{
          ...debitGiftCardAPIConfig.body,
          userDeviceIdentifier: deviceIdentifier,
        },
      }),
    };

    axios(debitGiftCardOptions)
      .then((response) => {
        const data = response.data as GetRedeemGiftCardResponse;
        if (data.response === 'OK') {
          const redeemedGiftCard: RedeemedGiftCard = {
            transactionId: data.transactionId,
            giftCardId: giftCardId,
            amount: amount,
          };

          setIsDebiting(false);

          closeModalCallback && closeModalCallback();
          checkBasketCallback &&
            checkBasketCallback(true, undefined, {
              giftCard: redeemedGiftCard,
            });
        } else {
          setIsDebiting(false);

          const errResponse = response.data as ErrorResponse;
          dispatch(
            addNotification(
              `${getGiftCardPhrase(errResponse.detail, giftCardPhrase)}`,
              'danger',
            ),
          );
        }
      })
      .catch(() => {
        setIsDebiting(false);
        dispatch(
          addNotification(
            getGiftCardPhrase(
              'There was an unexpected error whilst debiting $giftCardPhrase$.',
              giftCardPhrase,
            ),
            'danger',
          ),
        );
      });
  };

  const creditGiftCard = (
    giftCardId: string,
    transactionId: number,
    amount: number,
    checkBasketCallback?: CheckBasketParams,
    setStateOnComplete: boolean = true,
  ) => {
    setIsCrediting(true);
    const creditGiftCardAPIConfig = creditGiftCardAPI();
    const creditGiftCardOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      data: requestBodyFormatter({
        giftCardId,
        transactionId,
        amount,
        method: creditGiftCardAPIConfig.method,
        siteId: selectedVenue?.id,
        version: '20',
        ...{
          ...creditGiftCardAPIConfig.body,
          userDeviceIdentifier: deviceIdentifier,
        },
      }),
    };

    axios(creditGiftCardOptions)
      .then((response) => {
        const data = response.data as GetCreditGiftCardResponse;
        if (data.response === 'OK') {
          const giftCardsForCheckBasket = redeemedGiftCards.filter(
            (gc) => gc.transactionId !== transactionId,
          );
          setStateOnComplete && removeGiftCard(transactionId);
          setIsCrediting(false);
          checkBasketCallback &&
            checkBasketCallback(true, undefined, {
              giftCards: giftCardsForCheckBasket,
            });
        } else {
          const errResponse = response.data as ErrorResponse;
          if (errResponse.code === -855) {
            removeGiftCard(transactionId);
          }
          setIsCrediting(false);

          dispatch(
            addNotification(
              `${getGiftCardPhrase(errResponse.detail, giftCardPhrase)}`,
              'danger',
            ),
          );
        }
      })
      .catch(() => {
        setIsCrediting(false);
        dispatch(
          addNotification(
            getGiftCardPhrase(
              'There was an unexpected error whilst crediting $giftCardPhrase$.',
              giftCardPhrase,
            ),
            'danger',
          ),
        );
      });
  };

  const resetVerifiedCard = () => {
    setVerifiedGiftCard(undefined);
  };

  const removeGiftCard = (transactionId: number) => {
    setRedeemedGiftCards((prevGiftCards) =>
      prevGiftCards.filter((gc) => gc.transactionId !== transactionId),
    );
  };

  const removeAllGiftCards = () => {
    redeemedGiftCards.forEach((card) => {
      creditGiftCard(
        card.giftCardId,
        card.transactionId,
        card.amount,
        undefined,
        false,
      );
    });
    setRedeemedGiftCards([]);
  };

  return (
    <GiftCardContext.Provider
      value={{
        initialised: true,
        creditGiftCard,
        debitGiftCard,
        isCrediting,
        isDebiting,
        isVerifying,
        redeemedGiftCards,
        removeAllGiftCards,
        resetVerifiedCard,
        setRedeemedGiftCards,
        showGiftCardOverchargeModal,
        setShowGiftCardOverchargeModal,
        verifiedGiftCard,
        verifyGiftCard,
      }}
    >
      {children}
    </GiftCardContext.Provider>
  );
};
