import { MdClose, MdSearch } from 'react-icons/md';
import { FormControl } from 'react-bootstrap';

interface SearchBarProps {
  changeSearchTerm: (input: string) => void;
  searchTerm: string;
  placeHolder: string;
  clearSearchTerm: () => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  changeSearchTerm,
  searchTerm,
  placeHolder,
  clearSearchTerm,
}) => {
  return (
    <div className="search-bar">
      <FormControl
        type="text"
        value={searchTerm}
        placeholder={placeHolder}
        onChange={(e) => changeSearchTerm((e.target as HTMLInputElement).value)}
      />
      {searchTerm.length > 0 ? (
        <button
          className="search-bar-clear-button"
          onClick={clearSearchTerm}
          aria-label="Clear search"
        >
          <MdClose size={25} />
        </button>
      ) : null}
      <div className="search-bar-search-icon">
        <MdSearch size={25} />
      </div>
    </div>
  );
};
