import cs from 'classnames';

interface InputProps {
  id: string;
  name: string;
  value: string;
  'aria-label': string;
  checked: boolean;
}

interface CheckBoxOptionProps {
  inputProps: InputProps;
  label: string;
  onChange(): void;
}

export const CheckBoxOption: React.FC<CheckBoxOptionProps> = ({
  inputProps,
  label,
  onChange,
}) => {
  const checkInputNoLoyaltyClasses = cs(
    'no-flex-shrink radio-inline',
    inputProps.checked ? 'on' : '',
  );

  return (
    <div className="loyalty-checkboxes flex-grow">
      <label className={checkInputNoLoyaltyClasses} htmlFor={inputProps.id}>
        <input
          {...inputProps}
          type="radio"
          onChange={() => onChange()}
          data-testid="checkbox-loyalty"
        />
      </label>
      <div className="item-details">
        <span>{label}</span>
      </div>
    </div>
  );
};
