
import { useVenues } from 'contexts/VenueContext';
import { VenueInfoHeader } from 'venue/components/SingleVenue/VenueInfo/VenueInfoHeader';

export const VenueInfoBar: React.FC = () => {
  const { selectedVenue } = useVenues();

  return selectedVenue ? (
    <div className="venue-info-bar">
      <VenueInfoHeader venue={selectedVenue} isTitleLink={true} />
    </div>
  ) : null;
};
