import { Dialog } from 'components/Dialog';
import { useGlobalUser } from 'contexts/UserContext';
import { TaxLine } from 'types/models/Basket';

interface TaxRemovalModalProps {
  removeTax: (taxLine: TaxLine) => void;
  show: boolean;
  cancelTaxRemoval: () => void;
  taxLine: TaxLine;
}

export const ConfirmTaxRemovalModal: React.FC<TaxRemovalModalProps> = ({
  removeTax,
  show,
  cancelTaxRemoval,
  taxLine,
}) => {
  const { user } = useGlobalUser();
  const taxName = taxLine.friendlyName || taxLine.name;

  const title = user?.firstName
    ? `${user.firstName}, are you sure you want to remove ${taxName}?`
    : `Are you sure you want to remove ${taxName}?`;

  return (
    <Dialog
      isOpen={show}
      title={title}
      description={taxLine.phrase || ''}
      accept={{
        action: () => removeTax(taxLine),
        text: 'Yes, I want to remove',
        btnType: 'destructive',
      }}
      reject={{
        action: () => cancelTaxRemoval(),
        text: 'No, I want to keep',
      }}
    />
  );
};
