import { useEffect, useState } from 'react';
import GooglePayButton from '@google-pay/button-react';
import braintreeClient from 'braintree-web/client';
import googlePayment from 'braintree-web/google-payment';
import threeDSecure from 'braintree-web/three-d-secure';
import dataCollector from 'braintree-web/data-collector';
import { useConfig } from 'contexts/ConfigContext';
import { useDispatch, useSelector } from 'react-redux';
import { selectTipAmount } from 'checkout/selectors';
import { addNotification } from 'core/actions';
import { isValidGooglePayMerchantId } from 'utils/isValidGooglePayMerchantId';
import { useAdditionalInformation } from 'contexts/AdditionalInformationContext';
import { normaliseFormData } from 'checkout/utils';
import { useServices } from 'contexts/VenueContext';
import { isTimeslotService } from 'venue/utils';
import {
  useCheckout,
  useCheckoutDetails,
  usePayment,
} from 'contexts/CheckoutContext';
import { PaymentType } from 'types/models/Payment';
import { usePayMyBill } from 'contexts/PayMyBillContext/PayMyBillContext';
import { useGlobalUser } from 'contexts/UserContext';

export const GooglePay = ({ isPmb = false }) => {
  const {
    currency,
    env,
    googleMerchantId,
    client: clientName,
    clientPaymentName,
  } = useConfig();

  const dispatch = useDispatch();
  const { isLoggedIn } = useGlobalUser();
  const { selectedService } = useServices();
  const { submitPayment } = useCheckout();
  const { basketTotal, braintreeToken } = useCheckoutDetails();
  const {
    braintreeToken: pmbBraintreeToken,
    paymentAmount,
    submitPayment: submitPmbPayment,
  } = usePayMyBill();
  const {
    setPaymentEnabled,
    setPaymentReady,
    isGooglePayReady,
    paymentDisplayName,
  } = usePayment();

  const tipAmount = useSelector(selectTipAmount);
  const braintreeTokenToUse = isPmb ? pmbBraintreeToken : braintreeToken;
  const totalToUse = isPmb ? paymentAmount : basketTotal;

  const totalPlusTip = totalToUse + tipAmount;

  const shouldShowAdditionalInfo = isTimeslotService(selectedService);

  const [allowedPayments, setAllowedPayments] = useState([]);

  const [gPaymentInstance, setGPaymentInstance] = useState();
  const [cInstance, setCInstance] = useState();
  const [threeDSInstance, setThreeDSInstance] = useState();
  const [threeDSEnabled, setThreeDSEnabled] = useState(true);

  const gPayEnv =
    env.toLocaleLowerCase() === 'production' ? 'PRODUCTION' : 'TEST';

  const Google = global.google;
  const paymentsClient = new Google.payments.api.PaymentsClient({
    environment: gPayEnv,
  });

  const merchantName = paymentDisplayName || clientPaymentName || clientName;

  const {
    isUserFieldRequired,
    userInformationFields,
    savedLocationInformation,
    locationInformationFields,
  } = useAdditionalInformation();

  const showError = (error) => {
    setPaymentEnabled(PaymentType.GooglePay, false);
    dispatch(addNotification(error, 'danger'));
  };

  const handleSubmitPayment = (formData, deviceData, nonce) => {
    if (isPmb) {
      submitPmbPayment(formData, deviceData, nonce, PaymentType.GooglePay);
    } else {
      const normalisedFormData = normaliseFormData(
        locationInformationFields,
        userInformationFields,
        { ...savedLocationInformation, ...formData },
      );
      submitPayment(
        normalisedFormData,
        deviceData,
        nonce,
        PaymentType.GooglePay,
      );
    }
  };

  const userFieldFormats = {
    // key = iOrder field name
    // value = gPay response field name
    address1: 'address1',
    address2: 'address2',
    address3: 'address3',
    town: 'locality',
    first_name: 'name',
    last_name: 'name',
    mobile_phone: 'phoneNumber',
    home_phone: 'phoneNumber',
    postcode: 'postalCode',
    country: 'countryCode',
  };

  const isEmailRequired = !isLoggedIn || isUserFieldRequired('email');
  const isShippingAddressRequired =
    shouldShowAdditionalInfo &&
    Object.keys(userFieldFormats).some((key) => isUserFieldRequired(key));
  const isPhoneNumberRequired =
    shouldShowAdditionalInfo &&
    (isUserFieldRequired('mobile_phone') || isUserFieldRequired('home_phone'));

  const formatUserInfoFields = (paymentData) => {
    const formData = {};
    if (!isLoggedIn) {
      formData.email = paymentData.email;
    }
    if (userInformationFields.length > 0 && shouldShowAdditionalInfo) {
      userInformationFields.forEach((field) => {
        // Handle user field email separately
        if (field.userFieldType === 'email' && field.showInUI) {
          formData[field.name] = paymentData.email;
        }
        // Handle all other fields
        if (
          userFieldFormats.hasOwnProperty(field.userFieldType) &&
          field.showInUI
        ) {
          formData[field.name] =
            paymentData.shippingAddress[userFieldFormats[field.userFieldType]];
        }
      });
    }
    return formData;
  };

  const handleLoadPayment = (paymentData) => {
    gPaymentInstance.parseResponse(paymentData, (err, result) => {
      if (err) {
        // Handle parsing error
        showError('Payment data parse error: ' + err);
      }

      dataCollector.create(
        {
          client: cInstance,
          kount: true,
          paypal: true,
        },
        (dataCollectorError, dataCollectorInstance) => {
          if (dataCollectorError) {
            showError(
              `Error collecting device data ${JSON.stringify(
                dataCollectorError,
              )}`,
            );

            return;
          }

          const deviceData = dataCollectorInstance.deviceData;
          const additionalUserInfo = formatUserInfoFields(paymentData);

          if (result.details.isNetworkTokenized && threeDSEnabled) {
            const threeDSecureParams = {
              amount: totalPlusTip.toFixed(2),
              nonce: result.nonce,
              bin: result.bin,
            };
            threeDSInstance.verifyCard(
              {
                ...threeDSecureParams,
                onLookupComplete(data, next) {
                  // use `data` here, then call `next()`
                  next();
                },
              },
              (err, verifyCardPayload) => {
                if (err) {
                  // showError(normaliseThreeDSecureError(err));
                  console.log(err);
                  return;
                }

                if (verifyCardPayload.liabilityShifted) {
                  // Liability has shifted
                  handleSubmitPayment(
                    additionalUserInfo,
                    deviceData,
                    verifyCardPayload.nonce,
                  );
                } else if (verifyCardPayload.liabilityShiftPossible) {
                  // Liability may still be shifted
                  // Decide if you want to submit the nonce
                  handleSubmitPayment(
                    additionalUserInfo,
                    deviceData,
                    verifyCardPayload.nonce,
                  );
                } else {
                  // Liability has not shifted and will not shift
                  // Decide if you want to submit the nonce
                  handleSubmitPayment(
                    additionalUserInfo,
                    deviceData,
                    verifyCardPayload.nonce,
                  );
                }
              },
            );
          } else {
            // Send result.nonce to your server
            handleSubmitPayment(additionalUserInfo, deviceData, result.nonce);
          }
        },
      );
    });
  };

  useEffect(() => {
    if (!braintreeTokenToUse) {
      showError('Basket is not authorised');

      return;
    }

    if (!currency) {
      showError('Currency code is missing');

      return;
    }

    if (!isValidGooglePayMerchantId(googleMerchantId)) {
      setPaymentEnabled(PaymentType.GooglePay, false);
      return;
    }

    braintreeClient.create(
      {
        authorization: braintreeTokenToUse,
      },
      (clientErr, clientInstance) => {
        if (clientErr) {
          showError('Error connecting to payment service');

          return;
        }

        // take a copy of client instance
        setCInstance(clientInstance);

        googlePayment.create(
          {
            client: clientInstance,
            googlePayVersion: 2,
            ...googleMerchantId,
            // Optional in sandbox; if set in sandbox, this value must be a valid production Google Merchant ID
          },
          (googlePaymentErr, googlePaymentInstance) => {
            // ignoring error - usually when customer closes GPay panel

            setAllowedPayments(
              googlePaymentInstance.createPaymentDataRequest()
                .allowedPaymentMethods,
            );

            // Take a copy of Google PAyment instance for button click
            setGPaymentInstance(googlePaymentInstance);

            paymentsClient
              .isReadyToPay({
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods:
                  googlePaymentInstance.createPaymentDataRequest()
                    .allowedPaymentMethods,
              })
              .then((response) => {
                if (response.result) {
                  setPaymentReady(PaymentType.GooglePay, true);
                }

                const init3DS =
                  clientInstance.getConfiguration().gatewayConfiguration
                    .threeDSecureEnabled;

                init3DS
                  ? threeDSecure.create(
                      {
                        version: 2,
                        client: clientInstance,
                      },
                      (threeDSecureErr, threeDSecureInstance) => {
                        if (threeDSecureErr) {
                          showError('Error initialising 3DSecure');
                          console.log(threeDSecureErr);

                          return;
                        }
                        setThreeDSInstance(threeDSecureInstance);
                      },
                    )
                  : {};
                setThreeDSEnabled(init3DS);
              })
              .catch((err) => {
                // Handle errors
                showError('Ready to pay error:' + err);
              });
          },
        );
      },
    );

    return () => {
      setPaymentEnabled(PaymentType.GooglePay, false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="text-center" data-testid="google-pay-button">
      {isGooglePayReady && (
        <GooglePayButton
          environment={gPayEnv}
          paymentRequest={{
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: allowedPayments,
            emailRequired: isEmailRequired,
            merchantInfo: {
              merchantId: googleMerchantId,
              merchantName: merchantName,
            },
            shippingAddressRequired: isShippingAddressRequired,
            shippingAddressParameters: {
              phoneNumberRequired: isPhoneNumberRequired,
            },
            transactionInfo: {
              currencyCode: currency,
              totalPriceStatus: 'FINAL',
              totalPrice: totalPlusTip.toFixed(2),
            },
          }}
          onLoadPaymentData={(paymentData) => handleLoadPayment(paymentData)}
          buttonType="buy"
          buttonSizeMode="fill"
        />
      )}
    </div>
  );
};
