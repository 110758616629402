import { Link, useLocation } from 'react-router-dom';
import { FiLogIn } from 'react-icons/fi';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { FeatureFlagLink } from 'core/components/Header/FeatureFlagLink';
type LocationState = {
  from: {
    pathname: string;
  };
};

export const LoggedOutHeader: React.FC = () => {
  const { disableUserSignIn, accountDeletionOnly } = useConfig();
  const {
    login: { loginButtonText },
  } = usePhrases();
  const location = useLocation<LocationState>();
  const from = location?.state?.from?.pathname || location.pathname;
  const isOnLoginPage = location.pathname === '/user/login';

  if (disableUserSignIn || isOnLoginPage || accountDeletionOnly) {
    return null;
  }

  return (
    <>
      <Link
        className="header-link user-account"
        to={{ pathname: '/user/login', state: { from: { pathname: from } } }}
        data-testid="header-log-in-link"
      >
        <span className="link-label">{loginButtonText}</span>
        <FiLogIn
          size={20}
          className="link-icon link-icon-right"
          aria-labelledby="loginIconTitle"
        >
          <title id="loginIconTitle">Log in Icon</title>
        </FiLogIn>
      </Link>
      <FeatureFlagLink />
    </>
  );
};
