import { useGlobalUser } from 'contexts/UserContext';

interface EmailConfirmationProps {
  guestEmail?: string;
}

export const EmailConfirmation: React.FC<EmailConfirmationProps> = ({
  guestEmail,
}) => {
  const { isLoggedIn, user } = useGlobalUser();

  const getEmail = () => {
    if (isLoggedIn) {
      return user.email;
    }
    if (guestEmail) {
      return decodeURIComponent(guestEmail);
    }
    return '';
  };

  const emailToUse = getEmail();

  if (emailToUse) {
    return <p>{`A confirmation email has been sent to ${emailToUse}`}</p>;
  }
};
