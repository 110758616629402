import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useAuthCookie } from 'hooks/useAuthCookie';
import { useHistory, useLocation } from 'react-router';
import { setupFetchParams } from 'utils';

export const useAxiosInterceptor = (): AxiosInstance => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { authCookie, deleteAuthCookie } = useAuthCookie();

  const logOutErrors = [-957, -958, -959, -962];
  const axiosIn = axios.create();

  axiosIn.interceptors.request.use(
    async (request: AxiosRequestConfig) => {
      const { headers } = setupFetchParams(authCookie);
      request.headers = headers;
      return request;
    },
    async (error) => Promise.reject(error),
  );

  axiosIn.interceptors.response.use(
    async (response: AxiosResponse) => {
      try {
        const responseBody = response?.config?.data;

        // Check request for method type
        // Need this for checkBasket calls made from menu page
        // On error we can redirect to login page and then to checkout after user logs in
        if (typeof responseBody === 'string') {
          const decodedBody = decodeURIComponent(responseBody);
          const stringBody = decodedBody.replace(/^request=/, '');
          const bodyData = await JSON.parse(stringBody);
          const apiMethod = bodyData?.request?.method;

          // Does response match valid error
          if (
            response.data.response === 'Error' &&
            (logOutErrors.includes(response?.data?.code) ||
              response?.data?.detail.includes('Please log in to continue'))
          ) {
            deleteAuthCookie();

            history.push({
              pathname: '/user/login',
              state: {
                from: {
                  pathname:
                    apiMethod === 'checkBasket' ? '/checkout' : pathname,
                },
              },
            });
          }
        }
      } catch (err) {
        console.error('Error parsing api response', err);
      }
      return response;
    },
    async (error) => Promise.reject(error),
  );

  return axiosIn;
};
