import { useEffect, createRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useMenuGroups, useMenus } from 'contexts/MenuContext';
import { MenuNavButton } from 'menu/components/MenuNav';
import { useServices, useVenues } from 'contexts/VenueContext';
import { getUrlPart, sortWithSortOrder } from 'utils';
import { useConfig } from 'contexts/ConfigContext';
import { CanOrderBanner } from 'menu/components/Alerts';

interface TopLevelMenuNavParams {
  selectedMenuId: string;
  venueId: string;
  serviceName: string;
}

export const TopLevelMenuNav: React.FC<TopLevelMenuNavParams> = ({
  selectedMenuId,
  venueId,
  serviceName,
}) => {
  const { menus, loadingMenus } = useMenus();
  const { getMenuPages, loadingMenuGroups } = useMenuGroups();
  const history = useHistory();
  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();
  const { multipleMenusEnabled } = useConfig();

  const containerRef = createRef<HTMLDivElement>();
  const [containerWidth, setContainerWidth] = useState('100vw');
  const selectedMenu = menus.find(
    (menu) => menu.id.toString() === selectedMenuId,
  );

  useEffect(() => {
    /*Is there a scrollbar? What's the width? reduce the width of the container*/
    const scrollBarWidth = (window.innerWidth - document.body.clientWidth) / 2;
    setContainerWidth(`calc(100vw - ${scrollBarWidth}px`);
  }, [containerRef]);

  const handleMenuChange = (menuId: number) => {
    if (!loadingMenuGroups) {
      getMenuPages(menuId);
      history.push(`/venue/${venueId}/${serviceName}/menus/${menuId}`);
    }
  };

  useEffect(() => {
    if (!loadingMenus && !selectedMenu) {
      const serviceUrlPart = getUrlPart(selectedService);

      history.push(`/venue/${selectedVenue?.id}/${serviceUrlPart}/menus`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menus]);

  const topLevelMenus = menus
    ?.toSorted((a, b) => sortWithSortOrder(a, b))
    .map((tlm) => {
      return (
        <MenuNavButton
          key={`mg-item-${tlm.id}`}
          buttonId={`mg-item-${tlm.id}`}
          buttonClass={
            tlm.id === parseInt(selectedMenuId) || 0
              ? 'active list-group-item'
              : 'list-group-item'
          }
          handleClick={() => handleMenuChange(tlm.id)}
          testId="horizontal-top-level-menu-item"
          buttonText={tlm.name}
        />
      );
    });

  return (
    <div className="top-level-menu">
      {multipleMenusEnabled ? (
        <div
          className="horizontal-list-container"
          style={{ width: containerWidth }}
          data-testid="horizontal-top-level-menu"
        >
          <div className="horizontal-menu list-group">{topLevelMenus}</div>
        </div>
      ) : null}
      <CanOrderBanner menu={selectedMenu} shouldCalculateWidth />
    </div>
  );
};
