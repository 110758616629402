import { useAPI } from 'api/useAPI';
import { requestBodyFormatter } from 'api/utils';
import { AxiosRequestConfig } from 'axios';
import { addNotification } from 'core/actions';
import { useAxiosInterceptor } from 'hooks/useAxiosInstance';
import { createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  CombinedVenue,
  GetTimeslotsResponse,
  Timeslot,
  VenueSalesArea,
} from 'types/models';

interface TimeslotsContext {
  initialised: boolean;
  isFetchingTimeslots: boolean;
  handleFetchTimeslots: (
    venue: CombinedVenue | undefined,
    serviceId: number,
    salesAreaId: VenueSalesArea | undefined,
  ) => void;
  timeslots: Timeslot[];
  selectedTimeslot: Timeslot | undefined; // see above
  setSelectedTimeslot: (timeslot: Timeslot | undefined) => void;
  showTimeslotsModal: boolean;
  setShowTimeslotsModal: (status: boolean) => void;
}

// set up context
export const TimeslotsContext = createContext<TimeslotsContext>({
  initialised: false,
  isFetchingTimeslots: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleFetchTimeslots: () => {},
  timeslots: [],
  selectedTimeslot: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedTimeslot: () => {},
  showTimeslotsModal: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowTimeslotsModal: () => {},
});

// set up hook
export const useTimeslots = (): TimeslotsContext => {
  const consumer = useContext(TimeslotsContext);

  // Condition used to determine if Context Provider has been declared
  if (!consumer.initialised) {
    throw new Error('TimeslotContextProvider not initialised');
  }

  return consumer;
};

interface TimeslotContextProviderProps {
  children: React.ReactNode;
}

// set up provider
export const TimeslotContextProvider: React.FC<
  TimeslotContextProviderProps
> = ({ children }) => {
  const { url, getTimeslots } = useAPI();
  const dispatch = useDispatch();
  const axios = useAxiosInterceptor();

  const [isFetchingTimeslots, setIsFetchingTimeslots] = useState(false);
  const [timeslotOptions, setTimeslotOptions] = useState<Timeslot[]>([]);
  const [selectedTimeslot, setSelectedTimeslot] = useState<
    Timeslot | undefined
  >(undefined);
  const [showTimeslotsModal, setShowTimeslotsModal] = useState(false);

  const handleFetchTimeslots = (
    venue: CombinedVenue | undefined,
    service: number,
    salesArea: VenueSalesArea | undefined,
  ) => {
    if (venue && salesArea && service !== -1) {
      fetchTimeslots(venue.id, service, salesArea.id);
    }
  };

  const fetchTimeslots = (
    venueId: number,
    serviceId: number,
    salesAreaId: number,
  ) => {
    if (!isFetchingTimeslots) {
      setIsFetchingTimeslots(true);
      setTimeslotOptions([]);

      const { method, body } = getTimeslots();
      const getTimeslotsOptions: AxiosRequestConfig = {
        url,
        method: 'POST',
        data: requestBodyFormatter({
          method: method,
          siteId: venueId,
          salesAreaId,
          serviceId,
          ...body,
        }),
      };

      axios(getTimeslotsOptions)
        .then((response) => {
          const data = response.data as GetTimeslotsResponse;
          if (data.timeSlots) {
            setTimeslotOptions(data.timeSlots);
          } else {
            dispatch(
              addNotification(
                'There was an error fetching timeslots.',
                'danger',
              ),
            );
          }
        })
        .catch(() => {
          dispatch(
            addNotification(
              'There was an unexpected error fetching timeslots.',
              'danger',
            ),
          );
        })
        .finally(() => {
          setIsFetchingTimeslots(false);
        });
    }
  };
  return (
    <TimeslotsContext.Provider
      value={{
        initialised: true,
        isFetchingTimeslots,
        handleFetchTimeslots,
        timeslots: timeslotOptions,
        selectedTimeslot,
        setSelectedTimeslot,
        showTimeslotsModal,
        setShowTimeslotsModal,
      }}
    >
      {children}
    </TimeslotsContext.Provider>
  );
};
