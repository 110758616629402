/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useConfig } from 'contexts/ConfigContext';
import { useMenus } from 'contexts/MenuContext';
import { Button } from 'react-bootstrap';
import { MdArrowBack } from 'react-icons/md';
import { handleReturnKeyPress } from 'utils/onKeyEventHelper';

interface MenuBackButtonProps {
  onClick: () => void;
}

export const MenuBackButton: React.FC<MenuBackButtonProps> = ({ onClick }) => {
  const { multipleMenusEnabled } = useConfig();
  const { menus } = useMenus();

  if (!multipleMenusEnabled || menus.length <= 1) {
    return null;
  }

  return (
    <Button
      className="menu-back-button"
      tabIndex={0}
      onClick={() => onClick()}
      onKeyDown={(e) => handleReturnKeyPress(e, () => onClick)}
      data-testid="button-menu-back"
    >
      <MdArrowBack size={16} />
    </Button>
  );
};
