import { createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosRequestConfig } from 'axios';
import { useAPI } from 'api/useAPI';
import { requestBodyFormatter } from 'api/utils';
import { ErrorResponse } from 'types/models';
import { Order } from 'types/models/Orders';
import { OrderHistoryResponse } from 'types/models/Responses/OrderHistoryResponse';
import { addNotification } from 'core/actions';
import { useGlobalUser } from 'contexts/UserContext';
import { useAxiosInterceptor } from 'hooks/useAxiosInstance';

interface OrderHistoryContext {
  initialised: boolean;
  isLoadingOrders: boolean;
  orders: Order[];
  fetchOrders: () => void;
}

export const OrderHistoryContext = createContext<OrderHistoryContext>({
  initialised: false,
  isLoadingOrders: false,
  orders: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchOrders: () => {},
});

export const useOrderHistory = (): OrderHistoryContext => {
  const consumer = useContext(OrderHistoryContext);
  if (!consumer.initialised) {
    throw new Error('Order History Context Provider not initialised');
  }
  return consumer;
};

interface OrderHistoryContextProviderProps {
  children: React.ReactNode;
}

export const OrderHistoryContextProvider: React.FC<
  OrderHistoryContextProviderProps
> = ({ children }) => {
  const { url, orderHistoryRequestOrders } = useAPI();
  const { user } = useGlobalUser();

  const [orders, setOrders] = useState<Order[]>([]);
  const [isFetchingOrders, setIsFetchingOrders] = useState(false);
  const dispatch = useDispatch();
  const axios = useAxiosInterceptor();

  const fetchOrders = () => {
    setIsFetchingOrders(true);
    const { method, body } = orderHistoryRequestOrders();
    const orderHistoryRequestOrdersOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      data: requestBodyFormatter({
        method: method,
        userEmail: user?.email,
        ...body,
      }),
    };

    axios(orderHistoryRequestOrdersOptions)
      .then((response) => {
        setOrders([]);
        const data = response.data as OrderHistoryResponse;
        if (data.response === 'OK') {
          setOrders(data.myOrders as Order[]);
        } else {
          const errResponse = response.data as ErrorResponse;
          dispatch(
            addNotification(
              `There was a problem getting your order history. ${errResponse.detail} (${errResponse.code})`,
              'danger',
            ),
          );
        }
      })
      .catch(() => {
        dispatch(
          addNotification(
            'Something went wrong getting your order history. Please try again',
            'danger',
          ),
        );
      })
      .finally(() => {
        setIsFetchingOrders(false);
      });
  };

  return (
    <OrderHistoryContext.Provider
      value={{
        initialised: true,
        isLoadingOrders: isFetchingOrders,
        orders,
        fetchOrders,
      }}
    >
      {children}
    </OrderHistoryContext.Provider>
  );
};
