import { Button } from 'react-bootstrap';
import { CustomFormattedNumber } from 'common/components/CustomFormattedNumber';
import { useConfig } from 'contexts/ConfigContext';
import { useBasket } from 'contexts/BasketContext';
import { totalBasketPrice } from 'menu/utils';
import { useCheckout } from 'contexts/CheckoutContext';
import { useGlobalUser } from 'contexts/UserContext';
import { useHistory } from 'react-router';

export const CheckoutButton: React.FC = () => {
  const { currency, currencySymbolsDisabled, disableUserSignIn } = useConfig();
  const { isLoggedIn } = useGlobalUser();
  const { checkBasket, isFetchingBasket } = useCheckout();
  const { basket } = useBasket();

  const history = useHistory();

  const onCheckoutClick = () => {
    if (disableUserSignIn || isLoggedIn) {
      checkBasket(true);
    } else {
      history.push({
        pathname: '/user/login',
        state: { from: { pathname: '/checkout' } },
      });
    }
  };

  return isFetchingBasket ? (
    <Button
      className="checkout-button sticky-basket-checkout-button"
      bsStyle="success"
      disabled
      data-testid="sticky-basket-loading-button"
    >
      Loading
    </Button>
  ) : (
    <Button
      className="checkout-button sticky-basket-checkout-button"
      bsStyle="success"
      onClick={(e) => {
        e.stopPropagation();
        onCheckoutClick();
      }}
      disabled={basket.length === 0}
      data-testid="basket-checkout-button"
    >
      {basket.length > 0 ? (
        <span>
          Checkout{' '}
          <CustomFormattedNumber
            style={currencySymbolsDisabled ? 'decimal' : 'currency'}
            value={totalBasketPrice(basket)}
            currency={currency}
            minimumFractionDigits={2}
          />
        </span>
      ) : (
        <span>No items added</span>
      )}
    </Button>
  );
};
