import { useState } from 'react';
import OtpInput from '@wlwo/react-otp-input';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Notification } from 'core/components/Notification';
import { usePhrases } from 'contexts/ConfigContext';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'components/Modal';
import { useGlobalUser } from 'contexts/UserContext';

interface OTPModalProps {
  handleSubmit: (otp: string) => void;
  handleOTPResend: () => void;
}

export const OTPModal: React.FC<OTPModalProps> = ({
  handleSubmit,
  handleOTPResend,
}) => {

  const { otpDetails, resetOTP, showOtpModal } = useGlobalUser();

  const { otpSupportingText } = usePhrases();

  const [otp, setOtp] = useState('');

  const handleOtpChange = (otp: string) => {
    setOtp(otp);
  };

  const handleResend = () => {
    handleOTPResend();
    setOtp('');
  };

  const handleClose = () => {
    resetOTP();
    setOtp('');
  };

  const accountPhrase =
    otpDetails?.channel === 'sms'
      ? `the number ending with ${otpDetails?.mobilePhoneEndsWith}`
      : otpDetails?.email;

  return otpDetails ? (
    <Modal isOpen={showOtpModal} className="otp">
      <ModalHeader closeButton onRequestClose={handleClose} showCloseOnDesktop>
        <span className="h2">Account verification</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12}>
            <Notification />
            <span className="h4">We just need to verify your account...</span>
            <p>
              {`A verification code has been sent via ${otpDetails.channel} to ${accountPhrase}`}
            </p>
            <div className="otpWrapper" data-testid="otp-modal">
              <Form onSubmit={() => handleSubmit(otp)}>
                <OtpInput
                  inputStyle="form-control otpInput"
                  numInputs={6}
                  errorStyle="error"
                  onChange={handleOtpChange}
                  isInputNum={true}
                  isInputSecure={false}
                  shouldAutoFocus
                  value={otp}
                  data-testid="otp-input"
                />
              </Form>
            </div>
            {otpSupportingText && otpSupportingText.trim().length > 0 && (
              <p className="supporting-text">{otpSupportingText}</p>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          bsStyle="primary"
          type="submit"
          onClick={() => handleSubmit(otp)}
          data-testid="btn-verify-otp"
          disabled={otp.length < 6}
        >
          Verify
        </Button>
        <Button onClick={handleResend}>Re-send verification code</Button>
      </ModalFooter>
    </Modal>
  ) : null;
};
