import { ContextualLoader } from 'components/ContextualLoader';
import { useCheckout } from 'contexts/CheckoutContext';
import { useGlobalUser } from 'contexts/UserContext';

export const LoginContextualLoader: React.FC = () => {
  const { isLoggingIn } = useGlobalUser();
  const { isFetchingBasket } = useCheckout();

  return (
    <ContextualLoader
      isVisible={isLoggingIn || isFetchingBasket}
      loaderText={isFetchingBasket ? 'Loading Checkout' : ''}
    />
  );
};
