import { Order } from 'types/models/Orders';

export interface User {
  guestEmail: string;
  orders: Order[];
  screenSize: number;
  token: string;
}

export interface UserInformation {
  TemporaryPassword: boolean;
  address1: string;
  address2: string;
  address3: string;
  braintree_uuid: string;
  country: string;
  county: string;
  dob: string;
  email: string;
  email_optin: boolean;
  extra10: string;
  extra1: unknown;
  extra2: unknown;
  extra3: unknown;
  extra4: unknown;
  extra5: unknown;
  extra6: unknown;
  extra7: unknown;
  extra8: unknown;
  extra9: unknown;
  facebook_user_id: string;
  firstName: string;
  first_name: string;
  gender: string;
  homePhone: string;
  home_phone: string;
  id: number;
  lastName: string;
  last_name: string;
  loyaltyCard: number;
  loyalty_card: number;
  mobilePhone: string;
  mobile_phone: string;
  occupation: string;
  opt_in_email: boolean;
  opt_in_phone: boolean;
  opt_in_postal: boolean;
  opt_in_profiling: boolean;
  opt_in_push: boolean;
  opt_in_sms: boolean;
  postcode: string;
  sms_optin: boolean;
  title: string;
  town: string;
}

export const defaultUser: UserInformation = {
  TemporaryPassword: false,
  address1: '',
  address2: '',
  address3: '',
  braintree_uuid: '',
  country: '',
  county: '',
  dob: '',
  email: '',
  email_optin: false,
  extra10: '',
  extra1: null,
  extra2: null,
  extra3: null,
  extra4: null,
  extra5: null,
  extra6: null,
  extra7: null,
  extra8: null,
  extra9: null,
  facebook_user_id: '',
  firstName: '',
  first_name: '',
  gender: '',
  homePhone: '',
  home_phone: '',
  id: 0,
  lastName: '',
  last_name: '',
  loyaltyCard: 0,
  loyalty_card: 0,
  mobilePhone: '',
  mobile_phone: '',
  occupation: '',
  opt_in_email: false,
  opt_in_phone: false,
  opt_in_postal: false,
  opt_in_profiling: false,
  opt_in_push: false,
  opt_in_sms: false,
  postcode: '',
  sms_optin: false,
  title: '',
  town: '',
};
