import { CombinedVenue } from 'types/models';
import { InfoWindow, Marker } from '@react-google-maps/api';
import { useHistory } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import { usePhrases } from 'contexts/ConfigContext';
interface MapMarkerProps {
  venue: CombinedVenue;
  clusterer: any;
}
export const MapMarker: React.FC<MapMarkerProps> = ({ venue, clusterer }) => {
  const { venueTitle } = usePhrases();
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const markerPosition = {
    lat: venue.coordinates.latitude,
    lng: venue.coordinates.longitude,
  };
  const history = useHistory();
  const goToVenue = () => {
    history.push(`/venue/${venue.id}`);
  };

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  useEffect(() => {
    if (showInfoWindow) {
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.blur();
        }
      }, 25);
    }
  }, [showInfoWindow]);

  return (
    <>
      <Marker
        key={venue.id}
        clusterer={clusterer}
        position={markerPosition}
        onClick={() => {
          setShowInfoWindow(true);
        }}
      />
      {showInfoWindow && (
        <InfoWindow
          position={markerPosition}
          options={{
            disableAutoPan: false,
            pixelOffset: new window.google.maps.Size(0, -40),
          }}
          onCloseClick={() => setShowInfoWindow(false)}
        >
          <div>
            <h2>{venue.name}</h2>
            <button
              ref={buttonRef}
              className="btn btn-primary"
              onClick={() => goToVenue()}
            >
              Choose {venueTitle}
            </button>
          </div>
        </InfoWindow>
      )}
    </>
  );
};
