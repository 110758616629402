import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import sortBy from 'lodash/sortBy';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { useSalesAreas, useServices, useVenues } from 'contexts/VenueContext';
import { showServiceChoiceModal } from 'venue/actions';
import { useDispatch, useSelector } from 'react-redux';
import { SelectedServiceChoiceModal } from 'venue/components/SelectedServiceChoiceModal/SelectedServiceChoiceModal';
import {
  SalesAreaHeadline,
  SalesAreaSubtitle,
  SalesAreaCard,
} from 'sales-areas/components';
import {
  selectModalShown,
  selectIsServiceChoiceModalOpen,
} from 'venue/selectors';
import core from 'core';
import {
  ServiceUrlName,
  CombinedVenue,
  ServiceType,
  VenueSalesArea,
} from 'types/models';
import { getServiceFromUrl } from 'utils';
import { useBasket } from 'contexts/BasketContext';
import { filterActiveSalesAreas, getSupportedServices } from 'venue/utils';
import { Loader } from 'components/Loader';
import { VenueInfoLayout } from 'layouts';
import { useCheckout } from 'contexts/CheckoutContext';

interface ParamsProps {
  venueId: string;
  serviceName?: string;
  serviceId?: string;
  salesAreaId?: string;
}

export const SalesAreaPage: React.FC = () => {
  const {
    disableSearch,
    homepage,
    enableClickAndCollect,
    enableDeliveryLocation,
    enableOrderAndPay,
  } = useConfig();
  const { salesAreaPhrase } = usePhrases();
  const dispatch = useDispatch();
  const history = useHistory();

  const params = useParams<ParamsProps>();

  const { selectedService, setSelectedService } = useServices();
  const {
    venues,
    isLoadingVenues,
    selectedVenue,
    setSelectedVenue,
    venueHomeData,
    fetchVenueSummary,
    fetchVenues,
    fetchVenueHome,
  } = useVenues();
  const { salesAreas, selectedSalesArea, setSelectedSalesArea } =
    useSalesAreas();
  const { clearBasket } = useBasket();
  const { clearCheckout } = useCheckout();

  const serviceChoiceModalOpen = useSelector(selectIsServiceChoiceModalOpen);
  const modalShown = useSelector(selectModalShown);

  const activeSalesAreas = filterActiveSalesAreas(salesAreas, selectedService);

  const loaded = !isLoadingVenues && venues.length > 0;

  const linkedVenueId = parseInt(params.venueId, 10);
  const salesAreaFromRoute = parseInt(params.salesAreaId ?? '-1');
  const serviceIdFromRoute = parseInt(params.serviceId ?? '-1');

  const redirectToHomepage = () => {
    if (venues.length > 0) {
      if (disableSearch) {
        // eslint-disable-next-line no-console
        console.log('Redirecting to client homepage.');
        window.open(homepage, '_self');
      } else {
        history.push('/venues');
      }
    }
  };

  const redirectToVenue = (venueId: number) => {
    if (venues.length > 0) {
      if (disableSearch) {
        // eslint-disable-next-line no-console
        console.log('Redirecting to client homepage.');
        window.open(homepage, '_self');
      } else {
        history.push(`/venue/${venueId}`);
      }
    }
  };

  const showDeeplinkModal = (
    deeplinkVenue: CombinedVenue,
    serviceId: number,
  ) => {
    const venueSupportedServices = getSupportedServices(
      deeplinkVenue.services,
      enableOrderAndPay,
      enableClickAndCollect,
      enableDeliveryLocation,
    );
    if (
      serviceId &&
      venueSupportedServices.includes(serviceId) &&
      selectedSalesArea?.services.includes(serviceId)
    ) {
      setSelectedService(serviceId);
    } else {
      history.push(`/venue/${deeplinkVenue.id}`);
      return;
    }

    dispatch(core.actions.changeCurrency(deeplinkVenue.currency.currencyCode));
    dispatch(
      core.actions.changeLocale(deeplinkVenue.locale.replace(/_/g, '-')),
    );
    dispatch(showServiceChoiceModal());
  };

  const setService = (linkedServiceId: ServiceType) => {
    if (linkedServiceId !== selectedService) {
      setSelectedService(linkedServiceId);
    }
  };

  const findVenue = (linkedVenueId: number) => {
    return venues.find((x: { id: number }) => x.id === linkedVenueId);
  };

  const checkForService = (foundVenue: CombinedVenue) => {
    if (params.serviceName) {
      const tempServiceId = getServiceFromUrl(
        params.serviceName as ServiceUrlName,
      );
      if (tempServiceId !== selectedService) {
        setService(tempServiceId);
      }
    }

    if (serviceIdFromRoute !== -1) {
      foundVenue.services.includes(serviceIdFromRoute)
        ? setService(serviceIdFromRoute)
        : redirectToVenue(foundVenue.id);
    }
  };

  const checkForSalesAreas = (foundVenue: CombinedVenue) => {
    if (salesAreaFromRoute !== -1 && serviceIdFromRoute !== -1) {
      const salesArea = foundVenue.salesArea.find(
        (sa: { id: number }) => sa.id === salesAreaFromRoute,
      );

      !salesArea ? redirectToHomepage() : setSelectedSalesArea(salesArea);
    }
  };

  const handleClick = (salesArea: VenueSalesArea) => {
    setSelectedSalesArea(salesArea);
    dispatch(showServiceChoiceModal());
  };

  // Handle deeplink on first load to get venue details
  useEffect(() => {
    // from a deeplink /venue/6/sales-area/12 when choosing the service on intial load
    // selectedVenue and selectedService are both undefined so it was calling the fetch again even though we had all the venues
    if (!selectedVenue && selectedService === -1 && venues.length === 0) {
      fetchVenues(linkedVenueId);
      fetchVenueSummary(undefined, linkedVenueId);
      fetchVenueHome(linkedVenueId);
    } else if (selectedVenue && venueHomeData?.venueId !== selectedVenue.id) {
      fetchVenueHome(linkedVenueId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVenue, selectedService]);

  // Handles deeplinks after venues have been fetched
  useEffect(() => {
    if (loaded && linkedVenueId) {
      const foundVenue = findVenue(linkedVenueId);

      // Check for found venue and save if it is new
      if (!foundVenue) {
        redirectToHomepage();
        return;
      } else if (foundVenue && foundVenue.id != selectedVenue?.id) {
        setSelectedVenue(foundVenue);
      }

      checkForService(foundVenue);
      checkForSalesAreas(foundVenue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  useEffect(() => {
    if (
      serviceIdFromRoute !== -1 &&
      selectedSalesArea &&
      loaded &&
      !serviceChoiceModalOpen &&
      !modalShown
    ) {
      clearBasket();
      clearCheckout();

      if (!selectedVenue) {
        return;
      }

      if (selectedSalesArea?.services.includes(serviceIdFromRoute)) {
        showDeeplinkModal(selectedVenue, serviceIdFromRoute);
      } else {
        setSelectedService(-1);
        redirectToVenue(selectedVenue.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSalesArea, selectedVenue, loaded]);

  const salesAreaCards = sortBy(activeSalesAreas, [
    (sa) => sa.location.distance,
    (sa) => sa.friendly || sa.name,
  ]).map((sa) => {
    return (
      <SalesAreaCard key={sa.id} salesArea={sa} handleClick={handleClick} />
    );
  });

  if (isLoadingVenues || venues.length === 0) {
    return (
      <div className="container">
        <div className="text-center">
          <Loader />
        </div>
      </div>
    );
  }

  if (selectedVenue && activeSalesAreas.length > 0) {
    return (
      <VenueInfoLayout
        isTitleLink={true}
        selectedVenue={selectedVenue}
        classNamePrefix="sales-area"
      >
        <div
          className="sales-area-panel panel panel-default"
          data-testid="panel-sales-area-header"
        >
          <SalesAreaHeadline />
          <SalesAreaSubtitle />
          <hr />
          <div className="sales-area-list">{salesAreaCards}</div>
        </div>
        <SelectedServiceChoiceModal />
      </VenueInfoLayout>
    );
  }

  return (
    <div className="container container-sm">
      <div className="panel panel-default text-center">
        There are currently no {salesAreaPhrase.toLocaleLowerCase()}s available.
      </div>
    </div>
  );
};
