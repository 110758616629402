import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { useAccountDeletion, useGlobalUser } from 'contexts/UserContext';
import { OTPModal } from 'login/components';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ConfirmAccountDeletionDialog } from 'user/components/LoggedInUserComponents';
import cs from 'classnames';

export const AccountDeletion: React.FC = () => {
  const { accountDeletionOnly } = useConfig();
  const {
    deleteAccount: {
      prompt: { supportingText },
      confirmation: { supportingText: confirmationSupportingText, title },
    },
  } = usePhrases();

  const { logOut } = useGlobalUser();
  const {
    deleteAccountRequest,
    confirmAccountDeletion,
    accountDeleted,
    resetDeletionStatus,
  } = useAccountDeletion();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const pageWidthClasses = cs(accountDeletionOnly ? 'col-sm-12' : 'col-sm-9');

  const handleButtonClick = () => {
    setIsOpen(true);
  };

  const handleRejection = () => {
    setIsOpen(false);
  };

  const handleAccept = () => {
    setIsOpen(false);
    deleteAccountRequest();
  };

  const handleOtp = (otp: string) => {
    confirmAccountDeletion(otp);
  };

  const handleOTPResend = () => {
    deleteAccountRequest();
  };

  useEffect(() => {
    return () => {
      if (accountDeleted) {
        logOut();
        resetDeletionStatus();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountDeleted]);

  return !accountDeleted ? (
    <div className="container">
      <div className={pageWidthClasses}>
        <div className="panel panel-default">
          <h2>Delete My Account</h2>
          <p>{supportingText}</p>
          <Button
            className="btn btn-danger"
            onClick={handleButtonClick}
            data-testid="button-account-deletion"
          >
            Request Account Deletion
          </Button>
        </div>
      </div>
      <ConfirmAccountDeletionDialog
        isOpen={isOpen}
        confirmAction={handleAccept}
        rejectAction={handleRejection}
      />
      <OTPModal handleSubmit={handleOtp} handleOTPResend={handleOTPResend} />
    </div>
  ) : (
    <div className="col-sm-12">
      <div
        className="panel panel-default"
        data-testid="text-successful-account-deletion"
      >
        <h3>{title}</h3>
        <p>{confirmationSupportingText}</p>
        <Link to="/">Return to Homepage</Link>
      </div>
    </div>
  );
};
