import { Email } from 'core/components/RHF/FieldTemplates';
import { usePhrases } from 'contexts/ConfigContext';
import { useGlobalUser } from 'contexts/UserContext';

export const GuestCheckoutForm: React.FC = () => {
  const {
    guestCheckout: { guestCheckoutPhrase },
  } = usePhrases();
  const { isLoggedIn } = useGlobalUser();

  const emailField = {
    label: 'Email Address',
    max: 64,
    min: 5,
    name: 'email',
    placeholder: 'Email Address',
    required: true,
    type: 'email',
  };

  return (
    <>
      {!isLoggedIn ? (
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <Email field={emailField} />
            <p className="additional-info-supporting-text text-muted">
              {guestCheckoutPhrase}
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
};
