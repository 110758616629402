import * as actionTypes from './actionTypes';
import { initialState } from './model';

function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.OTP_ENABLED:
      return state.set('otpEnabled', action.otpEnabled);

    default:
      return state;
  }
}

export default reducer;
