import { useState } from 'react';
import { Panel } from 'react-bootstrap';
import { ItemButtons, ItemOpeningTimes } from './components';
import { useEffectSkipFirst } from 'hooks/useEffectSkipFirst';
import { FormattedDistance } from 'common/components/FormattedDistance';
import { ServiceType, CombinedVenue } from 'types/models';
import { VenueAddress } from 'venue/components/SingleVenue/VenueInfo';
import { useBreakpoint } from 'contexts/BreakpointContext';
import {
  IoChevronDownCircleOutline,
  IoChevronUpCircleOutline,
} from 'react-icons/io5';

interface VenueListItemProps {
  onRowExpand: () => void;
  venue: CombinedVenue;
  handleButtonClick: (service: ServiceType, venue: CombinedVenue) => void;
}

export const VenueListItem: React.FC<VenueListItemProps> = ({
  onRowExpand,
  venue,
  handleButtonClick,
}) => {
  const [venueDetailsHidden, setVenueDetailsHidden] = useState(true);
  const { breakpoints } = useBreakpoint();
  const isMobile =
    breakpoints.xs === true ||
    breakpoints.tiny === true ||
    breakpoints.landscape === true;

  const toggleDetails = () => {
    setVenueDetailsHidden(!venueDetailsHidden);
  };

  useEffectSkipFirst(() => {
    onRowExpand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venueDetailsHidden]);

  return (
    <Panel
      className={`venue-panel${venueDetailsHidden ? ' compact' : ''}`}
      data-testid={`venue-panel-${venue.id}`}
    >
      <div className="venue-panel-title">
        <h2 className="no-margin" data-testid="name-venue-header">
          {venue.name}
        </h2>
        {isMobile ? (
          <button
            onClick={() => toggleDetails()}
            className="icon-button"
            aria-label={
              venueDetailsHidden ? 'Show venue details' : 'Hide venue details'
            }
            aria-expanded={!venueDetailsHidden}
          >
            {venueDetailsHidden ? (
              <IoChevronDownCircleOutline />
            ) : (
              <IoChevronUpCircleOutline />
            )}
          </button>
        ) : null}
      </div>

      <FormattedDistance distance={venue.distance} showIcon={true} />

      <div className="venue-panel-content">
        {!isMobile || !venueDetailsHidden ? (
          <>
            <div className="venue-panel-address">
              <VenueAddress
                address={venue.address}
                separator="<br/>"
                className="text-muted"
              />
              {venue.contactDetails.telephone ? (
                <a href={`tel:${venue.contactDetails.telephone}`}>
                  {venue.contactDetails.telephone}
                </a>
              ) : null}
            </div>
            <ItemOpeningTimes venue={venue} data-testid="venue-panel-timings" />
          </>
        ) : null}

        <ItemButtons venue={venue} handleClick={handleButtonClick} />
      </div>
    </Panel>
  );
};
