import { usePhrases } from 'contexts/ConfigContext';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { useGlobalUser } from 'contexts/UserContext';
import { Button } from 'react-bootstrap';

export const UnlinkLoyaltyPage: React.FC = () => {
  const {
    loyalty: { cardPhrase },
  } = usePhrases();
  const { user } = useGlobalUser();

  const { unlinkLoyaltyCard } = useLoyaltyRewards();
  const loyaltyCard = user.loyalty_card;

  return (
    <div className="container container-md">
      <div className="panel panel-default">
        <h2>Unlink Loyalty Card</h2>
        {loyaltyCard ? (
          <>
            <p>
              Your {cardPhrase}: {loyaltyCard}
            </p>
            <Button
              bsStyle="primary"
              onClick={() => unlinkLoyaltyCard(loyaltyCard)}
            >
              Unlink Loyalty Card
            </Button>
          </>
        ) : (
          <p>User does not have a {cardPhrase}</p>
        )}
      </div>
    </div>
  );
};
