import { useState } from 'react';

import { TaxLine } from 'types/models/Basket';
import { TaxLineItem } from './TaxLineItem';
import { ConfirmTaxRemovalModal } from 'checkout/components/Modals/ConfirmTaxRemovalModal';
import { useCheckout, useTaxes } from 'contexts/CheckoutContext';

export const TaxTotals: React.FC = () => {
  const { checkBasket } = useCheckout();
  const { taxLines } = useTaxes();
  const hasTaxes = taxLines.length > 0;

  const [showTaxRemovalModal, setShowTaxRemovalModal] =
    useState<boolean>(false);
  const [taxToBeRemoved, setTaxToBeRemoved] = useState<TaxLine>();

  const handleRemovalClick = (taxLine: TaxLine) => {
    setTaxToBeRemoved(taxLine);
    setShowTaxRemovalModal(true);
  };

  const handleConfirmRemoveTax = (taxLine: TaxLine) => {
    checkBasket(true, undefined, undefined, undefined, taxLine);
    setTaxToBeRemoved(undefined);
    setShowTaxRemovalModal(false);
  };

  const handleCancelTaxRemoval = () => {
    setShowTaxRemovalModal(false);
    setTaxToBeRemoved(undefined);
  };

  return hasTaxes ? (
    <>
      {taxLines.map((taxLine: TaxLine) => (
        <TaxLineItem
          taxLine={taxLine}
          key={taxLine.id}
          handleRemovalClick={handleRemovalClick}
        />
      ))}
      {taxToBeRemoved && (
        <ConfirmTaxRemovalModal
          taxLine={taxToBeRemoved}
          show={showTaxRemovalModal}
          removeTax={handleConfirmRemoveTax}
          cancelTaxRemoval={handleCancelTaxRemoval}
        />
      )}
    </>
  ) : null;
};
