import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useAuthCookie } from 'hooks/useAuthCookie';
import JSONBigInt from 'json-bigint';
import { setupFetchParams } from 'utils';

interface AxiosInstanceProps {
  pmbAxios: AxiosInstance;
}

export const useAxiosInstance = (): AxiosInstanceProps => {
  const { authCookie } = useAuthCookie();

  const pmbAxios = axios.create({
    transformResponse: [
      (data) => {
        try {
          return JSONBigInt({ storeAsString: true }).parse(data);
        } catch (err) {
          throw new Error('Failed to parse response data');
        }
      },
    ],
  });

  pmbAxios.interceptors.request.use(
    async (request: AxiosRequestConfig) => {
      const { headers } = setupFetchParams(authCookie);
      request.headers = headers;
      return request;
    },
    async (error) => Promise.reject(error),
  );
  return { pmbAxios };
};
