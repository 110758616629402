import { Dictionary, Templates, WLWOConfig } from 'types/models';

export const defaultConfig: WLWOConfig = {
  applePayButtonColour: 'black',
  acceptsPayment: false,
  acceptsTip: false,
  accountDeletionOnly: false,
  amountOfVenuesToShow: 30,
  captchaDisabled: false,
  choicesFirstInLine: false,
  client: '',
  clientPaymentName: '',
  code: 0,
  compactChoicesView: false,
  currency: 'GBP',
  currencySymbolsDisabled: false,
  customFooter: '',
  customiserButtonDiameter: 25,
  defaultBasketItem: [],
  devGoogleAnalytics: '',
  dictionary: {
    basket: '',
    branchTitle: '',
    cardNumber: '',
    cvvNumber: '',
    menus: '',
    postalCode: '',
    selectVenue: 'Select a venue',
    venueTitle: '',
  } as Dictionary,
  disableIncrementingForDefaults: false,
  disableSearch: false,
  disableUserSignIn: false,
  distanceFormatMiles: true,
  enableClickAndCollect: true,
  enableDeliveryLocation: false,
  enableOrderAndPay: false,
  env: 'qa1',
  forms: {
    edit: [],
    register: [],
    linkLoyaltyAccount: [],
  },
  galleryViewEnabled: false,
  giftCardImage: '',
  googleAnalytics: '',
  googleKey: '',
  googleMapsApiKey: '',
  googleMerchantId: '',
  gradientTintColour: '000000',
  guestCheckoutEnabled: false,
  homeText: '',
  homepage: '',
  hideSelectVenueLink: false,
  iosAppId: '',
  logoLinkToHomepage: false,
  loyalty: {
    addLoyaltyCard: {
      altText: '$loyaltyCardPhrase$',
      imageURL: '',
    },
    canAssociateLoyaltyCard: false,
    loyaltySignUpAutomatically: false,
    shouldPromptForAssociationPostSignUp: false,
    userShowLoyaltyCard: false,
  },
  maxNumberOfVouchers: undefined,
  menuAutoScrollDisabled: false,
  menuLevelReorderEnabled: false,
  minSearchTermLength: 3,
  multipleMenusEnabled: false,
  openingTimes24hFormat: false,
  openingTimesRemovedFromTimeslotModal: true,
  pageTitle: '',
  payPalButtonColour: 'gold',
  prodCaptchaSitekey: undefined,
  productSelectionIndicatorsEnabled: false,
  privacyPolicyUrl: '',
  removeBackLink: false,
  searchPredictionEnabled: false,
  searchText: '',
  showAllergensButton: false,
  showAllergenPrompt: false,
  showFolioBalance: false,
  showResetLoyaltyPin: false,
  showVenueHeaderBanner: false,
  showVenueHomeBanners: false,
  shouldDisplayDailyCalorieAllowancePhraseOnMenu: true,
  shouldPrefixRoomPhrase: true,
  shouldShowGradient: true,
  specialRequestsDisabled: false,
  stickyBasketEnabled: false,
  supportsGiftCardRedemption: false,
  supportsOrderDestinations: true,
  supportsRewardsRedemption: false,
  supportsTip: false,
  tableSelectorSearchEnabled: true,
  templates: {
    menu: undefined,
  } as Templates,
  timeslots24hFormat: false,
  tipPresets: [5, 10, 15, 20],
  venueDetailsExpandedByDefault: false,
  viewMenuButtonOnTop: false,
  vouchersEnabled: false,
  waitTimeEnabled: true,
  wlaAspectRatioEnabled: false,
};
