import { FaUserCircle } from 'react-icons/fa';
import cs from 'classnames';
import { useGlobalUser } from 'contexts/UserContext';

interface LoggedInUserButtonInterface {
  isMenuOpen: boolean;
  onClick: () => void;
}

export const LoggedInUserButton: React.FC<LoggedInUserButtonInterface> = ({
  isMenuOpen,
  onClick,
}) => {
  const { user } = useGlobalUser();

  return (
    <button
      className={cs('header-link', isMenuOpen && 'active')}
      onClick={() => {
        onClick();
      }}
    >
      <span className="link-label">{` ${user?.firstName} ${user?.lastName}`}</span>
      <FaUserCircle
        size={20}
        className="link-icon link-icon-right"
        aria-labelledby="loggedInIconTitle"
      >
        <title id="loggedInIconTitle">Logged in Icon</title>
      </FaUserCircle>
    </button>
  );
};
