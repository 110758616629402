import { usePhrases } from 'contexts/ConfigContext';
import { useState } from 'react';
import {
  MdCalendarToday,
  MdOutlineShoppingCart,
  MdRedeem,
} from 'react-icons/md';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { FormatDateOptions, useIntl } from 'react-intl';
import { RewardItem } from 'types/models/Loyalty';
import { getLoyaltyPhrase } from 'utils';
import cs from 'classnames';
import { useLoyaltyRewards } from 'contexts/LoyaltyRewardContext';
import { QuantityField } from 'menu/components/MenuModal/ModalComponents';
import { useCheckout } from 'contexts/CheckoutContext';

interface LoyaltyRewardItemProps {
  reward: RewardItem;
  isAvailable: boolean;
  handleClose: () => void;
}

export const LoyaltyRewardItem: React.FC<LoyaltyRewardItemProps> = ({
  reward,
  isAvailable,
  handleClose,
}) => {
  const intl = useIntl();
  const {
    loyalty: { rewardPhrase, redeemButtonText, rewardBasketIndicator },
  } = usePhrases();
  const { debitLoyaltyReward, redeemedLoyaltyRewards } = useLoyaltyRewards();
  const { checkBasket } = useCheckout();
  const [quantitySelected, setQuantitySelected] = useState<number>(1);

  const redeemedReward = redeemedLoyaltyRewards.find(
    (r) => r.rewardId === reward.rewardId,
  );

  const rewardBalance = redeemedReward
    ? reward.balance - redeemedReward.quantity
    : reward.balance;

  const canRedeemReward = (): boolean => {
    return (reward.isUnlimited && !redeemedReward) || rewardBalance > 0;
  };

  const calculateExpiryDate = (date: string) => {
    const dateObj = new Date(date);
    if (dateObj.toLocaleTimeString() === '12:00:00 AM') {
      dateObj.setSeconds(dateObj.getSeconds() - 1);
    }

    const dateOptions: FormatDateOptions = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    };

    return intl.formatDate(dateObj, dateOptions);
  };

  const calculateMaxQuantity = (): number => {
    if (reward.balance > 99) {
      if (redeemedReward) {
        return 99 - redeemedReward.quantity;
      }
      return 99;
    }
    return rewardBalance;
  };

  const handleAddReward = () => {
    debitLoyaltyReward(reward, quantitySelected, handleClose, checkBasket);
  };
  const handleQuantityChange = (quantity: number) => {
    setQuantitySelected(quantity);
  };

  const redeemedRewardIndicator =
    rewardBasketIndicator === 'bookmark' ? (
      <div className="reward-bookmark">
        <MdOutlineShoppingCart size={25} />
        {`x${redeemedReward?.quantity}`}
      </div>
    ) : (
      <div className="reward-inline">
        <MdOutlineShoppingCart size={20} />
        {`${redeemedReward?.quantity} x Redeemed`}
      </div>
    );

  const rewardItemClasses = cs(
    'reward-item',
    !isAvailable && 'reward-item-unavailable',
  );

  const rewardHeaderClasses = cs(
    'reward-header',
    redeemedReward &&
      rewardBasketIndicator === 'bookmark' &&
      'reward-header-bookmark',
  );

  return (
    <div className={rewardItemClasses} data-testid={`reward-item-${reward.id}`}>
      <div className={rewardHeaderClasses}>
        <h4>{reward.displayName}</h4>
        <div className="reward-info">
          <div className="reward-availability">
            <MdRedeem size={20} />
            {reward.isUnlimited ? 'Unlimited' : `${rewardBalance} Available`}
          </div>
          {reward.availableToDate && (
            <div className="reward-expiry">
              <MdCalendarToday size={20} className="expiry-icon" />
              Expires {calculateExpiryDate(reward.availableToDate)}
            </div>
          )}
          {redeemedReward && redeemedRewardIndicator}
        </div>
      </div>
      {reward.termsAndConditionsUrl !== '' && isAvailable ? (
        <div
          className="terms-and-conditions"
          data-testid="link-terms-and-conditions"
        >
          <a
            href={reward.termsAndConditionsUrl}
            rel="noreferrer"
            target="_blank"
          >
            Terms and Conditions <FaExternalLinkAlt />
          </a>
        </div>
      ) : null}
      <hr />
      <div className="reward-description">{reward.displayDescription}</div>
      {isAvailable && !reward.isUnlimited && rewardBalance > 1 && (
        <div className="reward-quantity">
          <span>Quantity</span>
          <QuantityField
            quantity={quantitySelected}
            onChange={handleQuantityChange}
            min={1}
            max={calculateMaxQuantity()}
          />
        </div>
      )}
      {isAvailable && canRedeemReward() && (
        <button
          className="add-reward-btn btn btn-primary"
          onClick={handleAddReward}
          data-testid="debit-reward-btn"
        >
          {getLoyaltyPhrase(redeemButtonText, rewardPhrase)}
        </button>
      )}
    </div>
  );
};
