import { Map } from 'immutable';

/* eslint-disable-next-line */
// Below was used to build config into bundle but this requires re-build everytime config changes.
// const prodConfig = require(`${__dirname}/../../clients/${process.env.CLIENT}/config/config.json`);

export const setConfig = (config) => {
  return typeof config === 'undefined'
    ? Map({})
    : Map({
        acceptsTip: false,
        tipPresets: [],
        ...config,
      });
};

export const initialState = Map({
  // config: setConfig(CONFIG),
  currency: 'GBP',
  locale: 'en',
  notification: Map({
    message: null,
    level: null,
    code: null, // Should match Bootstrap's variants of success, warning, danger, or info
  }),
  cookiesDisabledAcknowledgement: false,
});