import { Button } from 'react-bootstrap';
import { usePhrases } from 'contexts/ConfigContext';
import { useCheckout } from 'contexts/CheckoutContext';

export const GuestLoginButton: React.FC = () => {
  const {
    login: { continueAsGuestText },
  } = usePhrases();
  const { checkBasket } = useCheckout();

  const guestLogIn = () => {
    checkBasket(true);
  };

  return (
    <Button
      bsStyle="primary"
      type="submit"
      onClick={() => guestLogIn()}
      block
      data-testid="guest-login-submit-button"
    >
      {continueAsGuestText}
    </Button>
  );
};
