import { Redirect, useRouteMatch } from 'react-router-dom';
import { TimeslotsModal } from 'core/components/Modals/TimeslotsModal';
import { TablesModal } from 'core/components/Modals/TablesModal';
import { OrderVenueInformation } from 'order-history/components/Order';
import { Order as OrderType, OrderBasketLine } from 'types/models/Orders';
import { OrderBasket } from 'order-history/components/OrderBasket';
import { isPromotion } from 'types/guards';
import { useOrderHistory } from 'contexts/OrderHistoryContext';

interface ParamsProps {
  orderId?: string | undefined;
}

export const Order: React.FC = () => {
  const match = useRouteMatch<ParamsProps>();
  const { orders } = useOrderHistory();

  if (!orders || orders.length === 0) {
    return <Redirect to="/user/order" />;
  }

  const order: OrderType | undefined = orders.find(
    (singleOrder: OrderType) =>
      singleOrder.accountNumber === parseInt(match.params.orderId || '', 10),
  );

  if (typeof order === 'undefined') {
    return <Redirect to="/user/order" />;
  }

  //need to do this as the myOrders call doesn't actually return an order service. Therefore, we're setting what it is based on the name
  const serviceId = (
    order.basket.find((line) => !isPromotion(line)) as OrderBasketLine
  ).orderingModeId;

  if (!serviceId) {
    return null;
  }

  return (
    <div className="container" data-testid="order-history-account">
      <div className="panel panel-default">
        <h2>{`Order #${order.accountNumber}`}</h2>
        <div className="row">
          <div className="col-sm-6">
            <div className="panel panel-default">
              <OrderBasket order={order} />
            </div>
          </div>
          <div className="col-sm-6">
            <OrderVenueInformation orderService={serviceId} order={order} />
          </div>
        </div>
      </div>
      <TimeslotsModal />
      <TablesModal />
    </div>
  );
};
