import cs from 'classnames';
import { useConfig } from 'contexts/ConfigContext';
import { HiLocationMarker } from 'react-icons/hi';

interface FormattedDistanceProps {
  additionalClassName?: string;
  distance: number;
  showBrackets?: boolean;
  showIcon: boolean;
}

export const FormattedDistance: React.FC<FormattedDistanceProps> = ({
  additionalClassName,
  distance,
  showBrackets = false,
  showIcon,
}) => {
  const { distanceFormatMiles } = useConfig();

  const calcDistance = (distance: number) => {
    if (distanceFormatMiles === true) {
      return distance >= 1000
        ? `${Math.round(distance / 100 / 1.609) / 10} miles`
        : `${Math.round(distance / 1.094)} yards`;
    }

    return distance >= 1000
      ? `${Math.round(distance / 100) / 10} kilometers`
      : `${distance} meters`;
  };

  const distanceClasses = cs('venue-distance-indicator', additionalClassName);

  if (distance === -1) return null;

  return (
    <div className={distanceClasses}>
      <div className="venue-distance-indicator-icon">
        {showIcon === true ? <HiLocationMarker size={16} /> : null}
      </div>
      <span>
        {showBrackets === true ? ` (` : null}
        {calcDistance(distance)} away
        {showBrackets === true ? `)` : null}
      </span>
    </div>
  );
};
