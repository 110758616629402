import { filterAccounts, formatBill } from 'PayMyBill/utils/sharedUtils';
import { useAPI } from 'api/useAPI';
import { requestBodyFormatter } from 'api/utils';
import { AxiosRequestConfig } from 'axios';
import { setSelectedTipPreset, setTipAmount } from 'checkout/actions';
import { selectTipAmount } from 'checkout/selectors';
import { usePayment } from 'contexts/CheckoutContext';
import { useGlobalUser } from 'contexts/UserContext';
import { useSalesAreas, useVenues } from 'contexts/VenueContext';
import { addNotification, removeNotification } from 'core/actions';
import { useAxiosInstance } from 'hooks/useAxiosInstance';
import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ErrorResponse } from 'types/models';
import { AccountSummary, FormattedBill } from 'types/models/PayMyBill';
import {
  AdditionalInformationFormData,
  PaymentDeviceData,
  PaymentNonce,
  PaymentType,
} from 'types/models/Payment';
import { getAccountResponse } from 'types/models/Responses/GetAccountResponse';
import { SearchOpenAccountsResponse } from 'types/models/Responses/SearchOpenAccountsResponse';

interface PayMyBillContext {
  initialised: boolean;
  bill: FormattedBill | undefined;
  braintreeLoading: boolean;
  braintreeToken: string;
  completedPmbAccount: string | undefined;
  fetchBill: (venueId: number, salesAreaId: number, accountId: string) => void;
  fetchOpenAccounts: (
    venueId: number,
    salesAreaId: number,
    table: number,
  ) => void;
  handleGoToCheckout: (amount: number) => void;
  isFetchingAccounts: boolean;
  isFetchingBill: boolean;
  openAccount: AccountSummary | undefined;
  accountError: boolean;
  paymentAmount: number;
  resetCompletedPmbAccount: () => void;
  resetOpenAccounts: () => void;
  resetBraintreeToken: () => void;
  setAccountError: (value: boolean) => void;
  setPaymentAmount: (amount: number) => void;
  submitPayment: (
    formData: AdditionalInformationFormData,
    deviceData: PaymentDeviceData,
    nonce: PaymentNonce,
    paymentType: PaymentType,
  ) => void;
}

// set up context
export const PayMyBillContext = createContext<PayMyBillContext>({
  initialised: false,
  bill: undefined,
  braintreeLoading: false,
  braintreeToken: '',
  completedPmbAccount: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchBill: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchOpenAccounts: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleGoToCheckout: () => {},
  isFetchingAccounts: false,
  isFetchingBill: false,
  openAccount: undefined,
  accountError: false,
  paymentAmount: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetCompletedPmbAccount: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetOpenAccounts: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetBraintreeToken: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAccountError: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPaymentAmount: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  submitPayment: () => {},
});

// set up hook
export const usePayMyBill = (): PayMyBillContext => {
  const consumer = useContext(PayMyBillContext);

  // Condition used to determine if Context Provider has been declared
  if (!consumer.initialised) {
    throw new Error('PayMyBillContextProvider not initialised');
  }

  return consumer;
};

interface PayMyBillContextProviderProps {
  children: React.ReactNode;
}

// set up provider
export const PayMyBillContextProvider: React.FC<
  PayMyBillContextProviderProps
> = ({ children }) => {
  const {
    url,
    searchOpenAccounts,
    getAccount,
    makeAccountPayment,
    getBraintreeToken: getBraintreeTokenAPI,
  } = useAPI();
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoggedIn, user } = useGlobalUser();
  const { selectedVenue } = useVenues();
  const { selectedSalesArea } = useSalesAreas();
  const { isSubmittingPayment, setIsSubmittingPayment } = usePayment();

  const loggedInEmail = user.email;
  const { pmbAxios } = useAxiosInstance();

  const [isFetchingAccounts, setIsFetchingAccounts] = useState(false);
  const [openAccount, setOpenAccount] = useState<AccountSummary | undefined>();
  const [accountError, setAccountError] = useState(false);
  const [completedPmbAccount, setCompletedPmbAccount] = useState<
    string | undefined
  >();

  const [isFetchingBill, setIsFetchingBill] = useState(false);
  const [bill, setBill] = useState<FormattedBill | undefined>();

  const [paymentAmount, setPaymentAmount] = useState(0);
  const [braintreeLoading, setBraintreeLoading] = useState(false);
  const [braintreeToken, setBraintreeToken] = useState('');

  const tipAmount = useSelector(selectTipAmount);

  const fetchOpenAccounts = (
    venueId: number,
    salesAreaId: number,
    table: number,
  ) => {
    if (!isFetchingAccounts) {
      resetOpenAccounts();
      setIsFetchingAccounts(true);
      setAccountError(false);

      const searchAccountsConfig = searchOpenAccounts();

      const searchAccountsOptions: AxiosRequestConfig = {
        url: url,
        method: 'POST',
        data: requestBodyFormatter({
          method: searchAccountsConfig.method,
          ...searchAccountsConfig.body,
          siteId: venueId,
          salesAreaId,
          table,
        }),
      };

      pmbAxios(searchAccountsOptions)
        .then((response) => {
          const data = response.data as SearchOpenAccountsResponse;
          if (data.response === 'OK') {
            const filteredAccounts = filterAccounts(data.results, table);
            if (filteredAccounts.length === 1) {
              setOpenAccount(filteredAccounts[0]);
            } else {
              setAccountError(true);
            }
          } else {
            setAccountError(true);
          }
        })
        .catch(() => {
          setAccountError(true);
        })
        .finally(() => {
          setIsFetchingAccounts(false);
        });
    }
  };

  const fetchBill = (
    venueId: number,
    salesAreaId: number,
    accountId: string,
  ) => {
    if (!isFetchingBill) {
      setIsFetchingBill(true);
      setAccountError(false);
      setBill(undefined);

      const getAccountConfig = getAccount();

      const getAccountOptions: AxiosRequestConfig = {
        url: url,
        method: 'POST',
        data: requestBodyFormatter({
          method: getAccountConfig.method,
          ...getAccountConfig.body,
          siteId: venueId,
          salesAreaId,
          accountId: BigInt(accountId),
        }),
      };

      pmbAxios(getAccountOptions)
        .then((response) => {
          const data = response.data as getAccountResponse;
          if (data.response === 'OK') {
            setBill(formatBill(data.account));
          } else {
            setAccountError(true);
          }
        })
        .catch(() => {
          setAccountError(true);
        })
        .finally(() => {
          setIsFetchingBill(false);
        });
    }
  };

  const submitPayment = (
    formData: AdditionalInformationFormData,
    deviceData: PaymentDeviceData,
    nonce: PaymentNonce,
    paymentMethod: PaymentType,
  ) => {
    if (!isSubmittingPayment) {
      setIsSubmittingPayment(true);

      const makePaymentConfig = makeAccountPayment();

      const makePaymentOptions: AxiosRequestConfig = {
        url: url,
        method: 'POST',
        data: requestBodyFormatter({
          method: makePaymentConfig.method,
          ...makePaymentConfig.body,
          siteId: selectedVenue?.id,
          salesAreaId: selectedSalesArea?.id,
          accountId: BigInt(bill?.accountId ?? ''),
          amount: paymentAmount,
          tip: tipAmount,
          customer: { email: isLoggedIn ? loggedInEmail : formData?.email },
          authorisation: {
            deviceData,
            nonce,
            paymentMethod,
          },
        }),
      };

      pmbAxios(makePaymentOptions)
        .then((response) => {
          // The response for this is the same as getAccount just with an updated outstanding balance
          const data = response.data as getAccountResponse;
          if (data.response === 'OK') {
            setCompletedPmbAccount(data.account.accountId);
            resetOpenAccounts();
            dispatch(setSelectedTipPreset(0));
            dispatch(setTipAmount(0));
            history.push({
              pathname: `/complete/pay-my-bill/${data.account.outstandingBalanceToPay}/${data.account.accountNumber}`,
              state: { from: { pathname: '/checkout/pay-my-bill' } },
            });
          } else {
            const data = response.data as ErrorResponse;
            dispatch(
              addNotification(`${data.detail} (${data.code})`, 'danger'),
            );
          }
        })
        .catch(() => {
          dispatch(
            addNotification(
              'There was an error processing your payment. Please try again or speak to a member of staff.',
              'danger',
            ),
          );
        })
        .finally(() => {
          setIsSubmittingPayment(false);
        });
    }
  };

  const handleGoToCheckout = (amount: number) => {
    getBraintreeToken(amount);
  };

  const getBraintreeToken = (amount: number) => {
    if (!braintreeLoading) {
      setBraintreeLoading(true);
      setBraintreeToken('');

      const getBraintreeTokenConfig = getBraintreeTokenAPI();

      const getBraintreeTokenOptions: AxiosRequestConfig = {
        url: url,
        method: 'POST',
        data: requestBodyFormatter({
          method: getBraintreeTokenConfig.method,
          ...getBraintreeTokenConfig.body,
        }),
      };

      pmbAxios(getBraintreeTokenOptions)
        .then((response) => {
          const data = response.data;
          if (data.braintreeToken) {
            setBraintreeToken(data.braintreeToken);
            setPaymentAmount(amount);
            history.push('/checkout/pay-my-bill');
          } else {
            dispatch(
              addNotification(
                'Something went wrong with the payment provider. Please try again.',
                'danger',
              ),
            );
          }
        })
        .catch(() => {
          dispatch(
            addNotification(
              'Something went wrong with the payment provider. Please try again.',
              'danger',
            ),
          );
        })
        .finally(() => {
          setBraintreeLoading(false);
        });
    }
  };

  const resetOpenAccounts = () => {
    dispatch(removeNotification());
    setOpenAccount(undefined);
    setAccountError(false);
    setBill(undefined);
    resetBraintreeToken();
    setPaymentAmount(0);
  };

  const resetCompletedPmbAccount = () => {
    setCompletedPmbAccount(undefined);
  };

  const resetBraintreeToken = () => {
    setBraintreeToken('');
  };

  useEffect(() => {
    if (accountError) {
      dispatch(
        addNotification(
          "Unfortunately, we couldn't retrieve the bill for this table. Please contact a member of staff to pay your bill",
          'danger',
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountError]);

  return (
    <PayMyBillContext.Provider
      value={{
        initialised: true,
        bill,
        braintreeLoading,
        braintreeToken,
        completedPmbAccount,
        fetchBill,
        fetchOpenAccounts,
        handleGoToCheckout,
        isFetchingAccounts,
        isFetchingBill,
        openAccount,
        accountError,
        paymentAmount,
        resetCompletedPmbAccount,
        resetOpenAccounts,
        resetBraintreeToken,
        setAccountError,
        setPaymentAmount,
        submitPayment,
      }}
    >
      {children}
    </PayMyBillContext.Provider>
  );
};
